import { useState, useEffect } from "react";

import ReactGA from "react-ga4";

import { getGAcategoryByPath } from "./utils";

export const usePrint = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrinting(true);

      // Track browser print event
      if (!window.customPrintButtonIsClicked) {
        ReactGA.event({
          category: getGAcategoryByPath(),
          action: "Print browser",
          label: window.location.pathname + window.location.search
        });
      }
    };

    const handleAfterPrint = () => {
      // Since window.print() is blocking (while Ctrl+P is not),
      // delay resetting of the print status to prevent re-rendering issues
      setTimeout(() => {
        setIsPrinting(false);
      }, 100);
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return isPrinting;
};
