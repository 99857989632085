import React, { useState, useRef, useEffect, useCallback } from "react";

import ky from "ky";
import { isEqual } from "lodash";
import HamburgerMenu from "react-hamburger-menu";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/ntb-logo.svg";
import style from "../../assets/scss/components/header.module.scss";
import { indexRoute, settingsRoute } from "../../helpers/routes";
import { selectArticleFilterIsMobile } from "../../store/articleFilter/selector";
import PrimaryMenu from "../navigation/PrimaryMenu";
import SearchField from "../search/SearchField";

import MainMenu from "./main-menu";
import NotificationCenter from "./NotificationCenter";
import UserMenu from "./UserMenu";
import UserSettingsHeaderMeta from "./UserSettingsHeaderMeta";

function Header() {
  const searchFieldRef = useRef();

  const [mainMenuData, setMainMenuData] = useState(null);

  const hamburgerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (isOpen = !isMenuOpen) => {
    setIsMenuOpen(isOpen);
  };

  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);

  const [fetchingMainMenuDataFailed, setFetchingMainMenuDataFailed] = useState(false);
  // fetch the main menu items from ntb.no
  useEffect(() => {
    ky.get("https://www.ntb.no/api/v1/public/menu")
      .then(response => response.json())
      .then(data => setMainMenuData(data))
      .catch(() => {
        setFetchingMainMenuDataFailed(true);
      });
  }, []);

  const routeMatchesSettingsPages = Boolean(useMatch(`${settingsRoute}/*`));

  const onPrimaryMenuLinkClick = useCallback(() => {
    searchFieldRef.current?.clearSearch();
  }, []);

  return (
    <>
      <header className={style["header"]}>
        {!fetchingMainMenuDataFailed && (
          <div ref={hamburgerRef}>
            <HamburgerMenu
              animationDuration={0.6}
              borderRadius={0}
              className={style["header__hamburger-menu"]}
              height={14}
              isOpen={isMenuOpen}
              menuClicked={() => toggleMenu()}
              rotate={360}
              strokeWidth={1}
              width={16}
            />
          </div>
        )}
        <Link className={style["header__logo"]} to={indexRoute}>
          <Logo />
        </Link>

        {routeMatchesSettingsPages ? (
          <UserSettingsHeaderMeta />
        ) : (
          <>
            {!isMobile && <PrimaryMenu onClick={onPrimaryMenuLinkClick} />}
            <SearchField className={style["header__search-field"]} ref={searchFieldRef} />
          </>
        )}
        <div className={style["header__actions"]}>
          {/* <NotificationCenter /> */}
          <div className={style["header__actions__user-menu"]}>
            <UserMenu />
          </div>
        </div>
      </header>

      {mainMenuData && (
        <MainMenu isOpen={isMenuOpen} menuItems={mainMenuData} toggleMenu={toggleMenu} />
      )}
    </>
  );
}

export default Header;
