import { isObjectLike, omit, mapKeys } from "lodash";

import { LS_KEY_INACTIVE_MY_NEWS_FILTERS } from "../helpers/constants";

import BaseService from "./BaseService";
import NotificationService from "./NotificationService";

const notificationService = new NotificationService();

const EXCLUDED_DEFAULT_MY_NEWS_SEARCH_STRINGS = [
  "search.category=Innenriks\n",
  "search.category=Utenriks\n",
  "search.category=Sport\nsearch.subjects=15000000\n",
  "search.category=Kultur\nsearch.subjects=01000000\n",
  "search.category=PRM-NTB\n",
  "search.category=Børsmelding\n"
];

export default class UserService extends BaseService {
  constructor() {
    super();
    super.setAction("view.action");
  }

  async getProfile() {
    super.setQuery({ json: "" });

    const response = await super.fetch();

    if (response.authenticated === false) {
      throw new Error("E_USER_NOT_AUTHENTICATED");
    }

    if (!isObjectLike(response) || !response.id) {
      throw new Error("E_MISSING_USER_PROFILE");
    }

    return response;
  }

  async getAllFilters(onlyActiveFilters = false) {
    super.setAction("api/user/filter");
    super.setQuery({ only_active: onlyActiveFilters });
    let filters = await super.fetch();
    return filters.map(filter => mapKeys(filter, this.mapFilterKeysInbound));
  }

  async addFilter(filter = {}) {
    super.setAction("api/user/filter/add");
    super.setQuery({});

    // We need to remove the id from the filter, as the backend expects it to not be there
    // and will throw an error if it is.
    const filtersWithoutId = omit(filter, "id");

    super.setBody(mapKeys(filtersWithoutId, this.mapFilterKeysOutbound));
    return await super.post();
  }

  async updateFilter(filter = {}) {
    super.setAction("api/user/filter/update");
    super.setQuery({});
    super.setBody(mapKeys(filter, this.mapFilterKeysOutbound));

    if (filter.notification) {
      await notificationService.updateByFilter(filter);
    }

    return await super.post({ method: "PATCH" });
  }

  async removeFilter(filter) {
    super.setAction("api/user/filter/remove");
    super.setQuery({ id: filter.id });
    await super.post({ method: "DELETE" });

    if (filter.notification?.id) {
      await notificationService.delete(filter.notification.id);
    }
  }

  async getMyNews() {
    super.setAction("api/user/news/categories/json");
    super.setQuery({});
    const myNews = await super.fetch();

    const filteringMyNews = item => {
      if (
        !item.name ||
        ["ALLE NYHETER", "PRESSEMELDINGER"].includes(item.name.toUpperCase()) ||
        EXCLUDED_DEFAULT_MY_NEWS_SEARCH_STRINGS.includes(item.searchParams)
      ) {
        return false;
      } else {
        return true;
      }
    };

    return myNews.filter(filteringMyNews).map(item => ({
      id: item.id,
      userId: item.userId,
      title: item.name || "Mine nyheter uten navn",
      default: false,
      accessible: true,
      active: (() => {
        let inactiveMyNewsFilters =
          JSON.parse(window.localStorage.getItem(LS_KEY_INACTIVE_MY_NEWS_FILTERS)) || [];
        return !inactiveMyNewsFilters.some(id => id === item.id);
      })(),
      visibility: ["newsfeed", "calendar"],
      properties: {
        searchString: item.documentSearchJson.searchString,
        service: item.documentSearchJson.service,
        categories: item.documentSearchJson.category,
        subcategories: item.documentSearchJson.subcategory,
        subjects: item.documentSearchJson.subjects
      },
      isMyNews: true
    }));
  }

  async shareByEmail(articleId, service, email) {
    super.setAction("api/user/news/share/email");

    super.setBody({
      id: articleId,
      service: service,
      toEmail: email
    });

    return await super.post();
  }

  mapFilterKeysOutbound(value, key) {
    if (key === "default") {
      return "isDefault";
    }

    return key;
  }

  mapFilterKeysInbound(value, key) {
    if (key === "isDefault") {
      return "default";
    }

    return key;
  }
}
