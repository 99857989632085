import React, { useCallback, useEffect } from "react";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isEqual } from "lodash";
import qs from "qs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { baseURL, indexRoute } from "../../helpers/routes";
import NotificationService from "../../services/NotificationService";
import { selectUserAuthenticated } from "../../store/user/selector";

const firebaseConfig = {
  apiKey: "AIzaSyAQcszZ0R63PdN9BdkYId2lpDsU-oDBijI",
  authDomain: "ntb-newsreader.firebaseapp.com",
  databaseURL: "https://ntb-newsreader.firebaseio.com",
  projectId: "ntb-newsreader",
  storageBucket: "ntb-newsreader.appspot.com",
  messagingSenderId: "869957035229",
  appId: "1:869957035229:web:de331c2b7211e33051a734",
  measurementId: "G-1F92WSHEGM"
};

const Notifications = () => {
  const navigate = useNavigate();

  const authenticated = useSelector(selectUserAuthenticated, isEqual);

  const requestPermission = useCallback(messaging => {
    navigator.serviceWorker.register(`${baseURL}/firebase-messaging-sw.js`).then(registration => {
      getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey:
          "BEXauGlVD2HXjCCAL9zwD9j-_DlH8K2zHxsY4g-oP44JH3GcemM4rolosp_DIIuNuSlc6FVu-4i1DWUVWTfCe_g"
      })
        .then(async notificationToken => {
          if (notificationToken) {
            // Start listening for notifications
            onMessage(messaging, payload => {
              // Foreground notification received
              // payload:
              // {
              //   "from": "869957035229",
              //   "messageId": "f8bb447a-aa4e-46e4-9c41-e5672ad8d747",
              //   "data": {
              //     "id": "17846637",
              //     "title": "Her er Riiber i aksjon i OL-bakken",
              //     "service": "news",
              //     "priority": "4",
              //     "ntbId": "NTB26918076-77a3-461c-8dfb-c199ae660b92",
              //     "text": "Her er Riiber i aksjon i OL-bakken"
              //   }
              // }
              toast(
                <div
                  onClick={() => {
                    navigate(
                      {
                        pathname: indexRoute,
                        search: qs.stringify({
                          service: payload.data.service,
                          article: payload.data.id
                        })
                      },
                      { replace: true }
                    );
                  }}
                >
                  <div
                    style={{
                      fontFamily: '"Overpass", sans-serif',
                      fontWeight: 600,
                      marginBottom: "3px"
                    }}
                  >
                    Ny artikkel publisert
                  </div>
                  <div>{payload.data.title}</div>
                </div>
              );
            });

            // Send the token to your server
            try {
              await new NotificationService().updateBrowserNotificationToken(notificationToken);
            } catch (e) {
              // probably authentication error
            }
          } else {
            // Show permission request UI
            console.log("No registration token available. Request permission to generate one.");
            // ...
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenticated) {
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);

      requestPermission(messaging);
    }
  }, [authenticated, requestPermission]);
  return null;
};

export default Notifications;
