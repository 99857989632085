import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";

import { isFunction } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import style from "../../assets/scss/components/article/media-preview.module.scss";
import iframeEventBus from "../../helpers/iframeEventBus";
import { selectUserThemePreference } from "../../store/user/selector";
import LoadingSpinner from "../LoadingSpinner";
import Modal from "../ui-element/Modal";

const MediaPreview = ({ media, onClose }) => {
  const preferredTheme = useSelector(selectUserThemePreference);

  const iframeRef = useRef(null);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const iframeNode = iframeRef.current;
    if (iframeNode) {
      iframeEventBus.registerIframe(iframeNode);

      return () => {
        iframeEventBus.unregisterIframe(iframeNode);
      };
    }
  }, [media]);

  const IMAGE_PORTAL_PREVIEW_URL = useMemo(() => "https://bilder.ntb.no/r/preview", []);

  const onCloseCallback = useCallback(() => {
    if (isFunction(onClose)) {
      onClose();
    }
  }, [onClose]);

  const onIframeLoaded = useCallback(() => {
    iframeEventBus.emit("setPreferredTheme", preferredTheme);
    setTimeout(() => {
      setIframeLoaded(true);
    }, 150);
  }, [preferredTheme]);

  // Reset `iframeLoaded` every time the `media` changes
  useEffect(() => {
    setIframeLoaded(false);
  }, [media]);

  useEffect(() => {
    iframeEventBus.emit("setPreferredTheme", preferredTheme);
  }, [preferredTheme]);

  return (
    <>
      <Modal
        className={style["media-preview-modal"]}
        open={Boolean(media)}
        size={"large"}
        onClose={onCloseCallback}
      >
        {!iframeLoaded && (
          <div className={style["media-preview-modal__loading-spinner"]}>
            <LoadingSpinner />
          </div>
        )}
        <iframe
          frameBorder="0"
          ref={iframeRef}
          src={`${IMAGE_PORTAL_PREVIEW_URL}/editorial/${media?.id}?newsPortalPreview`}
          style={{ opacity: iframeLoaded ? 1 : 0 }}
          width="100%"
          onLoad={onIframeLoaded}
        />
      </Modal>
    </>
  );
};

MediaPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
  media: PropTypes.shape({
    mediaType: PropTypes.oneOf(["image"]).isRequired,
    type: PropTypes.oneOf(["image"]).isRequired,
    mimeType: PropTypes.oneOf(["image/jpeg"]).isRequired,
    caption: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    scanpix: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired
  })
};

export default MediaPreview;
