import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

function RedirectFromTrailingSlash() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname.slice(-1)[0] === "/") {
      navigate(`${location.pathname.slice(0, -1)}${location.search}${location.hash}`, {
        state: location.state,
        replace: true
      });
    }
  }, [location, navigate]);
}
export default RedirectFromTrailingSlash;
