import { createSlice } from "@reduxjs/toolkit";

import { toggleBreakingNews, toggleServiceMessages } from "./thunk";

const initialState = {
  loadingInitial: true,
  list: [
    {
      id: 1,
      timestamp: 1623059429,
      title: "Kristoffersen har fått ny trener",
      description: "Utvidet tekst med 102 ord",
      priority: 0,
      read: false
    },
    {
      id: 55,
      timestamp: 1623059429,
      title: "Kristoffersen har fått ny trener",
      description: "Utvidet tekst med 102 ord",
      priority: 0,
      read: true
    },
    {
      id: 2,
      timestamp: 1623043860,
      title: "Beregning: En sykepleier kan kun kjøpe 1 av 40 boliger i Oslo",
      priority: 2,
      read: false
    },
    {
      id: 3,
      timestamp: 1622972817,
      title: "Beregning: En sykepleier kan kun kjøpe 1 av 40 boliger i Oslo",
      priority: 1,
      read: false
    }
  ],
  breakingNews: {
    sport: [],
    domestic: [],
    foreign: [],
    urgent: []
  },
  breakingNewsTypes: ["urgent", "domestic", "foreign", "sport", "editorial", "spvideo"],
  serviceMessages: { editorial: [], spvideo: [] },
  userNotification: []
};

export const counterSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setInitial(state, { payload }) {
      state.breakingNews = payload.breakingNews;
      state.serviceMessages = payload.serviceMessages;
      state.userNotification = payload.userNotification;
      state.breakingNewsTypes = payload.breakingNewsTypes;
      state.loadingInitial = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(toggleBreakingNews.pending, (state, { meta }) => {
      const { device, notificationType, enabled } = meta.arg;

      const set = new Set(state.breakingNews[notificationType]);
      enabled ? set.add(device) : set.delete(device);

      state.breakingNews = {
        ...state.breakingNews,
        [notificationType]: Array.from(set)
      };
    });
    builder.addCase(toggleServiceMessages.pending, (state, { meta }) => {
      const { device, notificationType, enabled } = meta.arg;

      const set = new Set(state.serviceMessages[notificationType]);
      enabled ? set.add(device) : set.delete(device);

      state.serviceMessages = {
        ...state.serviceMessages,
        [notificationType]: Array.from(set)
      };
    });
  }
});

export const { setInitial } = counterSlice.actions;

export { toggleBreakingNews, toggleServiceMessages };

export default counterSlice.reducer;
