import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import style from "../assets/scss/components/pulser.module.scss";

export default function Pulser({ variant }) {
  return <div className={classNames(style["pulser"], style[`pulser--${variant}`])} />;
}

Pulser.defaultProps = {
  variant: "green"
};

Pulser.propTypes = {
  variant: PropTypes.oneOf(["green", "yellow"])
};
