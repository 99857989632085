import React from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import { ReactComponent as WarningIcon } from "@ntbjs/react-components/icons/warning-circle.svg";
import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as VisibilityOffIcon } from "../assets/icons/visibility-off.svg";
import { ReactComponent as VisibilityIcon } from "../assets/icons/visibility.svg";
import style from "../assets/scss/components/filter-menu.module.scss";
import { FILTER_NAME_MAX_LENGTH } from "../helpers/constants";
import { truncateString } from "../helpers/utils";
import {
  selectAccessibleArticleFilters,
  selectDefaultArticleFilters,
  selectCustomArticleFilters,
  selectArticleFilterIsMobile
} from "../store/articleFilter/selector";
import {
  activateAllNewsFilter,
  enforceOneActiveFilterMobile,
  hideFilter,
  toggleFilter,
  toggleFilterMobile
} from "../store/articleFilter/slice";
import { selectDashboardActiveNewsItemColumnId } from "../store/dashboard/selector";
import { toggleFilterMenu, markNewsItemAsActive } from "../store/dashboard/slice";

import FilterForm from "./search/FilterForm";

function FilterMenu({
  filterVisibility,
  allowOneActiveFilterOnMobile = false,
  allNewsFilterName = "All news"
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCalendarFilter = filterVisibility === "calendar";

  const filters = useSelector(selectAccessibleArticleFilters, isEqual);
  const defaultFilters = useSelector(selectDefaultArticleFilters, isEqual);
  const customFilters = useSelector(selectCustomArticleFilters, isEqual);
  const activeNewsItemColumnId = useSelector(selectDashboardActiveNewsItemColumnId, isEqual);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);

  const allNewsFilter = defaultFilters.find(
    filter => filter.custom?.properties?.originalId === "all"
  );

  const handleFilterClick = id => {
    const index = filters.findIndex(filter => filter.id === id);

    if (!isMobile) {
      dispatch(toggleFilter({ id, calendarFilter: isCalendarFilter }));
    } else {
      if (allowOneActiveFilterOnMobile || filters[index].custom?.properties?.originalId === "all") {
        if (isCalendarFilter) {
          if (!filters[index].calendarActive) {
            dispatch(enforceOneActiveFilterMobile({ id, calendarFilter: true }));
          }
        } else {
          if (!filters[index].active) {
            dispatch(enforceOneActiveFilterMobile({ id, calendarFilter: false }));
          }
        }
        dispatch(toggleFilterMenu());
      } else {
        dispatch(toggleFilterMobile({ id, calendarFilter: isCalendarFilter }));
      }
    }

    if (filters[index].active) {
      if (activeNewsItemColumnId === id) {
        dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      }
    }
  };

  const handleAllNewsFilterClick = () => {
    dispatch(activateAllNewsFilter({ calendarFilter: isCalendarFilter }));
    dispatch(markNewsItemAsActive({ id: null, columnId: null }));
  };

  return (
    <div className={style["filter-menu"]}>
      {allNewsFilter && (
        <div className={style["filter-menu__section"]}>
          <div className={style["filter-menu__section__content"]}>
            <ul>
              <li className={classNames(style["filter-menu__section__content__li"])}>
                <span
                  className={classNames(style["filter-menu__section__content__li__title"], {
                    [style["filter-menu__section__content__li__title--active"]]: isCalendarFilter
                      ? allNewsFilter.calendarActive
                      : allNewsFilter.active
                  })}
                  onClick={() =>
                    !isMobile
                      ? isCalendarFilter
                        ? !allNewsFilter.calendarActive
                        : !allNewsFilter.active
                        ? handleAllNewsFilterClick()
                        : dispatch(toggleFilterMenu())
                      : handleFilterClick(allNewsFilter.id)
                  }
                >
                  {t("filterMenu:" + allNewsFilterName)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className={style["filter-menu__section"]}>
        <div
          className={classNames(
            style["filter-menu__section__heading"],
            style["filter-menu__section__heading--uppercased"]
          )}
        >
          Egendefinerte filter
        </div>
        <div className={style["filter-menu__section__content"]}>
          <ul>
            {customFilters.map(customFilter => (
              <li
                className={classNames(style["filter-menu__section__content__li"])}
                key={customFilter.id}
              >
                <span
                  className={classNames(style["filter-menu__section__content__li__title"], {
                    [style["filter-menu__section__content__li__title--active"]]: isCalendarFilter
                      ? customFilter.calendarActive
                      : customFilter.active
                  })}
                  onClick={() => handleFilterClick(customFilter.id)}
                >
                  {truncateString(customFilter.title, FILTER_NAME_MAX_LENGTH)}
                </span>
                <div
                  className={classNames(
                    style["filter-menu__section__content__li__actions"],
                    "section__content__li__actions"
                  )}
                >
                  {customFilter.custom?.properties?.hiddenByUser === "true" ? (
                    <Tooltip content={t("search:filter:showFilter")}>
                      <VisibilityOffIcon
                        onClick={() =>
                          dispatch(hideFilter({ id: customFilter.id, hiddenByUser: "false" }))
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip content={t("search:filter:hideFilter")}>
                      <VisibilityIcon
                        onClick={() =>
                          dispatch(hideFilter({ id: customFilter.id, hiddenByUser: "true" }))
                        }
                      />
                    </Tooltip>
                  )}

                  <FilterForm filter={customFilter} />
                </div>
              </li>
            ))}
            <li className={classNames(style["filter-menu__section__content__li"])}>
              <FilterForm inMenu={true} />
            </li>
          </ul>
        </div>
      </div>
      <div className={style["filter-menu__section"]}>
        <div
          className={classNames(
            style["filter-menu__section__heading"],
            style["filter-menu__section__heading--uppercased"]
          )}
        >
          Standard kategorier
        </div>
        <div className={style["filter-menu__section__content"]}>
          <ul>
            {defaultFilters
              .filter(filter => filter.custom?.properties?.originalId !== "all")
              .filter(filter =>
                filter.visibility.includes(isCalendarFilter ? "calendar" : "newsfeed")
              )
              .map(category => (
                <li
                  className={classNames(style["filter-menu__section__content__li"], {
                    [style["filter-menu__section__content__li--inactive"]]: !category.accessible
                  })}
                  key={category.id}
                >
                  <span
                    className={classNames(style["filter-menu__section__content__li__title"], {
                      [style["filter-menu__section__content__li__title--active"]]: isCalendarFilter
                        ? category.calendarActive
                        : category.active
                    })}
                    onClick={() =>
                      category.accessible ? handleFilterClick(category.id) : undefined
                    }
                  >
                    {category.custom?.properties?.originalId === "all"
                      ? t("articleCategories:AllEvents")
                      : t(category.title)}
                  </span>
                  <div
                    className={classNames(
                      style["filter-menu__section__content__li__actions"],
                      "section__content__li__actions"
                    )}
                  >
                    {category.custom?.properties?.originalId !== "all" &&
                      (category.custom?.properties?.hiddenByUser === "true" ? (
                        <Tooltip content={t("search:filter:showStandardCategory")}>
                          <VisibilityOffIcon
                            onClick={() =>
                              dispatch(hideFilter({ id: category.id, hiddenByUser: "false" }))
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip content={t("search:filter:hideStandardCategory")}>
                          <VisibilityIcon
                            onClick={() =>
                              dispatch(hideFilter({ id: category.id, hiddenByUser: "true" }))
                            }
                          />
                        </Tooltip>
                      ))}
                    {!category.accessible ? <WarningIcon /> : null}
                  </div>
                </li>
              ))}
          </ul>
          {defaultFilters.some(category => !category.accessible) && (
            <div className={style["filter-menu__section__content__warning"]}>
              <WarningIcon />
              <span>
                Kategorien er utenfor ditt abonnement. Kontakt salg for mer informasjon om
                produktet.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FilterMenu.propTypes = {
  allowOneActiveFilterOnMobile: PropTypes.bool,
  allNewsFilterName: PropTypes.string,
  filterVisibility: PropTypes.string
};

export default FilterMenu;
