import React, { useRef, useState } from "react";

import classNames from "classnames";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import style from "../../assets/scss/pages/settings/account.module.scss";
import Radio from "../../components/form/Radio";
import {
  selectUserArticleImagePlacementPreference,
  selectUserAuthenticated,
  selectUserProfile,
  selectUserThemePreference,
  selectUserArticleVersionPreference
} from "../../store/user/selector";
import {
  setThemePreferenceDark,
  setThemePreferenceLight,
  setThemePreferenceDefault,
  setArticleImagePlacementPreferenceTop,
  setArticleImagePlacementPreferenceBottom,
  setArticleVersionPreference
} from "../../store/user/slice";

function AccountSettingsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authenticated = useSelector(selectUserAuthenticated);
  const user = useSelector(selectUserProfile);
  const preferredTheme = useSelector(selectUserThemePreference);
  const preferredArticleImagePlacement = useSelector(selectUserArticleImagePlacementPreference);
  const preferredArticleVersion = useSelector(selectUserArticleVersionPreference);

  const [isCaptionOpen, setIsCaptionOpen] = useState(false);
  const captionRef = useRef();
  const toggleCaption = () => {
    setIsCaptionOpen(!isCaptionOpen);
  };

  const possibleThemes = [
    { label: t("settings:account:theme:option:default"), value: "default" },
    { label: t("settings:account:theme:option:light"), value: "light" },
    { label: t("settings:account:theme:option:dark"), value: "dark" }
  ];

  const possibleArticleImagePlacement = [
    {
      label: t("settings:account:article:imagePlacement:option:top"),
      value: "top"
    },
    {
      label: t("settings:account:article:imagePlacement:option:bottom"),
      value: "bottom"
    }
  ];

  const possibleArticleVersions = [
    {
      label: "Vis kun siste versjon av artikkelen",
      value: "latest"
    },
    {
      label: "Vis alle versjoner av artikkelen",
      value: "all"
    }
  ];

  function setPreferredTheme(value) {
    if (value === "default") {
      dispatch(setThemePreferenceDefault());
    } else if (value === "light") {
      dispatch(setThemePreferenceLight());
    } else if (value === "dark") {
      dispatch(setThemePreferenceDark());
    }

    ReactGA.event({
      category: "User settings",
      action: "Change theme",
      label: value
    });
  }

  function setPreferredArticleImagePlacement(value) {
    if (value === "top") {
      dispatch(setArticleImagePlacementPreferenceTop());
    } else if (value === "bottom") {
      dispatch(setArticleImagePlacementPreferenceBottom());
    }

    ReactGA.event({
      category: "User settings",
      action: "Change article image placement",
      label: value
    });
  }

  function setPreferredArticleVersion(value) {
    dispatch(setArticleVersionPreference(value));

    ReactGA.event({
      category: "User settings",
      action: "Change article version",
      label: value
    });
  }

  return (
    <main className={style["main"]}>
      {authenticated && (
        <>
          <section className={style["section"]}>
            <h2 className={style["section__title"]}>Min side</h2>

            <div className={style["section__setting"]}>
              <div>
                <span className={style["inline-label"]}>Fornavn</span> {user.firstName}
              </div>
              <div>
                <span className={style["inline-label"]}>Etternavn</span> {user.lastName || "-"}
              </div>
              <div>
                <span className={style["inline-label"]}>E-post</span> {user.email || "-"}
              </div>
              <div>
                <span className={style["inline-label"]}>Mobilnummer</span> {user.mobile || "-"}
              </div>
              <div>
                <span className={style["inline-label"]}>Tjenester</span>
              </div>
              {user.services ? (
                <>
                  <div
                    className={
                      isCaptionOpen
                        ? style["service-container__caption--expanded"]
                        : style["service-container__caption"]
                    }
                    dangerouslySetInnerHTML={{ __html: user.services.join(", ") }}
                    ref={captionRef}
                  />
                  <div
                    className={style["service-container__toggle-button"]}
                    onClick={toggleCaption}
                  >
                    {isCaptionOpen ? "Vis mindre" : "Vis mer"}
                  </div>
                </>
              ) : (
                "Ingen"
              )}
              <div>
                <a
                  className={style["service-container__toggle-button"]}
                  href="https://bilder.ntb.no/mypage.action?changePassword="
                  rel="noreferrer"
                  target="_blank"
                >
                  Endre passord
                </a>
              </div>
            </div>
          </section>

          <section className={style["section"]}>
            <h2 className={style["section__title"]}>{t("settings:account:theme:heading")}</h2>

            <div
              className={classNames(style["section__setting"], style["section__setting--radio"])}
            >
              {possibleThemes.map(theme => (
                <div key={theme.value}>
                  <Radio
                    checked={preferredTheme === theme.value}
                    name="theme"
                    value={theme.value}
                    onChange={setPreferredTheme}
                  >
                    {theme.label}
                  </Radio>
                </div>
              ))}
            </div>
          </section>

          <section className={style["section"]}>
            <h2 className={style["section__title"]}>{t("settings:account:article:heading")}</h2>

            <div
              className={classNames(style["section__setting"], style["section__setting--radio"])}
            >
              <span className={style["inline-label"]}>
                {t("settings:account:article:imagePlacement:label")}
              </span>
              <div>
                {possibleArticleImagePlacement.map(placement => (
                  <div key={placement.value}>
                    <Radio
                      checked={preferredArticleImagePlacement === placement.value}
                      name="placement"
                      value={placement.value}
                      onChange={setPreferredArticleImagePlacement}
                    >
                      {placement.label}
                    </Radio>
                  </div>
                ))}
              </div>
            </div>

            <div
              className={classNames(style["section__setting"], style["section__setting--radio"])}
            >
              <span className={style["inline-label"]}>Artikkelversjoner i nyhetsstrømmen</span>
              <div>
                {possibleArticleVersions.map(version => (
                  <div key={version.value}>
                    <Radio
                      checked={preferredArticleVersion === version.value}
                      name="version"
                      value={version.value}
                      onChange={setPreferredArticleVersion}
                    >
                      {version.label}
                    </Radio>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
}

export default AccountSettingsPage;
