import { isArray, isEmpty } from "lodash";

import { DEFAULT_SHOW_NUM_RESULTS } from "../helpers/constants";

import BaseService from "./BaseService";
import { queryStringBuilder, getMappedCategories } from "./utils";

export default class ArticleService extends BaseService {
  constructor() {
    super();
  }

  search(abortController) {
    super.setAction("api/search");
    return super.fetch(abortController);
  }

  getOne(service, articleId, searchString = null) {
    super.setAction(`api/search/${service}/${articleId}`);
    if (searchString) {
      super.setQuery({ searchString: searchString });
    } else {
      super.setQuery({});
    }
    return super.fetch();
  }

  getArticlesWithSameNtbId(service, id, ntbId) {
    super.setAction(`api/search/samentbid/${service}/${id}/${ntbId}`);
    return super.fetch();
  }

  getVersions(service, ntbId) {
    super.setAction(`api/search/versions/${service}/${ntbId}`);
    return super.fetch();
  }

  getMoreLikeThis(service, id) {
    super.setAction(`api/search/morelike/${service}/${id}`);
    return super.fetch();
  }

  searchString(searchString) {
    if (searchString) {
      super.appendQuery({ "search.searchString": searchString });
    }
    return this;
  }

  /**
   * @typedef {object} QueryBuilder
   * @property {function(service: string): void} service
   * @property {function(categories: ("sport"|"domestic"|"foreign"|"culture"|"ir")[]): void} categories
   * @property {function(subcategories: []): void} subcategories
   * @property {function(subcategories: []): void} subcategoriesNotIn
   * @property {function(subject: string): void} subject
   * @property {function(priority: number): void} minimumPriority
   * @property {function(calendarStart: string): void} calendarStart
   * @property {function(calendarStop: string): void} calendarStop
   * @property {function(sortOrder: ("STARTDATE_ASC")): void} sortOrder
   * @property {function(idGreaterThan: number): void} id
   * @property {function(startDate: string): void} startDate
   * @property {function(endDate: string): void} endDate
   * @property {function(status: string[]): void} coverageTypes
   * @property {function(status: string): void} highestCoverageStatus
   * @property {function(mediaTopics: string[]): void} mediaTopics
   * @property {function(regions: string[]): void} regions
   */

  /**
   * A where cause to filter the search
   * @param {function(QueryBuilder)} callback
   * @return {ArticleService}
   */
  where(callback) {
    /**
     * The query builder
     * @type {QueryBuilder}
     */
    const queryBuilder = {
      service: (service = "news") => {
        super.appendQuery({ "search.service": isArray(service) ? service.join(",") : service });
      },
      categories: categories => {
        if (!categories || !categories.length) return;
        categories = getMappedCategories(categories);
        super.appendQuery({ "search.category": categories });
      },

      subcategories: subcategories => {
        if (!subcategories) return;
        super.appendQuery({ "search.subcategory": subcategories });
      },

      subcategoriesNotIn: subcategories => {
        if (!subcategories) return;
        super.appendQuery({ "search.subcategoryNotIn": subcategories });
      },

      subject: subjects => {
        if (!subjects) return;
        super.appendQuery({ "search.subjects": subjects });
      },

      mediaTopics: mediaTopics => {
        if (!mediaTopics) return;
        super.appendQuery({ "search.mediaTopics": mediaTopics });
      },

      regions: regions => {
        if (!regions) return;
        super.appendQuery({ "search.region": regions });
      },

      minimumPriority: priority => {
        if (!priority) return;
        super.appendQuery({ "search.priorityEnd": priority });
      },

      calendarStart: dateTime => {
        if (!dateTime) return;
        super.appendQuery({ "search.calendarStart": dateTime });
      },

      calendarStop: dateTime => {
        if (!dateTime) return;
        super.appendQuery({ "search.calendarStop": dateTime });
      },

      startDate: dateTime => {
        if (!dateTime) return;
        super.appendQuery({ "search.startDate": dateTime });
      },

      endDate: dateTime => {
        if (!dateTime) return;
        super.appendQuery({ "search.endDate": dateTime });
      },

      sortOrder: sortOrder => {
        if (!sortOrder) return;
        const sortOrderParts = sortOrder.split("|");

        super.appendQuery({ "search.sortOrder": sortOrderParts[0] });
        if (sortOrderParts[1]) super.appendQuery({ "search.secondSortOrder": sortOrderParts[1] });
      },

      searchQuery: (string, filters, options) => {
        const searchString = queryStringBuilder(string, filters, options);
        if (!searchString) return;
        super.appendQuery({ "search.searchString": searchString });
      },

      idGreaterThan: id => {
        if (!id) return;
        super.appendQuery({ "search.idGreaterThan": id });
      },

      latest: latest => {
        if (!latest) return;
        super.appendQuery({ "search.latest": latest });
      },

      coverageTypes: types => {
        if (!types || isEmpty(types)) return;
        super.appendQuery({ "search.coverageTypes": types });
      },

      highestCoverageStatus: status => {
        if (!status) return;
        super.appendQuery({ "search.highestCoverageStatus": status });
      }
    };

    callback(queryBuilder);
    return this;
  }

  /**
   * Offset the search
   * @param offset
   * @return {ArticleService}
   */
  offset(offset = 0) {
    super.appendQuery({ "search.offset": offset });
    return this;
  }

  /**
   * Number of results to fetch
   * @param numResults
   * @return {ArticleService}
   */
  showNumResults(numResults = DEFAULT_SHOW_NUM_RESULTS) {
    super.appendQuery({ "search.showNumResults": numResults });
    return this;
  }
}
