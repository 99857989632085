import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import style from "../../assets/scss/components/filter-menu.module.scss";
import { FILTER_NAME_MAX_LENGTH } from "../../helpers/constants";
import { truncateString } from "../../helpers/utils";

import FilterForm from "./FilterForm";

function SearchFilterMenu({ filters, onFilterClick, filterVisibility }) {
  const { t } = useTranslation();

  const customFilters = filters.filter(filter => !filter.default && !filter.isMyNews);
  const defaultFilters = filters.filter(filter => filter.default);
  const isCalendarFilter = filterVisibility == "calendar";
  return (
    <div className={style["filter-menu"]}>
      {customFilters.length > 0 && (
        <div className={style["filter-menu__section"]}>
          <div
            className={classNames(
              style["filter-menu__section__heading"],
              style["filter-menu__section__heading--uppercased"]
            )}
          >
            Egendefinerte filter
          </div>
          <div className={style["filter-menu__section__content"]}>
            <ul>
              {customFilters.map(customFilter => (
                <li
                  className={classNames(style["filter-menu__section__content__li"])}
                  key={customFilter.id}
                >
                  <span
                    className={classNames(style["filter-menu__section__content__li__title"], {
                      [style["filter-menu__section__content__li__title--active"]]:
                        customFilter.active
                    })}
                    onClick={() => onFilterClick(customFilter.id)}
                  >
                    {truncateString(customFilter.title, FILTER_NAME_MAX_LENGTH)}
                  </span>
                  <div
                    className={classNames(
                      style["filter-menu__section__content__li__actions"],
                      "section__content__li__actions"
                    )}
                  >
                    <FilterForm filter={customFilter} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {defaultFilters.length > 0 && (
        <div className={style["filter-menu__section"]}>
          <div
            className={classNames(
              style["filter-menu__section__heading"],
              style["filter-menu__section__heading--uppercased"]
            )}
          >
            Standard kategorier
          </div>
          <div className={style["filter-menu__section__content"]}>
            <ul>
              {defaultFilters.map(category => (
                <li
                  className={classNames(style["filter-menu__section__content__li"])}
                  key={category.id}
                >
                  <span
                    className={classNames(style["filter-menu__section__content__li__title"], {
                      [style["filter-menu__section__content__li__title--active"]]: isCalendarFilter
                        ? category.calendarActive
                        : category.active
                    })}
                    onClick={() => onFilterClick(category.id)}
                  >
                    {t(category.title)}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

SearchFilterMenu.propTypes = {
  filters: PropTypes.array,
  loadingFilters: PropTypes.bool,
  onFilterClick: PropTypes.func,
  filterVisibility: PropTypes.string
};

export default SearchFilterMenu;
