import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import style from "../../assets/scss/components/widget/counter.module.scss";
import { selectCount } from "../../store/counter/selector";
import {
  increment,
  incrementIfOdd,
  incrementAsync,
  incrementByAmount,
  decrement
} from "../../store/counter/slice";

export function Counter() {
  const count = useSelector(selectCount);
  const dispatch = useDispatch();
  const [incrementAmount, setIncrementAmount] = useState("2");

  const incrementValue = Number(incrementAmount) || 0;

  return (
    <div>
      <div className={style["row"]}>
        <button
          aria-label="Decrement value"
          className={style["button"]}
          onClick={() => dispatch(decrement())}
        >
          -
        </button>
        <span className={style["value"]}>{count}</span>
        <button
          aria-label="Increment value"
          className={style["button"]}
          onClick={() => dispatch(increment())}
        >
          +
        </button>
      </div>
      <div className={style["row"]}>
        <input
          aria-label="Set increment amount"
          className={style["text-box"]}
          value={incrementAmount}
          onChange={e => setIncrementAmount(e.target.value)}
        />
        <button
          className={style["button"]}
          onClick={() => dispatch(incrementByAmount(incrementValue))}
        >
          Add Amount
        </button>
        <button
          className={style["asyncButton"]}
          onClick={() => dispatch(incrementAsync(incrementValue))}
        >
          Add Async
        </button>
        <button
          className={style["button"]}
          onClick={() => dispatch(incrementIfOdd(incrementValue))}
        >
          Add If Odd
        </button>
      </div>
    </div>
  );
}
