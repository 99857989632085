import React, { useCallback, useEffect } from "react";

import { Reorder } from "framer-motion";
import { debounce, isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import style from "../assets/scss/components/news-dashboard.module.scss";
import { useHotkey, useHotkeyScope, hotkeys, hotkeyScopes } from "../helpers/hotkeys";
import { selectActiveNewsfeedArticleFilters } from "../store/articleFilter/selector";
import { reorderFilter } from "../store/articleFilter/slice";
import {
  selectDashboardModalNewsItem,
  selectDashboardModalNewsItemLatestVersionId,
  selectDashboardColumns
} from "../store/dashboard/selector";
import {
  setNewsColumnsById,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive,
  setModalNewsItem,
  setNewsColumns
} from "../store/dashboard/slice";

import ArticleViewModal from "./article/ArticleViewModal";
import NewsDashboardColumn from "./NewsDashboardColumn";

function NewsDashboard() {
  const dispatch = useDispatch();
  const columns = useSelector(selectDashboardColumns, isEqual);

  const modalNewsItem = useSelector(selectDashboardModalNewsItem, isEqual);
  const activeArticleFilters = useSelector(selectActiveNewsfeedArticleFilters, isEqual);
  const modalNewsItemLatestVersionId = useSelector(
    selectDashboardModalNewsItemLatestVersionId,
    isEqual
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onReorderDebounced = useCallback(
    debounce(columns => {
      dispatch(reorderFilter(columns.map(column => column.filterId)));
    }, 150),
    []
  );

  useHotkeyScope(hotkeyScopes.ARTICLE);

  useHotkey(hotkeys.NEXT_ARTICLE, () => {
    dispatch(markNextNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_ARTICLE, () => {
    dispatch(markPreviousNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.NEXT_COLUMN, () => {
    dispatch(markNextNewsColumnAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_COLUMN, () => {
    dispatch(markPreviousNewsColumnAsActive());
    return false;
  });

  useEffect(() => {
    // Sort active filters according to their order
    const columnIds = [...activeArticleFilters]
      .sort((a, b) =>
        a.custom?.properties?.order > b.custom?.properties?.order
          ? 1
          : b.custom?.properties?.order > a.custom?.properties?.order
          ? -1
          : 0
      )
      .map(filter => filter.id);
    dispatch(setNewsColumnsById(columnIds));
  }, [dispatch, activeArticleFilters]);

  return (
    <div style={{ width: "100%" }}>
      {modalNewsItem.id && (
        <ArticleViewModal
          article={modalNewsItem}
          latestVersionId={modalNewsItemLatestVersionId}
          onClose={() => dispatch(setModalNewsItem({}))}
        />
      )}
      <Reorder.Group
        layoutScroll
        as={"div"}
        axis="x"
        className={style["dashboard"]}
        values={columns}
        onReorder={columns => {
          onReorderDebounced(columns);
          dispatch(setNewsColumns(columns));
        }}
      >
        {columns.map(column => (
          <NewsDashboardColumn column={column} key={`news-column-${column.id}`} />
        ))}
      </Reorder.Group>
    </div>
  );
}

export default NewsDashboard;
