import { createSlice } from "@reduxjs/toolkit";

import {
  articleSearch,
  loadMoreArticlesByFilterId,
  fetchNewArticlesByFilterId,
  showLatestArticleVersion,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive
} from "./thunk";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    columns: [],
    readNewsItems: [],
    updatedNewsItems: [],
    activeNewsItemId: null,
    activeNewsItemColumnId: null,
    activeNewsItemLatestVersionId: null,
    modalNewsItem: {},
    modalNewsItemLatestVersionId: null,
    filterMenuOpen: false,
    isMobile: false
  },
  reducers: {
    toggleFilterMenu(state) {
      state.filterMenuOpen = !state.filterMenuOpen;
    },
    setNewsColumns(state, action) {
      state.columns = action.payload;
    },
    setNewsColumnsById(state, action) {
      const columnIds = action.payload;

      // remove columns that don't exists in `columnIds`
      state.columns = state.columns.filter(column => columnIds.includes(column.filterId));

      columnIds.forEach(filterId => {
        if (!state.columns.some(column => column.filterId === filterId)) {
          state.columns.push({
            id: filterId,
            filterId: filterId,
            sort: "ID_DESC",
            loading: true,
            totalResults: 0,
            articles: [],
            extraArticles: []
          });
        }
      });
    },
    changeNewsColumnSort(state, action) {
      const { id, sort } = action.payload;
      const index = state.columns.findIndex(column => column.id === id);
      state.columns[index].sort = sort;
      state.columns[index].extraArticles = [];

      if (state.activeNewsItemColumnId === id) {
        state.activeNewsItemId = null;
        state.activeNewsItemColumnId = null;
      }
    },
    // If you want to keep columnId do not pass it to the action.
    // It's useful when a new article is opened in the same column.
    markNewsItemAsActive(state, action) {
      const { id, columnId } = action.payload;
      state.activeNewsItemId = id;

      if (id) {
        if (!state.readNewsItems.includes(id)) state.readNewsItems.push(id);

        if (state.updatedNewsItems.includes(id))
          state.updatedNewsItems = state.updatedNewsItems.filter(item => item !== id);
      }

      if (state.activeNewsItemLatestVersionId) state.activeNewsItemLatestVersionId = null;

      if (typeof columnId !== "undefined") state.activeNewsItemColumnId = columnId;
    },
    updateActiveNewsItemColumnId(state, action) {
      state.activeNewsItemColumnId = action.payload.columnId;
    },
    setModalNewsItem(state, action) {
      state.modalNewsItem = { ...action.payload };

      if (action.payload.id) {
        if (!state.readNewsItems.includes(action.payload.id))
          state.readNewsItems.push(action.payload.id);

        if (state.updatedNewsItems.includes(action.payload.id))
          state.updatedNewsItems = state.updatedNewsItems.filter(
            item => item !== action.payload.id
          );

        // Remove "new version" notification from the inline article view
        // if the new version displayed in a modal article view
        if (
          state.activeNewsItemLatestVersionId &&
          action.payload.id === state.activeNewsItemLatestVersionId
        )
          state.activeNewsItemLatestVersionId = null;
      }

      if (state.modalNewsItemLatestVersionId) state.modalNewsItemLatestVersionId = null;
    },
    setActiveNewsItemLatestVersionId(state, action) {
      state.activeNewsItemLatestVersionId = action.payload;
    },
    setModalNewsItemLatestVersionId(state, action) {
      state.modalNewsItemLatestVersionId = action.payload;
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(articleSearch.pending, (state, { meta }) => {
      const index = state.columns.findIndex(filter => filter.filterId === meta.arg);
      const columns = state.columns[index];

      columns.loading = true;
    });

    builder.addCase(articleSearch.fulfilled, (state, { payload, meta }) => {
      const index = state.columns.findIndex(filter => filter.filterId === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const column = state.columns[index];

      column.totalResults = payload.numResults;
      column.articles = payload.data;
      column.loading = false;
    });

    builder.addCase(articleSearch.rejected, (state, { meta }) => {
      const index = state.columns.findIndex(filter => filter.filterId === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const columns = state.columns[index];

      columns.loading = false;
    });

    builder.addCase(loadMoreArticlesByFilterId.fulfilled, (state, { payload, meta }) => {
      const index = state.columns.findIndex(filter => filter.filterId === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const columns = state.columns[index];

      columns.totalResults = payload.numResults;
      columns.articles = [...columns.articles, ...payload.data];
    });

    builder.addCase(fetchNewArticlesByFilterId.fulfilled, (state, { payload, meta }) => {
      const index = state.columns.findIndex(filter => filter.filterId === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      // No new articles fetched
      if (!payload.data || payload.data.length === 0) return;

      const column = state.columns[index];
      let addedArticleNtbIds = [];

      // When articles sorted by id (default sort) - add new articles to the top.
      if (column.sort === "ID_DESC") {
        const articleIds = column.articles.map(article => article.id);
        const ntbIds = column.articles.map(article => article.ntbId);

        // Additional check to make sure we fetch only new articles
        for (let i = 0; i <= payload.data.length - 1; i++) {
          // Article with this ID already in the column, remove it since we are looking only for the newer IDs.
          if (articleIds.includes(payload.data[i].id)) {
            payload.data.splice(i, 1);
          }
        }

        // No new articles fetched
        if (payload.data.length === 0) return;

        if (payload.latest) {
          for (let i = 0; i <= payload.data.length - 1; i++) {
            if (ntbIds.includes(payload.data[i].ntbId)) {
              // Mark the new article version as updated and remove the old version from the list.
              // Active article shouldn't be removed even if it's an old version of the article.
              if (!state.updatedNewsItems.includes(payload.data[i].id)) {
                state.updatedNewsItems.push(payload.data[i].id);
              }

              column.articles = column.articles.filter(
                article =>
                  article.ntbId !== payload.data[i].ntbId ||
                  (article.id === state.activeNewsItemId &&
                    column.id === state.activeNewsItemColumnId)
              );
            }
          }
        }

        addedArticleNtbIds = payload.data.map(article => article.ntbId);
        column.totalResults += payload.numResults;
        column.articles = [...payload.data, ...column.articles];
      }
      // For other sorting options compare fetched articles with existing ones
      // and replace if there are new ones.
      else {
        const oldArticleIds = column.articles.map(article => article.id);
        const oldNtbIds = column.articles.map(article => article.ntbId);
        const newArticleIds = payload.data.map(article => article.id);
        const newNtbIds = payload.data.map(article => article.ntbId);

        const addedArticleIds = newArticleIds.filter(id => !oldArticleIds.includes(id));

        // No new articles fetched
        if (addedArticleIds.length === 0) return;

        addedArticleNtbIds = payload.data
          .filter(article => addedArticleIds.includes(article.id))
          .map(article => article.ntbId);

        // Reset extra articles that might have been added under certain circumstances.
        column.extraArticles = [];

        // Add updated mark to articles when appropriate
        if (payload.latest) {
          for (let i = 0; i <= addedArticleIds.length - 1; i++) {
            let index = payload.data.findIndex(article => article.id === addedArticleIds[i]);
            if (
              oldNtbIds.includes(payload.data[index].ntbId) &&
              !state.updatedNewsItems.includes(payload.data[index].id)
            ) {
              state.updatedNewsItems.push(payload.data[index].id);
            }
          }
        }

        // Make sure the active article is in the column and add it back if it was removed
        if (
          state.activeNewsItemColumnId === column.id &&
          !newArticleIds.includes(state.activeNewsItemId)
        ) {
          const activeArticle = Object.assign(
            {},
            column.articles.find(article => article.id === state.activeNewsItemId)
          );

          // Was it removed because a newer version of it fetched?
          // If so we add it back right after the newer version of the article.
          if (payload.latest && newNtbIds.includes(activeArticle.ntbId)) {
            const newerVersionOfActiveArticleIndex = payload.data.findIndex(
              article => article.ntbId === activeArticle.ntbId
            );
            payload.data.splice(newerVersionOfActiveArticleIndex + 1, 0, activeArticle);
          }
          // Was it removed because it's placed too low in the list?
          // In this case just add it to the very bottom of the list.
          else {
            payload.data.push(activeArticle);
          }

          // We forced addition of the active article, we must take it into account when
          // polling for the new articles again to prevent fetching more items than needed.
          column.extraArticles = [activeArticle];
        }

        column.articles = [...payload.data];
      }

      // Was a newer version fecthed for the currently active article?
      if (state.activeNewsItemId && addedArticleNtbIds.length > 0) {
        const activeNewsItem = column.articles.find(
          article => article.id === state.activeNewsItemId
        );

        if (activeNewsItem && addedArticleNtbIds.includes(activeNewsItem.ntbId)) {
          // The latest version must be the first one in the array
          const activeNewsItemLatest = column.articles.find(
            article => article.ntbId === activeNewsItem.ntbId
          );
          state.activeNewsItemLatestVersionId = activeNewsItemLatest.id;
        }
      }

      // Was a newer version fecthed for the article displayed in the modal?
      if (state.modalNewsItem.id && addedArticleNtbIds.length > 0) {
        if (addedArticleNtbIds.includes(state.modalNewsItem.ntbId)) {
          // The latest version must be the first one in the array
          const modalNewsItemLatest = column.articles.find(
            article => article.ntbId === state.modalNewsItem.ntbId
          );

          state.modalNewsItemLatestVersionId = modalNewsItemLatest.id;
        }
      }
    });
  }
});

export const {
  toggleFilterMenu,
  toggleFilter,
  setNewsColumns,
  setNewsColumnsById,
  markNewsItemAsActive,
  updateActiveNewsItemColumnId,
  changeNewsColumnSort,
  setModalNewsItem,
  setActiveNewsItemLatestVersionId,
  setModalNewsItemLatestVersionId,
  setIsMobile
} = dashboardSlice.actions;
export {
  articleSearch,
  loadMoreArticlesByFilterId,
  fetchNewArticlesByFilterId,
  showLatestArticleVersion,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive
};
export default dashboardSlice.reducer;
