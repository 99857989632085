import React, { useEffect, useRef } from "react";

import { animate } from "framer-motion";
import PropTypes from "prop-types";

export default function NumberCounter({ from, to, duration }) {
  const nodeRef = useRef();
  const lastCounter = useRef(0);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from || lastCounter.current, to, {
      duration: duration,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      }
    });

    lastCounter.current = to;

    return () => controls.stop();
  }, [duration, from, to]);

  return <span ref={nodeRef} />;
}

NumberCounter.defaultProps = {
  duration: 0.75
};

NumberCounter.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number.isRequired,
  duration: PropTypes.number
};
