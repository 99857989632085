import React, { useMemo } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { useDispatch, useSelector } from "react-redux";

import style from "../../assets/scss/components/ui-element/calendar-content-filters.module.scss";
import { selectCalendarContentFilters } from "../../store/calendar/selector";
import { toggleContentFilter } from "../../store/calendar/slice";

function CalendarContentFilters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedContentFilters = useSelector(selectCalendarContentFilters);

  const contentFilterOptions = useMemo(
    () => [
      {
        id: "text",
        helpText: t("newsItem:calendarCoverages:text"),
        icon: <MaterialSymbol icon="article" size={24} />
      },
      {
        id: "picture",
        helpText: t("newsItem:calendarCoverages:pictures"),
        icon: <MaterialSymbol icon="photo" size={24} />
      },
      {
        id: "video",
        helpText: t("newsItem:calendarCoverages:video"),
        icon: <MaterialSymbol icon="play_circle" size={24} />
      },
      {
        id: "live_video",
        helpText: t("newsItem:calendarCoverages:liveVideo"),
        icon: <MaterialSymbol icon="videocam" size={24} />
      },
      {
        id: "undecided_under_consideration",
        helpText: t("newsItem:calendarCoverages:underConsideration"),
        icon: <MaterialSymbol icon="help" size={24} />
      }
      // {
      //   id: "infographics",
      //   helpText: t("newsItem:coverages:infographics"),
      //   icon: <MaterialSymbol icon="analytics" size={24} />
      // }
    ],
    [t]
  );

  return (
    <div className={style["calendar-content-filters"]}>
      {contentFilterOptions.map(option => (
        <button
          className={classNames(style["calendar-content-filters__item"], {
            [style["calendar-content-filters__item--active"]]: selectedContentFilters.includes(
              option.id
            )
          })}
          key={option.id}
          onClick={() => dispatch(toggleContentFilter(option.id))}
        >
          <Tooltip content={option.helpText}>{option.icon}</Tooltip>
        </button>
      ))}
    </div>
  );
}

export default CalendarContentFilters;
