import React, { useRef, useEffect } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import style from "../assets/scss/pages/calendar.module.scss";
import ActionRow from "../components/ActionRow";
import Calendar from "../components/Calendar";
import FilterMenu from "../components/FilterMenu";
import ArticleUrlModifier from "../components/helpers/ArticleUrlModifier";
import useDocumentTitle from "../helpers/useDocumentTitle";
import {
  selectAccessibleArticleFilters,
  selectArticleFilterIsMobile
} from "../store/articleFilter/selector";
import { enforceOneActiveFilterMobile } from "../store/articleFilter/slice";
import { setModalNewsItem, setIsMobile } from "../store/calendar/slice";
import { selectDashboardFilterMenuOpen } from "../store/dashboard/selector";

function CalendarPage() {
  const dispatch = useDispatch();
  const filterMenuOpen = useSelector(selectDashboardFilterMenuOpen, isEqual);
  const filters = useSelector(selectAccessibleArticleFilters, isEqual);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  useDocumentTitle();

  const asideRef = useRef(null);
  const filterLoadingStatus = !filters.length;

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [dispatch, isMobile]);

  useEffect(() => {
    if (isMobile && filters.length) {
      const allNewsFilter = filters.find(filter => filter.custom?.properties?.originalId === "all");
      if (allNewsFilter) {
        dispatch(enforceOneActiveFilterMobile({ id: allNewsFilter.id, calendarFilter: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLoadingStatus]);

  return (
    <>
      <ArticleUrlModifier
        service={"newscalendar"}
        onHardLoad={article => dispatch(setModalNewsItem(article))}
      />
      <ActionRow filterVisibility={"calendar"} />
      <div className={style["container"]}>
        <aside
          className={classNames(style["aside"], { [style["aside--open"]]: filterMenuOpen })}
          ref={asideRef}
        >
          <div className={style["aside__container"]}>
            <FilterMenu allNewsFilterName={"All events"} filterVisibility={"calendar"} />
          </div>
        </aside>
        <Calendar />
      </div>
    </>
  );
}

export default CalendarPage;
