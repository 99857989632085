import React from "react";

import style from "../assets/scss/components/news-item.module.scss";

import SkeletonBox from "./skeleton/SkeletonBox";

function NewsItemSkeleton() {
  return (
    <div className={style["item"]}>
      <SkeletonBox style={{ width: "35px", marginBottom: "10px", marginTop: "5px" }} />
      <SkeletonBox style={{ width: "80%", marginBottom: "5px" }} />
      <SkeletonBox style={{ width: "55%", marginBottom: "5px" }} />
    </div>
  );
}

export default NewsItemSkeleton;
