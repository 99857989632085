import BaseService from "./BaseService";

export default class AuthService extends BaseService {
  constructor() {
    super();
    super.setAction("login.action");
  }

  /**
   * Redirects the user to the CAS authentication service
   */
  login() {
    return super.changeLocation();
  }

  /**
   * Destroys the current user session and refreshes the page
   */
  logout() {
    super.setQuery({ logout: "" });
    return super.changeLocation();
  }
}
