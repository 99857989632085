import React, { useCallback, useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Tooltip } from "@ntbjs/react-components/data";
import { ReactComponent as AddIcon } from "@ntbjs/react-components/icons/add-circle.svg";
import { ReactComponent as EditIcon } from "@ntbjs/react-components/icons/edit.svg";
import { Button, MultiLevelCheckboxSelect, TextInput } from "@ntbjs/react-components/inputs";
import { SectionSeparator } from "@ntbjs/react-components/layout";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { debounce, isEmpty, isEqual } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import qs from "qs";
import ReactGA from "react-ga4";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as z from "zod";

import style from "../../assets/scss/components/search/filter-form.module.scss";
import { ALL_NEWS_SERVICES } from "../../helpers/constants";
import { calendarRoute, calendarSearchRoute } from "../../helpers/routes";
import ArticleService from "../../services/ArticleService";
import { queryStringBuilder } from "../../services/utils";
import {
  selectAccessibleDefaultArticleFilters,
  selectArticleFilters,
  selectMediaTopics,
  selectRegions
} from "../../store/articleFilter/selector";
import { addFilter, removeFilter, updateFilter } from "../../store/articleFilter/slice";
import Select from "../form/Select";
import NumberCounter from "../NumberCounter";
import Modal from "../ui-element/Modal";

const refineAtLeastOne = obj => {
  return (
    !isEmpty(obj.searchString) ||
    !isEmpty(obj.mediaTopics) ||
    !isEmpty(obj.regions) ||
    !isEmpty(obj.defaultFilters)
  );
};

const refineAtLeastOneOptions = {
  path: ["filters"],
  message: "Minimum et av feletene må fylles ut: Søkeord, Stoffgruppe, Tema eller Region"
};

export default function FilterForm({ filter = {}, inMenu = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const pathname = location?.pathname || "";
  const isCalendarPage = pathname == calendarRoute || pathname == calendarSearchRoute;

  const defaultFilters = useSelector(selectAccessibleDefaultArticleFilters, isEqual);
  const articleFilters = useSelector(selectArticleFilters, isEqual);
  const regionOptions = useSelector(selectRegions, isEqual);
  const mediaTopicOptions = useSelector(selectMediaTopics, isEqual);

  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [resultsCountLastWeek, setResultsCountLastWeek] = useState(null);
  const [selectedMediaTopicOptions, setSelectedMediaTopicOptions] = useState(
    filter.properties?.mediaTopics || []
  );

  const [selectedRegionOptions, setSelectedRegionOptions] = useState(
    filter.properties?.mediaTopics || []
  );

  const formSchema = useMemo(
    () =>
      z
        .object({
          id: z.number().nullable(),
          title: z
            .string()
            .min(1, { message: "Tittel er påkrevd" })
            .refine(
              string => {
                return !articleFilters
                  .filter(f => f.id !== filter.id)
                  .map(f => f.title.toLowerCase())
                  .includes(string.toLowerCase());
              },
              {
                message: t("search:filter:form:errors:uniqueName")
              }
            ),
          searchString: z.string().nullable(),
          mediaTopics: z.array(z.string()),
          regions: z.array(z.string()),
          defaultFilters: z.array(z.object({ label: z.string(), value: z.any() }))
        })
        .refine(refineAtLeastOne, refineAtLeastOneOptions),
    [articleFilters, filter.id, t]
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    control,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {
      id: filter?.id || null,
      mediaTopics: [],
      regions: [],
      defaultFilters: []
    }
  });

  const defaultFiltersOptions = useMemo(() => {
    return defaultFilters.map(filter => ({
      value: filter.title,
      label: t(filter.title)
    }));
  }, [t, defaultFilters]);

  const defaultFiltersToOptionObject = useCallback(
    defaultFilters => {
      if (isEmpty(defaultFilters) || isEmpty(defaultFiltersOptions)) {
        return [];
      }

      return defaultFiltersOptions.filter(defaultFilterOption =>
        defaultFilters.includes(defaultFilterOption.value)
      );
    },
    [defaultFiltersOptions]
  );

  const onSubmit = useCallback(
    formData => {
      if (submitting) return;
      setSubmitting(true);

      formData.defaultFilters = formData.defaultFilters.map(selectedOption => selectedOption.value);

      try {
        if (formData.id) {
          dispatch(updateFilter(formData));

          ReactGA.event({
            category: "Filter",
            action: "Edit Filter",
            label: qs.stringify(formData, { arrayFormat: "repeat" })
          });
        } else {
          dispatch(
            addFilter({
              data: { ...formData, calendarActive: isCalendarPage },
              calendarFilter: pathname == calendarRoute || pathname == calendarSearchRoute
            })
          );
          ReactGA.event({
            category: "Filter",
            action: "Add Filter",
            label: qs.stringify(
              { ...formData, calendarActive: isCalendarPage },
              { arrayFormat: "repeat" }
            )
          });
        }
      } catch (e) {
        // TODO: Add a toast notification
        console.error(e);
        alert("Innsending feilet");
      } finally {
        setSubmitting(false);

        // Wait until submit button animation is finished
        setTimeout(() => {
          setModalOpen(false);
          reset();
        }, 400);
      }
    },
    [dispatch, reset, submitting, isCalendarPage, pathname]
  );

  const openModal = useCallback(() => {
    reset(
      {
        id: filter.id || null,
        title: filter.title || "",
        searchString: filter.properties?.searchString || "",
        mediaTopics: filter.properties?.mediaTopics || [],
        regions: filter.properties?.regions || [],
        defaultFilters: defaultFiltersToOptionObject(filter.properties?.defaultFilters)
      },
      {}
    );

    setSelectedMediaTopicOptions(filter.properties?.mediaTopics || []);
    setSelectedRegionOptions(filter.properties?.regions || []);

    setModalOpen(true);
  }, [
    filter.properties?.mediaTopics,
    filter.properties?.regions,
    filter.properties?.searchString,
    filter.properties?.defaultFilters,
    filter.id,
    filter.title,
    reset,
    defaultFiltersToOptionObject
  ]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const onSelectedMediaTopicParentsChange = useCallback(
    async value => {
      setValue("mediaTopics", value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
      await trigger("filters");
    },
    [setValue, trigger]
  );

  const onSelectedRegionParentsChange = useCallback(
    async value => {
      setValue("regions", value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      await trigger("filters");
    },
    [setValue, trigger]
  );

  const debounceWatchFn = useCallback(
    values => {
      if (
        isEmpty(values.mediaTopics) &&
        isEmpty(values.regions) &&
        isEmpty(values.searchString) &&
        isEmpty(values.defaultFilters)
      ) {
        return setResultsCountLastWeek(null);
      }
      new ArticleService()
        .searchString(
          queryStringBuilder(
            values.searchString,
            [
              {
                active: true,
                calendarActive: true,
                properties: {
                  defaultFilters: values.defaultFilters.map(selectedOption => selectedOption.value),
                  mediaTopics: values.mediaTopics,
                  regions: values.regions,
                  service: ALL_NEWS_SERVICES
                }
              }
            ],
            { isCalendarFilter: isCalendarPage }
          )
        )
        .where(builder => {
          builder.service(ALL_NEWS_SERVICES);
          builder.startDate(
            DateTime.now().startOf("day").minus({ days: 7 }).toFormat("yyyy-MM-dd")
          );
          builder.endDate(DateTime.now().endOf("day").toFormat("yyyy-MM-dd"));
        })
        .showNumResults(0)
        .search()
        .then(results => {
          setResultsCountLastWeek(results.numResults);
        })
        .catch(() => setResultsCountLastWeek(null));
    },
    [isCalendarPage, setResultsCountLastWeek]
  );

  useEffect(() => {
    const subscription = watch(debounce(debounceWatchFn, 700));
    return () => subscription.unsubscribe();
  }, [watch, debounceWatchFn]);

  return (
    <>
      <div className={inMenu ? style["filter-form__modal__menu__link"] : ""} onClick={openModal}>
        {filter.id ? (
          <Tooltip content={t("search:filter:edit")}>
            <EditIcon />
          </Tooltip>
        ) : inMenu ? (
          <>
            <AddIcon width={20} />
            {t("search:actions:createFilter")}
          </>
        ) : (
          t("search:actions:createFilter")
        )}
      </div>

      <Modal className={style["filter-form__modal"]} open={modalOpen} onClose={closeModal}>
        <div className={style["filter-form__modal__header"]}>
          {filter?.id ? t("search:filter:Edit filter") : t("search:filter:Add new filter")}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("id")} />
          <div className={style["filter-form__modal__form__input"]}>
            <TextInput
              required
              autoComplete={"off"}
              label={t("search:filter:form:title")}
              {...register("title", { required: true })}
              error={errors.title?.message}
            />
          </div>
          <div className={style["filter-form__modal__form__input"]}>
            <SectionSeparator />
          </div>
          <div className={style["filter-form__modal__form__input"]}>
            <TextInput
              autoComplete={"off"}
              label={t("search:filter:form:searchString")}
              {...register("searchString", {
                deps: ["filters"]
              })}
              error={errors.searchString?.message}
            />
          </div>
          <div className={style["filter-form__modal__form__input"]}>
            <Controller
              control={control}
              name="defaultFilters"
              render={({ field: { onChange, value, ...field } }) => (
                <Select
                  {...field}
                  isMulti
                  defaultValue={value}
                  menuPosition={"fixed"}
                  options={defaultFiltersOptions}
                  placeholder={t("search:filter:form:defaultFilters")}
                  onChange={async selectedOptions => {
                    onChange(selectedOptions);
                    await trigger();
                  }}
                />
              )}
            />
          </div>
          <div className={style["filter-form__modal__form__input"]}>
            <MultiLevelCheckboxSelect
              checked={selectedMediaTopicOptions}
              label={"Tema"}
              options={mediaTopicOptions}
              onChange={setSelectedMediaTopicOptions}
              onParentChange={onSelectedMediaTopicParentsChange}
            />
          </div>
          <div className={style["filter-form__modal__form__input"]}>
            <MultiLevelCheckboxSelect
              checked={selectedRegionOptions}
              label={"Region"}
              options={regionOptions}
              onChange={setSelectedRegionOptions}
              onParentChange={onSelectedRegionParentsChange}
            />
          </div>
          {!isEmpty(errors.filters?.message) && (
            <div className={style["filter-form__modal__form__input__error"]}>
              {errors.filters?.message}
            </div>
          )}
          <div className={style["filter-form__modal__actions"]}>
            {filter?.id && (
              <button
                className={style["filter-form__modal__actions__delete"]}
                onClick={() => {
                  dispatch(removeFilter({ id: filter.id }));
                  ReactGA.event({
                    category: "Filter",
                    action: "Delete Filter",
                    label: qs.stringify(filter, { arrayFormat: "repeat" })
                  });
                }}
              >
                {t("search:filter:form:actions:delete")}
              </button>
            )}

            <AnimatePresence>
              {resultsCountLastWeek !== null && (
                <motion.div
                  animate={{ opacity: 0.8 }}
                  className={style["filter-form__modal__actions__counter"]}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                >
                  <NumberCounter duration={0.45} to={resultsCountLastWeek} />{" "}
                  {t("search:filter:form:actions:resultsLastWeekLabel")}
                </motion.div>
              )}
            </AnimatePresence>

            <Button secondary type="button" onClick={() => setModalOpen(false)}>
              {t("search:filter:form:actions:cancel")}
            </Button>
            <Button>{t("search:filter:form:actions:submit")}</Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

FilterForm.propTypes = {
  filter: PropTypes.object,
  inMenu: PropTypes.bool
};
