import { DateTime } from "luxon";

import BaseService from "./BaseService";

export default class CalendarService extends BaseService {
  constructor() {
    super();
  }

  async downloadIcal(calendarEventId) {
    super.setAction("ical.action");
    super.appendQuery({ downloadOne: "", id: calendarEventId });
    return super.changeLocation();
  }

  constructDateString(date) {
    const year = date.year;
    const month = this.getZeroPaddedString(date.month);
    const day = this.getZeroPaddedString(date.day);
    const hour = this.getZeroPaddedString(date.hour);
    const minute = this.getZeroPaddedString(date.minute);
    const second = this.getZeroPaddedString(date.second);
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  }

  getZeroPaddedString(value, numberOfZeros) {
    if (!numberOfZeros) {
      numberOfZeros = 2;
    }
    return String(value).padStart(numberOfZeros, "0");
  }

  async requestAddEvent(event = {}) {
    super.setAction("api/user/calender/newItem");

    const eventStartDate = DateTime.fromFormat(
      `${event.date.startDate} ${event.startTime}`,
      "dd.MM.yyyy hh:mm"
    );
    const eventStartDateOslo = DateTime.local(
      eventStartDate.year,
      eventStartDate.month,
      eventStartDate.day,
      eventStartDate.hour,
      eventStartDate.minute,
      { zone: "Europe/Oslo" }
    );
    const eventEndDate = DateTime.fromFormat(
      `${event.date.endDate} ${event.endTime}`,
      "dd.MM.yyyy hh:mm"
    );
    const eventEndDateOslo = DateTime.local(
      eventEndDate.year,
      eventEndDate.month,
      eventEndDate.day,
      eventEndDate.hour,
      eventEndDate.minute,
      { zone: "Europe/Oslo" }
    );

    if (eventEndDate < eventStartDate) {
      alert("Velg en gyldig dato");
    }

    super.setBody({
      additionalInfo: event.notes,
      contactMail: event.contactEmail,
      contactName: event.contactName,
      contactPhone: event.contactPhone,
      contactWeb: event.url,
      doc: {
        ntbService: "Nyhetskalender",
        category: event.category,
        subcategory: "Nyheter",
        priority: 5,
        contentMarkup: event.description,
        realCalendarData: {
          contactName: event.contactName,
          email: event.contactEmail,
          location: event.location,
          phone: event.contactPhone,
          url: event.url
        },
        // startdate: this.constructDateString(eventStartDate),
        // stopdate: this.constructDateString(eventEndDate),
        startdate: eventStartDateOslo,
        stopdate: eventEndDateOslo,
        title: event.title
      },
      iAddress: event.address,
      iLocation: event.location
    });
    await super.post();
  }
}
