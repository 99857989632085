import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  isArray,
  isEmpty,
  isEqual,
  isObject,
  mapValues,
  omit,
  omitBy,
  pick,
  transform
} from "lodash";

import { ALL_NEWS_SERVICES, DEFAULT_FILTERS } from "../../helpers/constants";
import NotificationService from "../../services/NotificationService";
import SearchFilterService from "../../services/SearchFilterService";
import UserService from "../../services/UserService";
import { setInitial } from "../notification/slice";

const userService = new UserService();

const defaultFilters = DEFAULT_FILTERS;

const notificationService = new NotificationService();

// Define an array of default filter properties that must be synchronized with database
const syncProps = ["accessible", "visibility", "properties", "custom"];

// Define an array of default filter custom properties that must not be synchronized with database
const ignoredCustomProps = ["properties.order", "properties.hiddenByUser"];

export const getFilters = createAsyncThunk(
  "articleFilter/getFilters",
  async (_payload, { dispatch }) => {
    let databaseSyncRequired = false;
    let userFilters = await userService.getAllFilters();

    // We'll check if the user has all default filters (by title). If not, we will add them to the
    // response and save them in the database.
    await Promise.all(
      defaultFilters.map(filter => {
        if (!userFilters.find(f => f.title === filter.title)) {
          databaseSyncRequired = true;
          return userService.addFilter(filter);
        }
      })
    );

    // We'll need to remove the default filters from the database if they no longer exist in the
    // defaultFilters array.
    await Promise.all(
      userFilters
        .filter(filter => filter.default)
        .map(filter => {
          // Check if filter is still in defaultFilters array. We do this by comparing the titles.
          if (!defaultFilters.some(defaultFilter => defaultFilter.title === filter.title)) {
            databaseSyncRequired = true;

            return userService.removeFilter(filter);
          }
        })
    );

    // Update filter properties in the database when they changed for the default filters
    await Promise.all(
      defaultFilters.map(filter => {
        const userFilter = userFilters.find(f => f.title === filter.title);

        if (!userFilter) return;

        function deepSort(obj) {
          if (isArray(obj)) {
            return obj.map(deepSort).sort();
          } else if (isObject(obj)) {
            return mapValues(obj, deepSort);
          }
          return obj;
        }

        let userFilterProperty = transform(pick(userFilter, syncProps), (result, value, key) => {
          // Get rid of null values since database adds them for missing properties
          if (value === null) return;

          if (key === "custom") {
            let custom = omit(value, ignoredCustomProps);
            custom = omitBy(custom, isEmpty);
            if (isEmpty(custom)) {
              return;
            }
            return (result[key] = custom);
          }

          if (isArray(value)) {
            return (result[key] = value);
          }

          if (isObject(value)) {
            return (result[key] = omitBy(value, isEmpty));
          }

          result[key] = value;
        });
        userFilterProperty = deepSort(userFilterProperty);

        let defaultFilterProperty = transform(pick(filter, syncProps), (result, value, key) => {
          // Get rid of null values since database adds them for missing properties
          if (value === null) return;

          if (key === "custom") {
            let custom = omit(value, ignoredCustomProps);
            custom = omitBy(custom, isEmpty);
            if (isEmpty(custom)) {
              return;
            }
            return (result[key] = custom);
          }

          if (isArray(value)) {
            return (result[key] = value);
          }

          if (isObject(value)) {
            return (result[key] = omitBy(value, isEmpty));
          }

          result[key] = value;
        });
        defaultFilterProperty = deepSort(defaultFilterProperty);

        if (!isEqual(userFilterProperty, defaultFilterProperty)) {
          databaseSyncRequired = true;
          return userService.updateFilter(Object.assign(userFilter, defaultFilterProperty));
        }
      })
    );

    // The filters has been updated in the database, so we'll need to fetch them again
    // (we need real DB ids in the front-end).
    if (databaseSyncRequired) {
      userFilters = await userService.getAllFilters();
    }

    // The backend returns filters sorted by ID in DESC order,
    // reverse the filters to follow the order in which they have been created
    userFilters.reverse();

    // Sort filters from the DB to match the order of the `defaultFilters` array.
    userFilters.sort((a, b) => {
      const aIndex = defaultFilters.findIndex(filter => filter.title === a.title);
      const bIndex = defaultFilters.findIndex(filter => filter.title === b.title);

      if (aIndex > bIndex || aIndex === -1) {
        return 1;
      }

      if (aIndex < bIndex) {
        return -1;
      }

      return 0;
    });

    // const myNews = await userService.getMyNews();
    // userFilters = userFilters.concat(myNews);

    // Attach notifications to filters
    let notifications = await notificationService.getAll();
    dispatch(setInitial(notifications));
    let userNotifications = notifications.userNotification;
    const userNotificationsForFilters = userNotifications.filter(n => n.name.startsWith("filter:"));

    let notificationDbSyncRequired = false;

    const createPromises = [];
    for (const filter of userFilters) {
      const filterNotificationExists = userNotificationsForFilters
        .map(x => x.name)
        .includes(`filter:${filter.id}`);

      // Check if filter has a corresponding notification
      if (!filterNotificationExists) {
        // Create notification without any active delivery types
        createPromises.push(notificationService.create(filter, []));
        notificationDbSyncRequired = true;
      }
    }

    await Promise.all(createPromises);

    if (notificationDbSyncRequired) {
      notifications = await notificationService.getAll();
      dispatch(setInitial(notifications));
    }

    userFilters = userFilters.map(filter => ({
      ...filter,
      notification: notifications.userNotification.find(n => n.name === `filter:${filter.id}`)
    }));

    // Remove notifications that are no longer matching any filter
    for (const notification of userNotificationsForFilters) {
      if (!userFilters.some(f => `filter:${f.id}` === notification.name)) {
        // remove notification that is no longer matching any filter
        notificationService.delete(notification.id);
      }
    }

    return userFilters;
  }
);

export const addFilter = createAsyncThunk(
  "articleFilter/addFilter",
  async (payload, { getState }) => {
    const { title, searchString, mediaTopics, regions, defaultFilters } = payload.data;
    const calendarFilter = payload.calendarFilter;

    let newFilter = {
      title: title,
      default: false,
      accessible: true,
      active: true,
      calendarActive: true,
      visibility: ["newsfeed", "calendar"],
      properties: {
        service: ALL_NEWS_SERVICES,
        searchString,
        mediaTopics,
        regions,
        defaultFilters
      }
    };

    newFilter.id = await userService.addFilter(newFilter);
    newFilter.userId = getState().user.profile.id;

    newFilter.notification = await notificationService.create(
      {
        ...newFilter,
        id: newFilter.id
      },
      []
    );

    return { newFilter: newFilter, calendarFilter: calendarFilter };
  }
);

export const toggleNotificationType = createAsyncThunk(
  "articleFilter/toggleNotificationType",
  async (data, { getState }) => {
    const { filterId } = data;

    const filters = getState().articleFilter.filters;
    const index = filters.findIndex(filter => filter.id === filterId);

    if (index === -1) return;

    const notificationService = new NotificationService();

    const filter = filters[index];

    // We can just update with `filter.notification.types` directly, since the state is already
    // updated by `toggleNotificationType.pending`.

    return await notificationService.updateByFilter(filter, filter.notification.types, true);
  }
);

export const getMediaTopics = createAsyncThunk("articleFilter/getMediaTopics", async () => {
  const searchFilterService = new SearchFilterService();
  const mediaTopics = await searchFilterService.getMediaTopics();
  if (typeof mediaTopics == "undefined" || !Array.isArray(mediaTopics)) {
    return [];
  }
  return mediaTopics;
});

export const getRegions = createAsyncThunk("articleFilter/getRegions", async () => {
  const searchFilterService = new SearchFilterService();
  const regions = await searchFilterService.getRegions();
  if (typeof regions == "undefined" || !Array.isArray(regions)) {
    return [];
  }
  return regions;
});
