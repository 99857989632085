import React from "react";

import style from "../assets/scss/pages/counter.module.scss";
import { Counter } from "../components/widget/Counter";
import logo from "../logo.svg";

function CounterPage() {
  return (
    <div className={style["app"]}>
      <header className={style["app-header"]}>
        <img alt="logo" className={style["app-logo"]} src={logo} />
        <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className={style["app-link"]}
            href="https://reactjs.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            React
          </a>
          <span>, </span>
          <a
            className={style["app-link"]}
            href="https://redux.js.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className={style["app-link"]}
            href="https://redux-toolkit.js.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className={style["app-link"]}
            href="https://react-redux.js.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  );
}

export default CounterPage;
