import React from "react";

import classNames from "classnames";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import Component from "react-component-is";

import style from "../../assets/scss/components/ui-element/label.module.scss";

const Label = ({ children, ...rest }) => {
  return (
    <Component
      is={isFunction(rest.onClick) ? "button" : "div"}
      {...rest}
      className={classNames(style["label"], rest.className)}
    >
      {children}
    </Component>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired
};

export default Label;
