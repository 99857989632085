export const selectCalendarStartDate = state => state.calendar.startDate;

export const selectCalendarEndDate = state => state.calendar.endDate;

export const selectCalendarColumns = state => state.calendar.columns;

export const selectCalendarReadNewsItemIds = state => state.calendar.readNewsItems;

export const selectCalendarUpdatedNewsItemIds = state => state.calendar.updatedNewsItems;

export const selectCalendarActiveNewsItem = state => {
  return selectCalendarArticleById(selectCalendarActiveNewsItemId(state))(state);
};

export const selectCalendarActiveNewsItemId = state => state.calendar.activeNewsItemId;

export const selectCalendarActiveNewsItemColumnId = state => state.calendar.activeNewsItemColumnId;

export const selectCalendarModalNewsItem = state => state.calendar.modalNewsItem;

export const selectCalendarColumnById = id => state =>
  selectCalendarColumns(state).find(column => column.id === id);

export const selectCalendarArticleById = id => state => {
  const column = state.calendar.columns.find(column =>
    column.articles.some(article => article.id === id)
  );

  return column ? column.articles.find(article => article.id === id) : null;
};

export const selectCalendarActiveNewsItemColumn = state => {
  return selectCalendarColumnById(selectCalendarActiveNewsItemColumnId(state))(state);
};

export const selectCalendarViewMode = state => state.calendar.viewMode;

export const selectCalendarContentFilters = state => state.calendar.coverageFilters;

export const selectCalendarIsMobile = state => state.calendar.isMobile;
