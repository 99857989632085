import React from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { ReactComponent as PrintIcon } from "../../../assets/icons/print.svg";
import style from "../../../assets/scss/components/article/actions/article-print.module.scss";

function Print({ article }) {
  const { t } = useTranslation();

  const handlePrintClick = () => {
    // See usePrint() custom hook.
    // Is there a better way to distinguish custom print button click
    // and Ctrl+P in the onbeforeprint event handler?
    window.customPrintButtonIsClicked = true;
    window.print();
    delete window.customPrintButtonIsClicked;

    ReactGA.event({
      category: "Article",
      action: "Print button",
      label: article.title
    });
  };

  return (
    <div className={style["article-print-wrapper"]}>
      <Tooltip content={t("articleView:actions:print")} interactive={true}>
        <PrintIcon className={style["article-print"]} onClick={handlePrintClick} />
      </Tooltip>
    </div>
  );
}

Print.propTypes = {
  article: PropTypes.object
};

export default Print;
