import { DEFAULT_FILTERS } from "../helpers/constants";

export const queryStringBuilder = (string, filters, options) => {
  options = options || {};
  const isCalendarFilter = options.calendarFilter;
  let searchQueryGroups = [];

  if (string) searchQueryGroups.push(string);

  const activeFilters = filters
    .filter(filter => (isCalendarFilter ? filter.calendarActive : filter.active))
    .filter(filter => filter.custom?.properties?.hiddenByUser !== "true");

  if (activeFilters) {
    const filterQueryList = activeFilters
      .map(filter => {
        return buildFilterSearchQuery(filter, options);
      })
      .filter(filterQuery => filterQuery);

    if (filterQueryList.length !== 0)
      searchQueryGroups.push("(" + filterQueryList.join(" OR ") + ")");
  }

  if (searchQueryGroups.length === 0) return null;
  return searchQueryGroups.join(" AND ");
};

const buildFilterSearchQuery = (filter, options) => {
  let filterQuery = [];
  const {
    searchString,
    service,
    categories,
    subjects,
    mediaTopics,
    regions,
    subcategories,
    defaultFilters: filterDefaultFilters
  } = filter.properties;

  if (searchString) {
    filterQuery.push("(" + searchString + ")");
  }

  if (service && !options.ignoreService) {
    filterQuery.push("(" + service.map(item => `type:${item}`).join(" OR ") + ")");
  }

  if (categories && categories.length) {
    const mappedCategories = getMappedCategories(categories);
    let categoriesNotIn = [];
    let categoriesIn = [];

    for (let i = 0; i < mappedCategories.length; i++) {
      if (mappedCategories[i].startsWith("NOT ")) {
        categoriesNotIn.push(mappedCategories[i].replace("NOT ", ""));
      } else {
        categoriesIn.push(mappedCategories[i]);
      }
    }

    if (categoriesNotIn.length) {
      filterQuery.push(
        "(" + categoriesNotIn.map(category => `NOT (category:(${category}))`).join(" AND ") + ")"
      );
    }

    if (categoriesIn.length) {
      filterQuery.push(
        "(" + categoriesIn.map(category => `category:(${category})`).join(" OR ") + ")"
      );
    }
  }

  if (subcategories && subcategories.length) {
    let subcategoriesNotIn = [];
    let subcategoriesIn = [];

    for (let i = 0; i < subcategories.length; i++) {
      if (subcategories[i].startsWith("NOT ")) {
        subcategoriesNotIn.push(subcategories[i].replace("NOT ", ""));
      } else {
        subcategoriesIn.push(subcategories[i]);
      }
    }

    if (subcategoriesNotIn.length) {
      filterQuery.push(
        "(" +
          subcategoriesNotIn
            .map(subcategory => `NOT (subcategory:(${subcategory}))`)
            .join(" AND ") +
          ")"
      );
    }

    if (subcategoriesIn.length) {
      filterQuery.push(
        "(" + subcategoriesIn.map(subcategory => `subcategory:(${subcategory})`).join(" OR ") + ")"
      );
    }
  }

  if (subjects) {
    filterQuery.push(
      "(" + subjects.map(subject => `subjects.reference:(${subject})`).join(" OR ") + ")"
    );
  }

  if (mediaTopics && mediaTopics.length > 0) {
    filterQuery.push(
      "(" +
        mediaTopics.map(mediaTopic => `mediaTopics.reference:(${mediaTopic})`).join(" OR ") +
        ")"
    );
  }

  if (regions && regions.length > 0) {
    filterQuery.push(
      "(" + regions.map(region => `region:(${region}) OR district:(${region})`).join(" OR ") + ")"
    );
  }

  if (filterDefaultFilters && filterDefaultFilters.length) {
    const defaultFilterQueryList = filterDefaultFilters
      .map(filter => {
        const defaultFilter = DEFAULT_FILTERS.find(f => f.title === filter);
        return defaultFilter ? buildFilterSearchQuery(defaultFilter, options) : "";
      })
      .filter(filterQuery => filterQuery);

    if (defaultFilterQueryList.length !== 0)
      filterQuery.push("(" + defaultFilterQueryList.join(" OR ") + ")");
  }

  return filterQuery.length !== 0 ? "(" + filterQuery.join(" AND ") + ")" : "";
};

export const getMappedCategories = categories => {
  return categories.map(category => {
    // Use category mapping if there is defined one otherwise use the actual category name
    return categoryMap[category] || category;
  });
};

export const categoryMap = {
  domestic: "Innenriks",
  sport: "Sport",
  foreign: "Utenriks",
  culture: "Kultur",
  prm: "PRM-NTB",
  ir: "Børsmelding"
};
