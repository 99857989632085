import React, { useCallback, useMemo, useState } from "react";

import cn from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-long.svg";
import style from "../../assets/scss/components/ui-element/notification-settings-table.module.scss";

function NotificationSettingsTable({ label, notifications: notificationsProp }) {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState("DESC");

  const toggleSortOrder = useCallback(() => {
    setSortOrder(currentSortOrder => {
      return currentSortOrder === "DESC" ? "ASC" : "DESC";
    });
  }, []);

  const notifications = useMemo(() => {
    let sortedNotifications = notificationsProp.sort((a, b) => a.label.localeCompare(b.label));

    if (sortOrder === "ASC") {
      sortedNotifications = sortedNotifications.reverse();
    }

    return sortedNotifications;
  }, [notificationsProp, sortOrder]);

  return (
    <div className={style["table"]}>
      <div className={style["table__header"]}>
        <div>{label}</div>
        <div
          className={cn(style["table__header__sort"], {
            [style["table__header__sort--reversed"]]: sortOrder === "ASC"
          })}
          onClick={toggleSortOrder}
        >
          A-Å
          <div>
            <ArrowIcon />
          </div>
        </div>
      </div>
      <div className={style["table__body"]}>
        {notifications.length > 0 ? (
          notifications.map(notification => (
            <div
              className={style["table__body__row"]}
              key={`notification-table-${label}-${notification.label}`}
            >
              <div>{notification.label}</div>
              <div className={style["table__body__row__actions"]}>{notification.actions}</div>
            </div>
          ))
        ) : (
          <div className={cn(style["table__body__row"], style["table__body__row--empty"])}>
            {t("settings:notifications:noFilterMatched")}
          </div>
        )}
      </div>
    </div>
  );
}

NotificationSettingsTable.propTypes = {
  label: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      actions: PropTypes.element.isRequired
    })
  ).isRequired
};

export default NotificationSettingsTable;
