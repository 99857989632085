import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { DEFAULT_SHOW_NUM_RESULTS } from "../../helpers/constants";
import ArticleService from "../../services/ArticleService";

import { markNewsItemAsActive, setModalNewsItem } from "./slice";

async function search(
  filter,
  sortOrder,
  latest,
  lastFetchedId = null,
  offset = 0,
  numResults = DEFAULT_SHOW_NUM_RESULTS
) {
  const filterProperties = filter.properties;
  const articleService = new ArticleService();

  return await articleService
    .where(builder => {
      builder.service(filterProperties.service);
      builder.searchQuery("", [filter]);
      builder.sortOrder(sortOrder);
      builder.latest(latest);
      builder.idGreaterThan(lastFetchedId);
      if (sortOrder !== "ID_DESC") builder.startDate(DateTime.now().minus({ hours: 24 }).toISO());
    })
    .offset(offset)
    .showNumResults(numResults)
    .search();
}

export const articleSearch = createAsyncThunk(
  "dashboard/articleSearch",
  async (filterId = "", { getState, fulfillWithValue, rejectWithValue }) => {
    const filters = getState().articleFilter.filters;
    const sortOrder = getState().dashboard.columns.find(column => column.id === filterId).sort;
    const latest = getState().user.preferences.articleVersion === "latest";

    const filter = filters.find(filter => filter.id === filterId);

    try {
      const articles = await search(filter, sortOrder, latest, null, 0);

      return fulfillWithValue(articles);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadMoreArticlesByFilterId = createAsyncThunk(
  "dashboard/loadMoreArticlesByFilterId",
  (filterId = "", { getState }) => {
    const filters = getState().articleFilter.filters;
    const latest = getState().user.preferences.articleVersion === "latest";

    const filter = filters.find(filter => filter.id === filterId);

    const column = getState().dashboard.columns.find(column => column.id === filterId);

    return search(filter, column.sort, latest, null, column.articles.length);
  }
);

export const fetchNewArticlesByFilterId = createAsyncThunk(
  "dashboard/fetchNewArticlesByFilterId",
  async (filterId = "", { getState }) => {
    const filters = getState().articleFilter.filters;

    const filter = filters.find(filter => filter.id === filterId);
    const latest = getState().user.preferences.articleVersion === "latest";

    const column = getState().dashboard.columns.find(column => column.id === filterId);
    const lastFetchedId = Math.max(...column.articles.map(article => article.id));

    let response;
    if (column.sort === "ID_DESC") {
      response = await search(filter, column.sort, latest, lastFetchedId, 0);
    } else {
      let numResults =
        column.articles.length >= DEFAULT_SHOW_NUM_RESULTS
          ? column.articles.length
          : DEFAULT_SHOW_NUM_RESULTS;

      // Are there any extra articles added to the column that backend is not aware of?
      // If so we must reduce the number of results to fetch.
      if (column.extraArticles && column.extraArticles.length > 0) {
        numResults -= column.extraArticles.length;
      }

      response = await search(filter, column.sort, latest, null, 0, numResults);
    }

    response.latest = latest;
    return response;
  }
);

export const showLatestArticleVersion = (id, versions, modal) => (dispatch, getState) => {
  const state = getState();
  const dashboard = state.dashboard;
  let foundArticle;

  // When an article displayed in a modal we must pass an article object of the latest article
  if (modal) {
    // First check if latest version available in the versions list.
    foundArticle = versions.find(version => version.id === id);

    // Then try finding the latest article version by id through all columns.
    if (!foundArticle) {
      for (let i = 0; i < dashboard.columns.length; i++) {
        foundArticle = dashboard.columns[i].articles.find(article => article.id === id);

        if (foundArticle) break;
      }

      // Still no article found? We must never get to this point.
      if (!foundArticle) {
        return dispatch(setModalNewsItem({}));
      }
    }

    dispatch(setModalNewsItem(foundArticle));
  }
  // Handle switch to the latest version for inline articleView
  else {
    // Is the latest article version placed in the same column?
    const columnIndex = dashboard.columns.findIndex(
      column => column.id === dashboard.activeNewsItemColumnId
    );
    foundArticle = dashboard.columns[columnIndex].articles.find(article => article.id === id);

    // The latest version is placed in the same column
    // therefore we only need to change activeNewsItemId but keep the same activeNewsItemColumnId
    if (foundArticle) {
      dispatch(
        markNewsItemAsActive({
          id
        })
      );
    }
    // The latest version most likely placed into different column hence we show it in the modal
    else {
      dispatch(showLatestArticleVersion(id, versions, true));
    }
  }
};

export const markNextNewsItemAsActive = () => (dispatch, getState) => {
  const state = getState();
  const dashboard = state.dashboard;

  // if no article is selected, select the first article in the first column
  if (!dashboard.activeNewsItemId) {
    if (!dashboard.columns[0].articles.length) return;

    return dispatch(
      markNewsItemAsActive({
        id: dashboard.columns[0].articles[0].id,
        columnId: dashboard.columns[0].id
      })
    );
  }

  const currentColumn = dashboard.columns.find(
    column => column.id === dashboard.activeNewsItemColumnId
  );

  const nextActiveNewsItemIndex =
    currentColumn.articles.findIndex(article => article.id === dashboard.activeNewsItemId) + 1;

  if (nextActiveNewsItemIndex > currentColumn.articles.length - 1) return;

  const nextActiveNewsItemId = currentColumn.articles[nextActiveNewsItemIndex].id;

  dispatch(
    markNewsItemAsActive({
      id: nextActiveNewsItemId,
      columnId: dashboard.activeNewsItemColumnId
    })
  );
};

export const markPreviousNewsItemAsActive = () => (dispatch, getState) => {
  const state = getState();
  const dashboard = state.dashboard;

  if (!dashboard.activeNewsItemId) {
    if (!dashboard.columns[0].articles.length) return;

    return dispatch(
      markNewsItemAsActive({
        id: dashboard.columns[0].articles[0].id,
        columnId: dashboard.columns[0].id
      })
    );
  }

  const currentColumn = dashboard.columns.find(
    column => column.id === dashboard.activeNewsItemColumnId
  );

  const previousActiveNewsItemIndex =
    currentColumn.articles.findIndex(article => article.id === dashboard.activeNewsItemId) - 1;

  if (previousActiveNewsItemIndex < 0) return;

  const previousActiveNewsItemId = currentColumn.articles[previousActiveNewsItemIndex].id;

  dispatch(
    markNewsItemAsActive({
      id: previousActiveNewsItemId,
      columnId: dashboard.activeNewsItemColumnId
    })
  );
};

export const markNextNewsColumnAsActive = () => (dispatch, getState) => {
  const state = getState();
  const dashboard = state.dashboard;

  if (!dashboard.activeNewsItemColumnId) {
    return dispatch(
      markNewsItemAsActive({
        id: dashboard.columns[0].articles[0].id,
        columnId: dashboard.columns[0].id
      })
    );
  }

  const currentColumnIndex = dashboard.columns.findIndex(
    column => column.id === dashboard.activeNewsItemColumnId
  );

  if (currentColumnIndex === dashboard.columns.length - 1) return;

  return dispatch(
    markNewsItemAsActive({
      id: dashboard.columns[currentColumnIndex + 1].articles[0].id,
      columnId: dashboard.columns[currentColumnIndex + 1].id
    })
  );
};

export const markPreviousNewsColumnAsActive = () => (dispatch, getState) => {
  const state = getState();
  const dashboard = state.dashboard;

  if (!dashboard.activeNewsItemColumnId) {
    return dispatch(
      markNewsItemAsActive({
        id: dashboard.columns[0].articles[0].id,
        columnId: dashboard.columns[0].id
      })
    );
  }

  const currentColumnIndex = dashboard.columns.findIndex(
    column => column.id === dashboard.activeNewsItemColumnId
  );

  if (currentColumnIndex === 0) return;

  return dispatch(
    markNewsItemAsActive({
      id: dashboard.columns[currentColumnIndex - 1].articles[0].id,
      columnId: dashboard.columns[currentColumnIndex - 1].id
    })
  );
};
