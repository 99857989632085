import React, { useRef, useEffect, useCallback } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import { ReactComponent as CloseIcon } from "../../assets/icons/clear-alt.svg";
import style from "../../assets/scss/components/ui-element/modal.module.scss";
import { hotkeys, hotkeyScopes, useHotkey, useHotkeyScope } from "../../helpers/hotkeys";

function Modal({ open, onClose, showCloseButton, size, className, children }) {
  useHotkeyScope(hotkeyScopes.MODAL, open);
  useHotkey(hotkeys.CLOSE_MODAL, () => {
    onClose();
  });

  const modalBackdropRef = useRef(null);
  const modalCloseButtonRef = useRef(null);

  // set the modal as the focused element when it opens
  useEffect(() => {
    if (open) {
      modalBackdropRef.current?.focus();
    }
  }, [open]);

  const onModalClose = useCallback(
    event => {
      if (
        event.target === modalBackdropRef.current ||
        event.target === modalCloseButtonRef.current
      ) {
        onClose();
      }
    },
    [onClose]
  );

  if (!open) return null;

  const Modal = (
    <div
      className={style["modal-backdrop"]}
      ref={modalBackdropRef}
      tabIndex="0"
      onClick={onModalClose}
    >
      <div
        className={classNames(
          style["modal"],
          { [style["modal--small"]]: size === "small" },
          { [style["modal--large"]]: size === "large" },
          className
        )}
      >
        {showCloseButton && (
          <div
            className={style["modal__close-button"]}
            ref={modalCloseButtonRef}
            onClick={onModalClose}
          >
            <CloseIcon />
          </div>
        )}

        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(Modal, document.body);
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  children: PropTypes.node
};

Modal.defaultProps = {
  showCloseButton: true
};

export default Modal;
