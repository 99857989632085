import React, { useRef } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import style from "../../assets/scss/components/article/caption.module.scss";
import { selectArticleFilterIsMobile } from "../../store/articleFilter/selector";

import ReadMoreLess from "./ReadMoreLess";

function Caption({ children, charLimit = 100, mediaId = "" }) {
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  const onClick = (e, mediaId) => {
    if (mediaId) {
      const readMoreLessClicked = e.target.classList.contains("react-read-more-read-less");

      if (readMoreLessClicked) {
        const captionClonedElements = document.querySelectorAll(
          "[data-media-id='" + mediaId + "'] .react-read-more-read-less"
        );

        captionClonedElements.forEach(el => {
          if (el !== e.target) {
            // Make sure that cloned captions are sync with the original ones.
            // E.g. when slick slides are cloned.
            el.click();
          }
        });
      }
    }
  };

  if (!children) return null;

  return (
    <div className={style["caption"]} data-media-id={mediaId} onClick={e => onClick(e, mediaId)}>
      <ReadMoreLess maxLength={!isMobile ? charLimit : 90}>{children}</ReadMoreLess>
    </div>
  );
}

Caption.propTypes = {
  charLimit: PropTypes.number,
  children: PropTypes.any,
  mediaId: PropTypes.string
};

export default Caption;
