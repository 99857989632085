import { useRef, useEffect } from "react";

import i18next from "i18next";
import { useLocation } from "react-router-dom";

import {
  indexRoute,
  calendarRoute,
  newsSearchRoute,
  calendarSearchRoute,
  settingsRoute,
  settingsAccountRoute,
  notificationsAccountRoute
} from "./routes";

export function getDocumentTitle(title, pathname) {
  const titlePrefix = "NTB Nyheter | ";
  let documentTitle;

  if (title) {
    documentTitle = title;
  } else {
    switch (pathname) {
      case indexRoute:
      case indexRoute.replace(/\/$/, ""):
        documentTitle = i18next.t("common:News feed");
        break;
      case newsSearchRoute:
        documentTitle = i18next.t("common:News search");
        break;
      case calendarRoute:
        documentTitle = i18next.t("common:News calendar");
        break;
      case calendarSearchRoute:
        documentTitle = i18next.t("common:Newscalendar search");
        break;
      case settingsRoute:
      case settingsAccountRoute:
      case notificationsAccountRoute:
        documentTitle = i18next.t("common:User settings");
        break;
      default:
        return "NTB Nyheter";
    }
  }

  return `${titlePrefix}${documentTitle}`;
}

function useDocumentTitle(title, prevailOnUnmount = false) {
  const { pathname } = useLocation();
  const defaultTitle = useRef(document.title);
  const documentTitle = getDocumentTitle(title, pathname);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useDocumentTitle;
