import React, { useState, useEffect } from "react";

import { Popover } from "@ntbjs/react-components/data";
import { ActionButton } from "@ntbjs/react-components/inputs";
import classNames from "classnames";
import nb from "date-fns/locale/nb";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import "react-datepicker/src/stylesheets/datepicker.scss";
import style from "../../assets/scss/components/form/datepicker.module.scss";
import { DEFAULT_DATE_FORMAT } from "../../helpers/constants";
import PopoverMenu from "../navigation/PopoverMenu";

import TextInput from "./TextInput";

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

registerLocale("nb", nb);

const DatePickerRange = React.forwardRef(function DatePickerRange(props, ref) {
  const { t } = useTranslation();
  const { placeholder, block, options, onChange, ...restProps } = props;

  const allowClear = typeof restProps.allowClear !== "undefined" ? restProps.allowClear : true;

  const initialStartDateLuxon = restProps.startDate
    ? DateTime.fromFormat(restProps.startDate, DEFAULT_DATE_FORMAT)
    : "";
  const initialEndDateLuxon = restProps.endDate
    ? DateTime.fromFormat(restProps.endDate, DEFAULT_DATE_FORMAT)
    : "";

  const initialStartDate = initialStartDateLuxon.isValid
    ? new Date(
        initialStartDateLuxon.year,
        initialStartDateLuxon.month - 1,
        initialStartDateLuxon.day
      )
    : "";
  let initialEndDate = initialEndDateLuxon.isValid
    ? new Date(initialEndDateLuxon.year, initialEndDateLuxon.month - 1, initialEndDateLuxon.day)
    : "";
  if (initialStartDate && initialEndDate && initialEndDateLuxon.diff(initialStartDateLuxon) < 0) {
    initialEndDate = "";
  }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const [minDate, setMinDate] = useState(
    initialStartDate && !initialEndDate ? initialStartDate : undefined
  );
  const [maxDate, setMaxDate] = useState(
    restProps.maxDays && !initialEndDate ? addDays(initialStartDate, restProps.maxDays) : undefined
  );
  const [selectsDate, setSelectsDate] = useState(false);
  const [calOpen, setCalOpen] = useState(false);

  const [popoverMenuOpen, setPopoverMenuOpen] = useState(false);
  const showPopover = () => setPopoverMenuOpen(true);
  const hidePopover = () => setPopoverMenuOpen(false);

  const optionSet = {
    today: t("datepicker:Today"),
    tomorrow: t("datepicker:Tomorrow"),
    next7days: t("datepicker:Next 7 days"),
    next30days: t("datepicker:Next 30 days"),
    next90days: t("datepicker:Next 90 days"),
    allUpcoming: t("datepicker:allUpcoming"),
    yesterday: t("datepicker:Yesterday"),
    last7days: t("datepicker:Last 7 days"),
    last30days: t("datepicker:Last 30 days"),
    last180days: t("datepicker:Last 6 months"),
    last365days: t("datepicker:Last 12 months")
  };

  const dateFormat = DEFAULT_DATE_FORMAT;

  let calendar = null;

  useEffect(() => {
    setStartDate(initialStartDate);
    //setMinDate(initialStartDate);
    setEndDate(initialEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.startDate, restProps.endDate]);

  const handleDateSelect = date => {
    date = processDateByLocalZone(date);
    setSelectsDate(!startDate || !!endDate);
    if (!startDate || endDate) {
      setStartDate(date);
      setEndDate("");
      setMinDate(date);

      if (restProps.maxDays) {
        setMaxDate(addDays(date, restProps.maxDays));
      }
    } else if (startDate && date < startDate) {
      handleDateRange(date, "");
      setCalOpen(false);
    } else {
      handleDateRange(startDate, date);
      setCalOpen(false);
    }
  };

  const processDateByLocalZone = date => {
    if (date) {
      date = DateTime.fromFormat(
        DateTime.fromJSDate(date, { zone: "local" }).toFormat(dateFormat),
        dateFormat,
        { zone: "local" }
      );
      return new Date(date.year, date.month - 1, date.day);
    }

    return date;
  };

  const processDateByConfiguredZone = date => {
    if (date) {
      date = DateTime.fromFormat(DateTime.fromJSDate(date).toFormat(dateFormat), dateFormat);

      return new Date(date.year, date.month - 1, date.day);
    }

    return date;
  };

  const handleDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setMinDate(undefined);
    setMaxDate(undefined);
    setSelectsDate(false);

    start = start ? DateTime.fromJSDate(start, { zone: "local" }).toFormat(dateFormat) : "";
    end = end ? DateTime.fromJSDate(end, { zone: "local" }).toFormat(dateFormat) : "";
    onChange(start, end);
  };

  const handleCalendarOpen = () => {};

  const handleCalendarClose = () => {
    setCalOpen(false);
    handleDateRange(processDateByLocalZone(startDate), processDateByLocalZone(endDate));
  };
  const getCalendar = c => {
    calendar = c;
  };

  const openDatepickerPortal = () => {
    hidePopover();
    setCalOpen(true);
    if (calendar) {
      calendar.setOpen(true);
    }
  };

  const handlePresetDate = preset => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let end = "",
      start = today;
    switch (preset) {
      case "today":
        end = today;
        break;
      case "tomorrow":
        start = addDays(today, +1);
        end = start;
        break;
      case "next7days":
        end = addDays(today, +6);
        break;
      case "next30days":
        end = addDays(today, +29);
        break;
      case "next90days":
        end = addDays(today, +89);
        break;
      case "yesterday":
        start = addDays(today, -1);
        end = start;
        break;
      case "last7days":
        start = addDays(today, -6);
        end = today;
        break;
      case "last30days":
        start = addDays(today, -29);
        end = today;
        break;
      case "last180days":
        start = addDays(today, -179);
        end = today;
        break;
      case "last365days":
        start = addDays(today, -364);
        end = today;
        break;
      case "allUpcoming":
        start = today;
        end = "";
        break;
      case "clear":
      default:
        start = "";
    }

    hidePopover();
    handleDateRange(start, end);
  };

  const renderDatePicker = () => {
    return (
      calOpen && (
        <div className={style["datepicker-range__popover-container"]}>
          <DatePicker
            {...restProps}
            autoFocus
            inline
            showMonthDropdown
            showYearDropdown
            withPortal
            allowSameDay={true}
            dateFormat={dateFormat}
            endDate={processDateByLocalZone(endDate)}
            // openToDate={processDateByLocalZone(startDate)}
            // endDate={endDate}
            locale="nb"
            maxDate={maxDate}
            minDate={minDate}
            monthsShown={2}
            placeholderText={placeholder}
            ref={getCalendar}
            selected={startDate ? processDateByLocalZone(startDate) : startDate}
            // selected={startDate}
            selectsEnd={selectsDate}
            showPopperArrow={false}
            startDate={processDateByLocalZone(startDate)}
            // startDate={startDate}
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            onChange={handleDateSelect}
            onClickOutside={handleCalendarClose}
          />
        </div>
      )
    );
  };

  let popoverMenuItems = [];
  const popoverMenuOptions = options.map(option => {
    if (optionSet[option]) {
      return { title: optionSet[option], onClick: () => handlePresetDate(option) };
    }
  });

  if (popoverMenuOptions) {
    popoverMenuItems.push(popoverMenuOptions);
  }

  popoverMenuItems.push([
    { title: t("datepicker:Custom date range"), onClick: () => openDatepickerPortal() }
  ]);

  if (startDate && allowClear) {
    popoverMenuItems.push([
      { title: t("datepicker:Clear date range"), onClick: () => handlePresetDate("clear") }
    ]);
  }

  return (
    <div className={style["datepicker-range"]}>
      <Popover
        arrow={false}
        content={renderDatePicker()}
        placement={"bottom-end"}
        trigger=""
        visible={calOpen}
      >
        <div
          className={classNames(style["datepicker-range__inputs"], {
            [style["datepicker-range__inputs--block"]]: block
          })}
        >
          <TextInput
            label={t("datepicker:From Date")}
            name="fromdate"
            readOnly={true}
            value={
              startDate && DateTime.fromJSDate(startDate, { zone: "local" }).toFormat(dateFormat)
            }
            onClick={openDatepickerPortal}
          />
          <TextInput
            label={t("datepicker:To Date")}
            name="todate"
            readOnly={true}
            value={endDate && DateTime.fromJSDate(endDate, { zone: "local" }).toFormat(dateFormat)}
            onClick={openDatepickerPortal}
          />
        </div>
      </Popover>
      <div className={style["datepicker-range__select-options"]}>
        <Popover
          arrow={false}
          content={<PopoverMenu items={popoverMenuItems} />}
          visible={popoverMenuOpen}
          onClickOutside={hidePopover}
        >
          <ActionButton
            icon={<ExpandIcon />}
            onClick={popoverMenuOpen ? hidePopover : showPopover}
          />
        </Popover>
      </div>
    </div>
  );
});

// const DatePickerRange = props => {
//
// };

DatePickerRange.propTypes = {
  placeholder: PropTypes.string,
  block: PropTypes.bool,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  options: PropTypes.array
};

export default DatePickerRange;
