import React, { useState, useRef, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import style from "../../assets/scss/components/user-menu.module.scss";
import { settingsAccountRoute } from "../../helpers/routes";
import AuthService from "../../services/AuthService";
import { selectUserAuthenticated, selectUserProfile } from "../../store/user/selector";
import UserExternalLink from "../helpers/UserExternalLink";

const authService = new AuthService();

function UserMenu() {
  const { t } = useTranslation();

  const authenticated = useSelector(selectUserAuthenticated);
  const user = useSelector(selectUserProfile);

  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  useEffect(() => {
    // Close dropdown if clicked outside of element
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setUserMenuIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleDropdownClick = event => {
    if (dropdownRef.current) {
      for (const child of dropdownRef.current.children) {
        if (child.nodeName === "A" && child.contains(event.target)) {
          setUserMenuIsOpen(false);
          return;
        }
      }
    }
  };

  const triggerClicked = () => {
    if (!authenticated) {
      return login();
    }

    setUserMenuIsOpen(!userMenuIsOpen);
  };

  const logout = () => {
    authService.logout();
  };

  const login = () => {
    authService.login();
  };

  const computedDisplayName = useMemo(() => {
    if (!user) {
      return null;
    }
    const { firstName, lastName, username } = user;
    if (!firstName && !lastName) {
      return username;
    }

    return `${firstName || ""} ${lastName || ""}`;
  }, [user]);

  return (
    <>
      <div className={style["user-menu"]} ref={wrapperRef}>
        <button className={style["user-menu__trigger"]} onClick={triggerClicked}>
          {authenticated ? (
            <UserIcon className={style["user-menu__trigger__user-icon"]} />
          ) : (
            <LoginIcon className={style["user-menu__trigger__login-icon"]} />
          )}

          <div className={style["user-menu__trigger__name"]}>
            {authenticated ? (
              <span data-hj-suppress>{computedDisplayName}</span>
            ) : (
              t("user:Sign in")
            )}
          </div>
        </button>

        {userMenuIsOpen && (
          <div
            className={style["user-menu__dropdown"]}
            ref={dropdownRef}
            onClick={handleDropdownClick}
          >
            {(user.ntbInfoUser ||
              user.mediaManagerUser ||
              user.mediaBankUser ||
              user.newsServiceUser) && (
              <>
                <div className={style["user-menu__dropdown__text"]}>
                  {t("user:Your applications")}
                </div>
                <UserExternalLink
                  displayLogo
                  label={"Info"}
                  url={"https://kommunikasjon.ntb.no/"}
                  visible={user.ntbInfoUser}
                />
                <UserExternalLink
                  displayLogo
                  label={"Media Manager"}
                  url={"https://client.sdl.no"}
                  visible={user.mediaManagerUser}
                />
                <UserExternalLink
                  displayLogo
                  label={"Mediebank"}
                  url={"https://mediebank.ntb.no/"}
                  visible={user.mediaBankUser}
                />
                <UserExternalLink
                  displayLogo
                  label={"Bilder og video"}
                  url={"https://bilder.ntb.no/"}
                  visible={user.imageportalUser}
                />
                <div className={style["user-menu__dropdown__divider"]} />
              </>
            )}
            <Link className={style["user-menu__dropdown__item"]} to={settingsAccountRoute}>
              {t("user:Settings")}
            </Link>
            {user.roles.includes("admin") && (
              <a
                className={style["user-menu__dropdown__item"]}
                href="https://bilder.ntb.no/useradmin.action"
              >
                {t("user:Admin")}
              </a>
            )}
            <UserExternalLink label={"Hjelp"} url={"https://hjelp.ntb.no/"} />
            {(user.roles.includes("superadmin") || user.roles.includes("admin")) && (
              <div className={style["user-menu__dropdown__divider"]} />
            )}
            <div className={style["user-menu__dropdown__item"]} onClick={logout}>
              {t("user:Sign out")}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserMenu;
