import React, { useMemo } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet, Link, matchPath, useLocation } from "react-router-dom";
import { useLastLocation } from "react-router-dom-last-location";

import { ReactComponent as ArrowIcon } from "../assets/icons/arrow-long.svg";
import style from "../assets/scss/pages/settings.module.scss";
import { indexRoute, settingsAccountRoute, notificationsAccountRoute } from "../helpers/routes";
import useDocumentTitle from "../helpers/useDocumentTitle";

const SettingsPage = ({}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { lastLocation } = useLastLocation();
  useDocumentTitle();

  const settingsPageList = useMemo(
    () => [
      {
        label: t("settings:account:label"),
        path: settingsAccountRoute
      },
      {
        label: t("settings:notifications:label"),
        path: notificationsAccountRoute
      }
    ],
    [t]
  );

  return (
    <div className={style["settings"]}>
      <nav className={style["settings__aside"]}>
        <ul className={style["settings__aside__nav"]}>
          <li
            className={classNames(
              style["settings__aside__nav__item"],
              style["settings__aside__nav__item--back"]
            )}
          >
            <Link
              to={{
                pathname: lastLocation ? lastLocation.pathname : indexRoute,
                search: lastLocation ? lastLocation.search : ""
              }}
            >
              <ArrowIcon />
              {t("settings:backLinkTitle")}
            </Link>
          </li>
          {settingsPageList.map(settingsPage => (
            <li
              className={classNames(style["settings__aside__nav__item"], {
                [style["settings__aside__nav__item--active"]]: matchPath(
                  location.pathname,
                  settingsPage.path
                )
              })}
              key={settingsPage.path}
            >
              <Link state={{ preventLastLocation: true }} to={settingsPage.path}>
                {settingsPage.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className={style["settings__content"]}>
        <Outlet />
      </div>
    </div>
  );
};

SettingsPage.propTypes = {};

export default SettingsPage;
