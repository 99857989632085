import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import style from "../assets/scss/pages/search.module.scss";
import CalendarExtraActionRow from "../components/calendar/CalendarExtraActionRow";
import ArticleUrlModifier from "../components/helpers/ArticleUrlModifier";
import CalendarSearchResults from "../components/search/CalendarSearchResults";
import SearchActionRow from "../components/search/SearchActionRow";
import SearchFilterMenu from "../components/search/SearchFilterMenu";
import useDocumentTitle from "../helpers/useDocumentTitle";
import { selectAccessibleCalendarArticleFilters } from "../store/articleFilter/selector";
import { getFilters } from "../store/articleFilter/slice";
import { setModalNewsItem } from "../store/calendar/slice";
import { selectDashboardFilterMenuOpen } from "../store/dashboard/selector";

function CalendarSearchPage() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const filterMenuOpen = useSelector(selectDashboardFilterMenuOpen);
  const filters = useSelector(selectAccessibleCalendarArticleFilters, isEqual);
  useDocumentTitle();

  const asideRef = useRef(null);

  const [searchFilters, setSearchFilters] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const queryObject = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const defaultFiltersQueryParam = useMemo(() => {
    return queryObject.defaultFilters
      ? Array.isArray(queryObject.defaultFilters)
        ? queryObject.defaultFilters
        : [queryObject.defaultFilters]
      : [];
  }, [queryObject.defaultFilters]);

  useEffect(() => {
    if (!filters.length) {
      dispatch(getFilters());
    } else {
      // Clone filters and deactivate them by default
      setSearchFilters(
        filters
          .filter(filter => (filter.custom?.properties?.originalId === "all" ? false : true))
          .map(filter => {
            filter = { ...filter, calendarActive: false };

            // If the filter was previously activated on the search page keep it as active
            if (searchFilters.length) {
              const searchFilterPrevState = searchFilters.find(f => f.id === filter.id);
              if (searchFilterPrevState) {
                filter.calendarActive = searchFilterPrevState.calendarActive;
              }
              if (defaultFiltersQueryParam.length) {
                const searchFilter = defaultFiltersQueryParam.find(
                  item => item === filter.title && filter.default
                );
                if (searchFilter) {
                  filter.calendarActive = true;
                }
              }
            }
            // Activate default filter if it is present in the URL query parameters
            else if (defaultFiltersQueryParam.length) {
              const searchFilter = defaultFiltersQueryParam.find(
                item => item === filter.title && filter.default
              );
              if (searchFilter) filter.calendarActive = true;
            }

            return filter;
          })
      );
      setLoadingFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, defaultFiltersQueryParam, dispatch]);

  // Deactivate all filters on search reset
  useEffect(() => {
    if (!defaultFiltersQueryParam.length && searchFilters.length) {
      // Are there any filters activated while the relevant search parameters are not there?
      const activeFilters = searchFilters.filter(filter => filter.calendarActive);

      if (activeFilters.length) {
        setSearchFilters(
          searchFilters.map(filter => {
            filter = { ...filter, calendarActive: false };
            return filter;
          })
        );
      }
    } else if (defaultFiltersQueryParam.length && searchFilters.length) {
      const filteredFilters = searchFilters.map(filter =>
        defaultFiltersQueryParam.includes(filter.title) && filter.default
          ? {
              ...filter,
              calendarActive: true
            }
          : { ...filter, calendarActive: false }
      );
      setSearchFilters(filteredFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFiltersQueryParam]);

  const handleFilterClick = id => {
    const filter = searchFilters.find(f => f.id === id);

    setSearchFilters(
      searchFilters.map(filter =>
        filter.id === id ? { ...filter, calendarActive: !filter.calendarActive } : { ...filter }
      )
    );

    // Add active default filters as URL query parameters
    if (!filter.default) return;

    let defaultFilters = [...defaultFiltersQueryParam];

    if (!filter.calendarActive) {
      defaultFilters.push(filter.title);
    } else {
      const index = defaultFilters.findIndex(item => item === filter.title);
      if (index !== -1) defaultFilters.splice(index, 1);
    }

    if (defaultFilters.length) {
      queryObject.defaultFilters = defaultFilters;
    } else {
      delete queryObject.defaultFilters;
    }

    navigate(
      {
        pathname: pathname,
        search: `?${qs.stringify(queryObject)}`
      },
      { replace: true }
    );
  };

  const onClearFilters = useCallback(() => {
    setSearchFilters(currentSearchFilters =>
      currentSearchFilters.map(filter => ({
        ...filter,
        calendarActive: false
      }))
    );

    delete queryObject.defaultFilters;
    navigate(
      {
        pathname: pathname,
        search: `?${qs.stringify(queryObject)}`
      },
      { replace: true }
    );
  }, [navigate, pathname, queryObject]);

  return (
    <>
      <ArticleUrlModifier
        service={"newscalendar"}
        onHardLoad={article => dispatch(setModalNewsItem(article))}
      />
      <SearchActionRow
        filters={searchFilters}
        loadingFilters={loadingFilters}
        onClearFilters={onClearFilters}
        onFilterClick={id => handleFilterClick(id)}
      />
      <CalendarExtraActionRow showViewMode={false} />
      <div className={style["container"]}>
        <aside
          className={classNames(style["aside"], { [style["aside--open"]]: filterMenuOpen })}
          ref={asideRef}
        >
          <div className={style["aside__container"]}>
            <SearchFilterMenu
              filters={searchFilters}
              filterVisibility={"calendar"}
              onFilterClick={id => handleFilterClick(id)}
            />
          </div>
        </aside>
        {!loadingFilters && <CalendarSearchResults filters={searchFilters} />}
      </div>
    </>
  );
}

export default CalendarSearchPage;
