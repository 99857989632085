import React from "react";

import { Reorder, useDragControls } from "framer-motion";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import style from "../assets/scss/components/news-dashboard.module.scss";
import {
  selectDashboardActiveNewsItem,
  selectDashboardActiveNewsItemColumnId,
  selectDashboardActiveNewsItemLatestVersionId
} from "../store/dashboard/selector";
import { markNewsItemAsActive } from "../store/dashboard/slice";

import ArticleView from "./article/ArticleView";
import NewsColumn from "./NewsColumn";

function NewsDashboardColumn({ column }) {
  const dispatch = useDispatch();
  const controls = useDragControls();
  const activeNewsItemColumnId = useSelector(selectDashboardActiveNewsItemColumnId, isEqual);
  const activeNewsItem = useSelector(selectDashboardActiveNewsItem, isEqual);
  const activeNewsItemLatestVersionId = useSelector(
    selectDashboardActiveNewsItemLatestVersionId,
    isEqual
  );

  return (
    <Reorder.Item
      as={"div"}
      className={style["reorder-item"]}
      dragControls={controls}
      dragListener={false}
      layout="position"
      value={column}
    >
      <NewsColumn draggableControls={controls} id={column.id} />
      {activeNewsItemColumnId === column.id && (
        <ArticleView
          article={activeNewsItem}
          latestVersionId={activeNewsItemLatestVersionId}
          onClose={() => dispatch(markNewsItemAsActive({ id: null, columnId: null }))}
        />
      )}
    </Reorder.Item>
  );
}

NewsDashboardColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default NewsDashboardColumn;
