import dateNb from "date-fns/locale/nb";
import dateNn from "date-fns/locale/nn";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Settings as luxonSettings } from "luxon";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { initReactI18next } from "react-i18next";

import nbNO from "./i18n/nb-NO.json";
import nnNO from "./i18n/nn-NO.json";

luxonSettings.defaultLocale = "nb-NO";
luxonSettings.defaultZone = "Europe/Oslo";

registerLocale("nb", dateNb);
registerLocale("nn", dateNn);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    fallbackLng: "nb-NO",

    supportedLngs: ["nb", "nb-NO", "nn-NO"],
    resources: {
      "nb-NO": nbNO,
      "nn-NO": nnNO
    },

    // // have a common namespace used around the full app
    // ns: ["translations"],
    defaultNS: "common",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },

    detection: {
      caches: ["localStorage", "cookie"],
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
        "userSetting"
      ]
    }
  });

i18n.on("languageChanged", function (language) {
  luxonSettings.defaultLocale = language;
  setDefaultLocale(language);
});

i18n.on("initialized", function (loaded) {
  //
});

setDefaultLocale(i18n.language);
export default i18n;
