import React from "react";

import PropTypes from "prop-types";

import style from "../../assets/scss/components/navigation/popover-menu.module.scss";

const PopoverMenu = ({ header, items }) => {
  return (
    <div className={style["popover-menu"]}>
      {header && <header className={style["popover-menu__header"]}>{header}</header>}

      {items.map((section, index) => (
        <React.Fragment key={index}>
          {section.map(item => (
            <a
              className={style["popover-menu__item"]}
              href={item.url}
              key={`popover-menu-${item.title}`}
              onClick={item.onClick}
            >
              {item.title}
            </a>
          ))}

          {index !== items.length - 1 && <div className={style["popover-menu__divider"]} />}
        </React.Fragment>
      ))}
    </div>
  );
};

PopoverMenu.propTypes = {
  header: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, url: PropTypes.string }))
  )
};

export default PopoverMenu;
