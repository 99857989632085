import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as SearchIcon } from "@ntbjs/react-components/icons/search.svg";
import { Checkbox, TextInput } from "@ntbjs/react-components/inputs";
import { isEqual } from "lodash";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import style from "../../assets/scss/pages/settings/notifications.module.scss";
import LoadingSpinner from "../../components/LoadingSpinner";
import NotificationSettingsTable from "../../components/ui-element/NotificationSettingsTable";
import { selectArticleFilters } from "../../store/articleFilter/selector";
import { toggleNotificationType } from "../../store/articleFilter/slice";
import { getFilters } from "../../store/articleFilter/thunk";
import {
  selectBreakingNewsNotifications,
  selectLoadingInitialNotifications,
  selectServiceMessageNotifications
} from "../../store/notification/selector";
import { toggleBreakingNews, toggleServiceMessages } from "../../store/notification/slice";

function NotificationsSettingsPage() {
  const { t } = useTranslation();
  const filters = useSelector(selectArticleFilters, isEqual);
  const loadingInitialNotifications = useSelector(selectLoadingInitialNotifications, isEqual);
  const breakingNewsNotifications = useSelector(selectBreakingNewsNotifications, isEqual);
  const serviceMessageNotifications = useSelector(selectServiceMessageNotifications, isEqual);

  const [searchString, setSearchString] = useState("");

  const dispatch = useDispatch();

  const breakingNewsOptions = useMemo(
    () => [
      { label: "HAST innenriks", key: "domestic" },
      {
        label: "HAST utenriks",
        key: "foreign"
      },
      { label: "HAST sport", key: "sport" }
    ],
    []
  );

  const serviceMessageOptions = useMemo(
    () => [
      {
        label: "Til red.",
        key: "editorial"
      },
      { label: "Varsling av utsendt video", key: "spvideo" }
    ],
    []
  );

  useEffect(() => {
    if (filters.length) return;
    dispatch(getFilters());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleBreakingNewsCallback = useCallback(
    (device, notificationType, enabled) => {
      dispatch(toggleBreakingNews({ device, notificationType, enabled }));

      ReactGA.event({
        category: "Notification settings",
        action: `${device === "web" ? "web push" : device} ${enabled ? "enable" : "disable"}`,
        label: `${t("settings:notifications:sectionHeader:breakingNewsNotifications")}: ${
          breakingNewsOptions.find(o => o.key === notificationType)?.label
        }`
      });
    },
    [dispatch, t, breakingNewsOptions]
  );

  const toggleServiceMessageCallback = useCallback(
    (device, notificationType, enabled) => {
      dispatch(toggleServiceMessages({ device, notificationType, enabled }));

      ReactGA.event({
        category: "Notification settings",
        action: `${device === "web" ? "web push" : device} ${enabled ? "enable" : "disable"}`,
        label: `${t("settings:notifications:sectionHeader:serviceMessages")}: ${
          serviceMessageOptions.find(o => o.key === notificationType)?.label
        }`
      });
    },
    [dispatch, t, serviceMessageOptions]
  );

  const toggleNotificationTypeForFilter = useCallback(
    (filter, notificationType, enabled) => {
      dispatch(toggleNotificationType({ filterId: filter.id, notificationType, enabled }));

      ReactGA.event({
        category: "Notification settings",
        action: `${notificationType === "web" ? "web push" : notificationType} ${
          enabled ? "enable" : "disable"
        }`,
        label: `${t("settings:notifications:sectionHeader:filterNotifications")}: ${
          filter.default ? t(filter.title) : filter.title
        }`
      });
    },
    [dispatch, t]
  );

  const filterNotificationsMatchingSearchString = notifications => {
    const lowerCasedSearchString = searchString.toLowerCase();

    return notifications.filter(
      s =>
        (s.label ? s.label : s.default ? t(s.title) : s.title)
          .toLowerCase()
          .indexOf(lowerCasedSearchString) !== -1
    );
  };

  if (loadingInitialNotifications) {
    return (
      <div className={style["loading"]}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <main className={style["main"]}>
        <section className={style["section"]}>
          <h2 className={style["section__title"]}>Administrere det du følger</h2>
          <p>
            Velg hva som er viktig for deg ved å administrere hvor og hva du ønsker varsler for.
          </p>
          <div className={style["filter-search"]}>
            <TextInput
              icon={<SearchIcon />}
              placeholder={"Filtrer"}
              onChange={e => setSearchString(e.target.value)}
            />
          </div>

          <NotificationSettingsTable
            label={t("settings:notifications:sectionHeader:breakingNewsNotifications")}
            notifications={[
              ...filterNotificationsMatchingSearchString(breakingNewsOptions).map(bn => ({
                label: bn.label,
                actions: (
                  <>
                    <Checkbox
                      checked={breakingNewsNotifications[bn.key]?.includes("email")}
                      label="E-post"
                      onChange={event =>
                        toggleBreakingNewsCallback("email", bn.key, event.target.checked)
                      }
                    />
                    <Checkbox
                      checked={breakingNewsNotifications[bn.key]?.includes("web")}
                      label="Nettleser"
                      onChange={event =>
                        toggleBreakingNewsCallback("web", bn.key, event.target.checked)
                      }
                    />
                  </>
                )
              }))
            ]}
          />
        </section>

        <section className={style["section"]}>
          <NotificationSettingsTable
            label={t("settings:notifications:sectionHeader:serviceMessages")}
            notifications={[
              ...filterNotificationsMatchingSearchString(serviceMessageOptions).map(bn => ({
                label: bn.label,
                actions: (
                  <>
                    <Checkbox
                      checked={serviceMessageNotifications[bn.key]?.includes("email")}
                      label="E-post"
                      onChange={event =>
                        toggleServiceMessageCallback("email", bn.key, event.target.checked)
                      }
                    />
                    <Checkbox
                      checked={serviceMessageNotifications[bn.key]?.includes("web")}
                      label="Nettleser"
                      onChange={event =>
                        toggleServiceMessageCallback("web", bn.key, event.target.checked)
                      }
                    />
                  </>
                )
              }))
            ]}
          />
        </section>

        <section className={style["section"]}>
          <NotificationSettingsTable
            label={t("settings:notifications:sectionHeader:filterNotifications")}
            notifications={[
              ...filterNotificationsMatchingSearchString(filters).map(filter => ({
                label: filter.default ? t(filter.title) : filter.title,
                actions: (
                  <>
                    <Checkbox
                      checked={filter.notification?.types.includes("email")}
                      label="E-post"
                      onChange={event =>
                        toggleNotificationTypeForFilter(filter, "email", event.target.checked)
                      }
                    />
                    <Checkbox
                      checked={filter.notification?.types.includes("web")}
                      label="Nettleser"
                      onChange={event =>
                        toggleNotificationTypeForFilter(filter, "web", event.target.checked)
                      }
                    />
                  </>
                )
              }))
            ]}
          />
        </section>

        {/*<section className={style["section"]}>*/}
        {/*  <h2 className={style["section__title"]}>*/}
        {/*    {t("settings:notifications:sectionHeader:filterNotifications")}*/}
        {/*  </h2>*/}
        {/*  {filters.map(filter => (*/}
        {/*    <div className={style["notifications"]} key={filter.id}>*/}
        {/*      <div className={style["notifications__name"]}>*/}
        {/*        {filter.default ? t(filter.title) : filter.title}*/}
        {/*      </div>*/}
        {/*      <div className={style["notifications__options"]}>*/}
        {/*        <Checkbox*/}
        {/*          checked={filter.notification?.types.includes("sms")}*/}
        {/*          onChange={enabled => toggleNotificationTypeForFilter(filter, "sms", enabled)}*/}
        {/*        >*/}
        {/*          SMS*/}
        {/*        </Checkbox>*/}
        {/*        <Checkbox*/}
        {/*          checked={filter.notification?.types.includes("email")}*/}
        {/*          onChange={enabled => toggleNotificationTypeForFilter(filter, "email", enabled)}*/}
        {/*        >*/}
        {/*          E-post*/}
        {/*        </Checkbox>*/}
        {/*        <Checkbox*/}
        {/*          checked={filter.notification?.types.includes("web")}*/}
        {/*          onChange={enabled => toggleNotificationTypeForFilter(filter, "web", enabled)}*/}
        {/*        >*/}
        {/*          Nettleser*/}
        {/*        </Checkbox>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</section>*/}
      </main>
    </div>
  );
}

export default NotificationsSettingsPage;
