import { createAsyncThunk } from "@reduxjs/toolkit";

import NotificationService from "../../services/NotificationService";

export const toggleBreakingNews = createAsyncThunk(
  "notification/toggleBreakingNews",
  async ({ device, notificationType, enabled }) => {
    await new NotificationService().enableBreakingNews(device, notificationType, enabled);
  }
);

export const toggleServiceMessages = createAsyncThunk(
  "notification/toggleServiceMessages",
  async ({ device, notificationType, enabled }) => {
    await new NotificationService().enableServiceMessages(device, notificationType, enabled);
  }
);
