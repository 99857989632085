import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import articleFilterReducer from "./articleFilter/slice";
import calendarReducer from "./calendar/slice";
import counterReducer from "./counter/slice";
import dashboardReducer from "./dashboard/slice";
import notificationReducer from "./notification/slice";
import userReducer from "./user/slice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    notification: notificationReducer,
    calendar: calendarReducer,
    articleFilter: articleFilterReducer
  },
  middleware: getDefaultMiddleware =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware()
});
