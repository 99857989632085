import React, { useRef, useCallback, useEffect } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import style from "../assets/scss/pages/index.module.scss";
import ActionRow from "../components/ActionRow";
import FilterMenu from "../components/FilterMenu";
import ArticleUrlModifier from "../components/helpers/ArticleUrlModifier";
import NewsDashboard from "../components/NewsDashboard";
import useDocumentTitle from "../helpers/useDocumentTitle";
import {
  selectAccessibleArticleFilters,
  selectArticleFilterIsMobile
} from "../store/articleFilter/selector";
import { enforceOneActiveFilterMobile } from "../store/articleFilter/slice";
import { selectDashboardFilterMenuOpen } from "../store/dashboard/selector";
import { setIsMobile, setModalNewsItem } from "../store/dashboard/slice";

function IndexPage() {
  const dispatch = useDispatch();
  const filterMenuOpen = useSelector(selectDashboardFilterMenuOpen, isEqual);
  const filters = useSelector(selectAccessibleArticleFilters, isEqual);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  useDocumentTitle();

  const asideRef = useRef(null);
  const filterLoadingStatus = !filters.length;

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [dispatch, isMobile]);

  useEffect(() => {
    if (isMobile && filters.length) {
      const allNewsFilter = filters.find(filter => filter.custom?.properties?.originalId === "all");
      if (allNewsFilter) dispatch(enforceOneActiveFilterMobile({ id: allNewsFilter.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLoadingStatus]);

  const onHardLoad = useCallback(
    article => {
      dispatch(setModalNewsItem(article));
    },
    [dispatch]
  );

  return (
    <>
      <ArticleUrlModifier service={"news"} onHardLoad={onHardLoad} />
      <ActionRow filterVisibility={"newsfeed"} />
      <div className={style["container"]}>
        <aside
          className={classNames(style["aside"], { [style["aside--open"]]: filterMenuOpen })}
          ref={asideRef}
        >
          <div className={style["aside__container"]}>
            <FilterMenu allowOneActiveFilterOnMobile={true} />
          </div>
        </aside>
        <NewsDashboard />
      </div>
    </>
  );
}

export default IndexPage;
