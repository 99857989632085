import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import style from "../../assets/scss/components/layout/settings/user-settings-header-meta.module.scss";
import { notificationsAccountRoute, settingsAccountRoute } from "../../helpers/routes";

const UserSettingsHeaderMeta = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const settingsPageList = useMemo(
    () => [
      {
        label: t("settings:account:label"),
        path: settingsAccountRoute
      },
      {
        label: t("settings:notifications:label"),
        path: notificationsAccountRoute
      }
    ],
    [t]
  );

  const matchingSettingsPage = useMemo(() => {
    return settingsPageList.find(settingsPage => matchPath(location.pathname, settingsPage.path));
  }, [settingsPageList, location.pathname]);

  return (
    <div className={style["header-meta"]}>
      <h3 className={style["header-meta__main"]}>Brukerinnstillinger</h3>
      <h3 className={style["header-meta__secondary"]}>{matchingSettingsPage.label}</h3>
    </div>
  );
};

export default UserSettingsHeaderMeta;
