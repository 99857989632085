import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "usehooks-ts";

import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-long.svg";
import style from "../../assets/scss/components/layout/main-menu.module.scss";

function MainMenu({ menuItems, toggleMenu, isOpen }) {
  const categories = useMemo(() => menuItems.categories, [menuItems.categories]);
  const submenu = useMemo(() => menuItems.submenu, [menuItems.submenu]);

  const [activeCategory, setActiveCategory] = useState(null);

  const services = useMemo(() => (activeCategory ? activeCategory.products : []), [activeCategory]);
  const [activeService, setActiveService] = useState(null);

  const isMobile = useMediaQuery("(max-width: 639px)");

  const preview = useMemo(() => {
    if (activeService || activeCategory) {
      const { title, description, url, image } = activeService || activeCategory;
      return {
        title,
        description,
        url,
        image
      };
    }

    return null;
  }, [activeService, activeCategory]);

  const mainMenuDomElement = useRef(null);

  const closeMenu = useCallback(() => {
    toggleMenu(false);
  }, [toggleMenu]);

  const clickedOutsideMenu = useCallback(
    event => {
      if (isOpen && !mainMenuDomElement.current.contains(event.target)) {
        closeMenu();
      }
    },
    [closeMenu, isOpen]
  );

  useEffect(() => {
    function addEventListeners() {
      window.addEventListener("scroll", closeMenu, false);
      window.addEventListener("click", clickedOutsideMenu);
    }

    function removeEventListeners() {
      window.removeEventListener("scroll", closeMenu, false);
      window.removeEventListener("click", clickedOutsideMenu);
    }

    // wait until the next frame before setting eventListeners
    setTimeout(() => {
      if (isOpen) {
        addEventListeners();
      } else {
        removeEventListeners();
      }
    }, 0);

    return () => removeEventListeners();
  }, [isOpen, closeMenu, clickedOutsideMenu]);

  return (
    <div
      className={classNames(style["main-menu"], {
        [style["main-menu--open"]]: isOpen
      })}
      ref={mainMenuDomElement}
    >
      <div
        className={classNames(style["main-menu__main"], {
          [style["main-menu__main--active-mobile"]]: isMobile && !activeCategory
        })}
      >
        <div className={style["main-menu__main__header"]}>Tjenesteområder</div>

        <ul className={style["main-menu__main__list"]}>
          {categories.map(category => (
            <li
              className={classNames(style["main-menu__main__list__item"], {
                [style["main-menu__main__list__item--active"]]: isEqual(category, activeCategory)
              })}
              key={category.title}
            >
              <a
                href={category.url}
                rel="noreferrer"
                target="_blank"
                onClick={() => closeMenu()}
                onMouseEnter={() => {
                  if (!isMobile) {
                    setActiveCategory(category);
                  }
                  setActiveService(null);
                }}
              >
                {category.title}
              </a>

              {isMobile && (
                <div
                  className={style["main-menu__main__list__item__navigation"]}
                  onClick={() => setActiveCategory(category)}
                >
                  <ArrowIcon />
                </div>
              )}
            </li>
          ))}
        </ul>

        <ul className={style["main-menu__main__sub-menu"]}>
          {submenu.map(item => (
            <li className={style["main-menu__main__sub-menu__item"]} key={item.title}>
              <a href={item.url} rel="noreferrer" target="_blank" onClick={() => closeMenu()}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={classNames(style["main-menu__secondary"], {
          [style["main-menu__secondary--active"]]: activeCategory,
          [style["main-menu__secondary--active-mobile"]]: isMobile && activeCategory
        })}
      >
        {isMobile && (
          <div
            className={style["main-menu__secondary__back-button"]}
            onClick={() => setActiveCategory(null)}
          >
            <div className={style["main-menu__secondary__back-button__icon"]}>
              <ArrowIcon />
            </div>
            Tilbake
          </div>
        )}

        <div className={style["main-menu__secondary__header"]}>Tjenester</div>

        <ul className={style["main-menu__secondary__list"]}>
          {services.map(service => (
            <li
              className={classNames(style["main-menu__secondary__list__item"], {
                [style["main-menu__secondary__list__item--active"]]: isEqual(service, activeService)
              })}
              key={service.title}
            >
              <a
                href={service.url}
                rel="noreferrer"
                target="_blank"
                onClick={() => closeMenu()}
                onMouseEnter={() => setActiveService(service)}
              >
                {service.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={classNames(style["main-menu__preview"], {
          [style["main-menu__preview--active"]]: activeCategory
        })}
      >
        {preview && (
          <>
            <div className={style["main-menu__preview__image-wrapper"]}>
              <LazyLoadImage
                alt={`${preview.image.title} - ${preview.image.credit}`}
                className={style["main-menu__preview__image-wrapper__image"]}
                effect="opacity"
                height={"100%"}
                key={preview.image.url}
                src={preview.image.url}
              />
            </div>
            <a
              className={style["main-menu__preview__button"]}
              href={preview.url}
              onClick={() => closeMenu()}
            >
              Gå til {preview.title}
            </a>

            <div className={style["main-menu__preview__description"]}>{preview.description}</div>
          </>
        )}
      </div>
    </div>
  );
}

MainMenu.propTypes = {
  menuItems: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default MainMenu;
