import qs from "qs";

import {
  indexRoute,
  calendarRoute,
  newsSearchRoute,
  calendarSearchRoute,
  settingsRoute,
  settingsAccountRoute,
  notificationsAccountRoute
} from "./routes";

export const truncateString = (str, maxLength) => {
  return str.length > maxLength ? str.substring(0, maxLength).concat("...") : str;
};

export const formatSearchResult = totalResult => {
  return (
    totalResult &&
    (totalResult > 9999
      ? totalResult.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
      : totalResult)
  );
};

export const getGAcategoryByPath = (ignoreActiveArticle = false) => {
  let category;

  if (!ignoreActiveArticle) {
    const queryObject = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryObject.hasOwnProperty("article") && queryObject.article) return "Article";
  }

  switch (window.location.pathname) {
    case indexRoute:
    case indexRoute.replace(/\/$/, ""):
      category = "News feed";
      break;
    case newsSearchRoute:
      category = "Search newsfeed";
      break;
    case calendarRoute:
      category = "News calendar";
      break;
    case calendarSearchRoute:
      category = "Search calendar";
      break;
    case settingsRoute:
    case settingsAccountRoute:
    case notificationsAccountRoute:
      category = "User settings";
      break;
    default:
      return "App";
  }

  return category;
};
