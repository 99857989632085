import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch } from "react-router-dom";

import style from "../../assets/scss/components/navigation/primary-menu.module.scss";
import {
  indexRoute,
  calendarRoute,
  newsSearchRoute,
  calendarSearchRoute
} from "../../helpers/routes";

function PrimaryMenu({ onClick }) {
  const { t } = useTranslation();

  const isNewsSearch = useMatch(newsSearchRoute);
  const isCalendarSearch = useMatch(calendarSearchRoute);

  return (
    <div className={style["primary-menu"]}>
      <ul>
        <li>
          <NavLink
            end
            className={({ isActive }) =>
              classNames(style["primary-menu__anchor"], {
                [style["primary-menu__anchor--is-active"]]: isActive || isNewsSearch
              })
            }
            to={indexRoute}
            onClick={onClick}
          >
            {t("common:News feed")}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              classNames(style["primary-menu__anchor"], {
                [style["primary-menu__anchor--is-active"]]: isActive || isCalendarSearch
              })
            }
            to={calendarRoute}
            onClick={onClick}
          >
            {t("common:News calendar")}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

PrimaryMenu.propTypes = {
  onClick: PropTypes.func
};

export default PrimaryMenu;
