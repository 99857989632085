import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import qs from "qs";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import Select from "react-select";

import style from "../../assets/scss/components/search/search-results.module.scss";
import { DEFAULT_DATE_FORMAT, ALL_NEWS_SERVICES } from "../../helpers/constants";
import { useHotkey, useHotkeyScope, hotkeys, hotkeyScopes } from "../../helpers/hotkeys";
import ArticleService from "../../services/ArticleService";
import { selectArticleFilterIsMobile } from "../../store/articleFilter/selector";
import {
  selectDashboardActiveNewsItemId,
  selectDashboardModalNewsItem,
  selectDashboardReadNewsItemIds
} from "../../store/dashboard/selector";
import { setModalNewsItem, markNewsItemAsActive } from "../../store/dashboard/slice";
import { selectUserArticleVersionPreference } from "../../store/user/selector";
import ArticleView from "../article/ArticleView";
import ArticleViewModal from "../article/ArticleViewModal";
import HorizontalInfiniteScroll from "../helpers/HorizontalInfiniteScroll";
import LoadingSpinner from "../LoadingSpinner";
import NewsItem from "../NewsItem";
import NewsItemSkeleton from "../NewsItemSkeleton";

import { formatSearchResult } from "./../../helpers/utils";

function NewsSearchResults({ filters }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const articleService = new ArticleService();

  const readNewsItems = useSelector(selectDashboardReadNewsItemIds, isEqual);
  const activeNewsItemId = useSelector(selectDashboardActiveNewsItemId, isEqual);
  const modalNewsItem = useSelector(selectDashboardModalNewsItem, isEqual);
  const preferredArticleVersion = useSelector(selectUserArticleVersionPreference);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);

  const [searchResults, setSearchResults] = useState({
    totalResults: 0,
    data: [],
    loading: false
  });

  const activeNewsItem =
    searchResults.data && searchResults.data.length
      ? searchResults.data.find(item => item.id === activeNewsItemId)
      : null;

  const [sorting, setSorting] = useState("ID_DESC");
  const columnScrollableRef = useRef(null);

  const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
  const searchString = queryObject.searchString ? queryObject.searchString : "";
  const startDate = queryObject.startDate ? queryObject.startDate : "";
  const endDate = queryObject.endDate ? queryObject.endDate : "";
  const [mediaTopics] = useQueryState("mediaTopics", []);
  const [regions] = useQueryState("regions", []);

  const filterSearchStringCollection = filters
    .filter(filter => filter.active && !filter.default)
    .map(filter => filter?.properties?.searchString)
    .filter(searchString => !!searchString);

  useHotkeyScope(hotkeyScopes.ARTICLE);

  useHotkey(hotkeys.NEXT_ARTICLE, () => {
    // if no article is selected, select the first article in the first column
    if (!activeNewsItemId) {
      if (!searchResults.data.length) return;
      return dispatch(markNewsItemAsActive({ id: searchResults.data[0].id }));
    }

    const nextActiveNewsItemIndex =
      searchResults.data.findIndex(article => article.id === activeNewsItemId) + 1;

    if (nextActiveNewsItemIndex > searchResults.data.length - 1) return;
    dispatch(markNewsItemAsActive({ id: searchResults.data[nextActiveNewsItemIndex].id }));
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_ARTICLE, () => {
    if (!activeNewsItemId) {
      if (!searchResults.data.length) return;
      return dispatch(markNewsItemAsActive({ id: searchResults.data[0].id }));
    }

    const previousActiveNewsItemIndex =
      searchResults.data.findIndex(article => article.id === activeNewsItemId) - 1;

    if (previousActiveNewsItemIndex < 0) return;
    dispatch(markNewsItemAsActive({ id: searchResults.data[previousActiveNewsItemIndex].id }));
    return false;
  });

  useEffect(() => {
    // Reset active news item when entering or leaving the page
    dispatch(markNewsItemAsActive({ id: null, columnId: null }));
    return () => dispatch(markNewsItemAsActive({ id: null, columnId: null }));
  }, [dispatch, pathname]);

  useEffect(() => {
    const abortController = new AbortController();

    setSearchResults({
      loading: true
    });

    articleService
      .where(builder => {
        builder.service(ALL_NEWS_SERVICES);
        builder.searchQuery(searchString, filters);
        builder.sortOrder(sorting);
        builder.latest(preferredArticleVersion === "latest");
        if (startDate) {
          builder.startDate(
            DateTime.fromFormat(startDate, DEFAULT_DATE_FORMAT).startOf("day").toUTC().toISO()
          );
        }
        if (endDate) {
          builder.endDate(
            DateTime.fromFormat(endDate, DEFAULT_DATE_FORMAT).endOf("day").toUTC().toISO()
          );
        }

        if (mediaTopics.length > 0) {
          builder.mediaTopics(mediaTopics);
        }

        if (regions.length > 0) {
          builder.regions(regions);
        }
      })
      .offset(0)
      .showNumResults(80)
      .search(abortController)
      .then(response => {
        if (response) {
          setSearchResults({
            totalResults: response.numResults,
            data: response.data,
            loading: false
          });
        }
      });

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sorting,
    searchString,
    startDate,
    endDate,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(mediaTopics),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(regions),
    filters,
    preferredArticleVersion
  ]);

  const loadMore = () => {
    articleService
      .where(builder => {
        builder.service(ALL_NEWS_SERVICES);
        builder.searchQuery(searchString, filters);
        builder.sortOrder(sorting);
        builder.latest(preferredArticleVersion === "latest");
        if (startDate) {
          builder.startDate(
            DateTime.fromFormat(startDate, DEFAULT_DATE_FORMAT).startOf("day").toUTC().toISO()
          );
        }

        if (endDate) {
          builder.endDate(
            DateTime.fromFormat(endDate, DEFAULT_DATE_FORMAT).endOf("day").toUTC().toISO()
          );
        }

        if (mediaTopics.length > 0) {
          builder.mediaTopics(mediaTopics);
        }

        if (regions.length > 0) {
          builder.regions(regions);
        }
      })
      .offset(searchResults.data.length)
      .showNumResults(40)
      .search()
      .then(response => {
        setSearchResults({
          totalResults: response.numResults,
          data: [...searchResults.data, ...response.data],
          loading: false
        });
      });
  };

  useEffect(() => {
    if (isMobile && columnScrollableRef.current) {
      const hasVerticalScrollbar =
        columnScrollableRef.current.scrollHeight > columnScrollableRef.current.clientHeight;
      // Added the check because on iOS devices the scrollbar isn't visible
      const verticalScrollbarIsVisible =
        columnScrollableRef.current.offsetWidth > columnScrollableRef.current.clientWidth;

      columnScrollableRef.current.classList.toggle(
        style["column__scrollable--has-scrollbar"],
        hasVerticalScrollbar && verticalScrollbarIsVisible
      );
    }
  });

  const sortOptions = useMemo(
    () => [
      { value: "ID_DESC", label: t("articleFilter:published") },
      { value: "PRIORITY_ASC|ID_DESC", label: t("articleFilter:Priority") }
    ],
    [t]
  );

  const handleSortChange = sort => {
    if (sorting !== sort) {
      setSorting(sort);
    }
  };

  const getSelectedSortOption = useCallback(() => {
    return sortOptions.find(option => option.value === sorting) || sortOptions[0];
  }, [sorting]); // eslint-disable-line react-hooks/exhaustive-deps

  let selectedSortOption = getSelectedSortOption();

  const setActiveNewsItem = useCallback(
    (id, columnId) => {
      // Close newsItem if the open one was clicked
      if (activeNewsItemId === id) {
        return dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      }

      dispatch(markNewsItemAsActive({ id, columnId }));
    },
    [activeNewsItemId, dispatch]
  );

  const newsItemList = ({ showLabelTop = false }) => {
    return searchResults.data.map(item => (
      <NewsItem
        active={activeNewsItemId === item.id}
        columnId={"search-results"}
        item={item}
        key={`news-item-search-result-${item.id}`}
        read={readNewsItems.includes(item.id)}
        showDate={true}
        showLabelTop={showLabelTop}
        updated={false}
        withInlineArticleView={false}
        onClick={setActiveNewsItem}
      />
    ));
  };

  const loader = () => {
    return (
      <>
        <NewsItemSkeleton />
        <NewsItemSkeleton />
        <NewsItemSkeleton />
      </>
    );
  };

  return (
    <div className={`${style["search-results"]} ${style["news"]}`}>
      {modalNewsItem.id && (
        <ArticleViewModal article={modalNewsItem} onClose={() => dispatch(setModalNewsItem({}))} />
      )}
      <div
        className={classNames(style["column"], {
          [style["column--single"]]: !isMobile
        })}
      >
        <div className={style["column__headline"]}>
          <div className={style["column__headline_title"]}>
            {t("search:searchResults")}: {formatSearchResult(searchResults.totalResults)}
          </div>
          <Select
            className={style["column__headline__select"]}
            classNamePrefix={"column__headline__select"}
            isDisabled={searchResults.loading}
            isSearchable={false}
            options={sortOptions}
            value={selectedSortOption}
            onChange={e => handleSortChange(e.value)}
          />
        </div>
        {searchResults.loading ? (
          <div className={style["loading"]}>
            <LoadingSpinner />
          </div>
        ) : searchResults.data.length ? (
          <>
            {isMobile ? (
              <div
                className={style["column__scrollable"]}
                id={"column-search-results"}
                ref={columnScrollableRef}
              >
                <InfiniteScroll
                  dataLength={searchResults.data.length}
                  hasMore={searchResults.totalResults > searchResults.data.length}
                  loader={loader()}
                  next={() => loadMore()}
                  scrollableTarget={"column-search-results"}
                >
                  {newsItemList({ showLabelTop: false })}
                </InfiniteScroll>
              </div>
            ) : (
              <div
                className={style["column__scrollable"]}
                id={"column-search-results"}
                ref={columnScrollableRef}
              >
                <InfiniteScroll
                  dataLength={searchResults.data.length}
                  hasMore={searchResults.totalResults > searchResults.data.length}
                  loader={loader()}
                  next={() => loadMore()}
                  scrollableTarget={"column-search-results"}
                >
                  {newsItemList({ showLabelTop: true })}
                </InfiniteScroll>
              </div>
            )}
          </>
        ) : (
          <div className={style["column__empty"]}>{t("search:emptySearchResults")}</div>
        )}
      </div>
      {activeNewsItem && (
        <ArticleView
          article={activeNewsItem}
          modal={true}
          searchString={filterSearchStringCollection.join(" OR ")}
          onClose={() => dispatch(markNewsItemAsActive({ id: null, columnId: null }))}
        />
      )}
    </div>
  );
}

NewsSearchResults.propTypes = {
  filters: PropTypes.array
};

export default NewsSearchResults;
