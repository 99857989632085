import React, { useEffect, useMemo, useRef, useState } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import classNames from "classnames";
import { isEmpty, isEqual, isFunction, map } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { useSelector } from "react-redux";

import { ReactComponent as LastMultiDayIndicator } from "../../assets/graphics/last-multi-day-indicator.svg";
import { ReactComponent as MultiDayIndicator } from "../../assets/graphics/multi-day-indicator.svg";
import style from "../../assets/scss/components/news-item-detailed.module.scss";
import { NTB_TEMA_NEWS_SERVICES } from "../../helpers/constants";
import ArticleService from "../../services/ArticleService";
import { selectArticleFilters } from "../../store/articleFilter/selector";
import { selectDashboardActiveNewsItemColumnId } from "../../store/dashboard/selector";
import { decodeHtml, extractCalendarInfo } from "../article/ArticleView";
import Pulser from "../Pulser";

import NewsItemDetailedSkeleton from "./NewsItemDetailedSkeleton";

const articleService = new ArticleService();

function NewsItemDetailed({
  item,
  columnId,
  columnDate,
  active,
  read,
  updated,
  onClick,
  showLabel = true
}) {
  const { t } = useTranslation();

  const activeNewsItemColumnId = useSelector(selectDashboardActiveNewsItemColumnId, isEqual);
  const newsItemRef = useRef(null);

  const [itemContent, setItemContent] = useState(null);

  const articleFilters = useSelector(selectArticleFilters, isEqual);
  const activeArticleFilters = useMemo(
    () => articleFilters.filter(filter => filter.active),
    [articleFilters]
  );

  // Disable the label of only one filter is selected and is not "All news"
  if (
    activeArticleFilters.length === 1 &&
    activeArticleFilters[0].custom?.properties?.originalId !== "all"
  ) {
    showLabel = false;
  }

  const calendarInfo = useMemo(() => extractCalendarInfo(itemContent), [itemContent]);

  const statuses = useMemo(
    () => [
      {
        id: "updated",
        label: t("newsItem:statuses:updated"),
        color: "green"
      },
      {
        id: "published",
        color: "green"
      },
      {
        id: "beingUpdated",
        color: "green"
      },
      {
        id: "inProgress",
        color: "yellow"
      }
    ],
    [t]
  );

  const priority = useMemo(() => {
    return item.priority;
  }, [item.priority]);

  const previousPriority = useMemo(() => {
    if (Number.isInteger(item.priority) && item.priority <= 3) {
      return item.priority;
    }

    let value = 5;

    if (!isEmpty(item?.previousPriorities)) {
      value = Math.min(...item.previousPriorities.filter(n => Number.isInteger(n)));
    }

    return value;
  }, [item.previousPriorities, item.priority]);

  const itemStatus = useMemo(() => {
    if (updated) {
      const index = statuses.findIndex(status => status.id === "updated");
      return statuses[index];
    }

    if (item.coverages) {
      const inProgressCoverage = item.coverages.find(coverage => coverage.status === "inProgress");
      const beingUpdatedCoverage = item.coverages.find(
        coverage => coverage.status === "beingUpdated"
      );
      const publishedCoverage = item.coverages.find(coverage => coverage.status === "published");

      if (publishedCoverage) {
        return statuses.find(status => status.id === "published");
      }

      if (beingUpdatedCoverage) {
        return statuses.find(status => status.id === "beingUpdated");
      }

      if (inProgressCoverage) {
        return statuses.find(status => status.id === "inProgress");
      }
    }

    return null;
  }, [item.coverages, statuses, updated]);

  const itemLabel = () => {
    let label = item.category;
    let cssClass = "";

    if (NTB_TEMA_NEWS_SERVICES.includes(item.service)) {
      label = "NTB Tema";
    }

    if (label === "PRM-NTB") {
      label = t("articleCategories:PRM");
    }

    if (item.service === "mediation") cssClass = "tranquil";

    return { text: label, cssClass };
  };

  const getItem = item => {
    setItemContent(null);
    articleService.getOne(item.service, item.id).then(itemDetails => {
      setItemContent(itemDetails);
    });
  };

  const renderCoverageIcon = (item, type) => {
    if (
      !item.coverages?.some(coverage => coverage.type === type) &&
      !(type === "picture" && item.pictures)
    ) {
      return null;
    }

    const isCancelled = item.coverages?.some(c => c.type === type && c.status === "cancelled");

    return (
      <Tooltip
        content={
          isCancelled ? (
            <>
              {type === "text" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:textCancelled")
                  }}
                />
              )}
              {type === "picture" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:picturesCancelled")
                  }}
                />
              )}
              {type === "video" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:videoCancelled")
                  }}
                />
              )}
              {type === "live_video" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:liveVideoCancelled")
                  }}
                />
              )}
              {type === "infographics" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:infographicsCancelled")
                  }}
                />
              )}
              {type === "undecided_under_consideration" && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("newsItem:calendarCoverages:underConsiderationCancelled")
                  }}
                />
              )}
            </>
          ) : (
            <>
              {type === "text" && t("newsItem:calendarCoverages:text")}
              {type === "picture" && t("newsItem:calendarCoverages:pictures")}
              {type === "video" && t("newsItem:calendarCoverages:video")}
              {type === "live_video" && t("newsItem:calendarCoverages:liveVideo")}
              {type === "infographics" && t("newsItem:calendarCoverages:infographics")}
              {type === "undecided_under_consideration" &&
                t("newsItem:calendarCoverages:underConsideration")}
            </>
          )
        }
      >
        <div
          className={classNames(style["item__info__icons__container"], {
            [style["item__info__icons__container--cancelled"]]: isCancelled
          })}
        >
          {type === "text" && <MaterialSymbol icon="article" size={18} />}
          {type === "picture" && <MaterialSymbol icon="photo" size={18} />}
          {type === "video" && <MaterialSymbol icon="play_circle" size={18} />}
          {type === "live_video" && <MaterialSymbol icon="videocam" size={18} />}
          {type === "infographics" && <MaterialSymbol icon="analytics" size={18} />}
          {type === "undecided_under_consideration" && <MaterialSymbol icon="help" size={18} />}

          {isCancelled && <div className={style["item__info__icons__container__cancelled-line"]} />}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (!itemContent) getItem(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (active && columnId === activeNewsItemColumnId) {
      newsItemRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [active, columnId, activeNewsItemColumnId]);

  const handleNewsItemClick = id => {
    if (isFunction(onClick)) {
      onClick(id, columnId);
    }
  };

  const isMultiDayItem = useMemo(() => {
    return (
      item.endTime &&
      !DateTime.fromMillis(item.time).hasSame(DateTime.fromMillis(item.endTime), "day")
    );
  }, [item.endTime, item.time]);

  const isLastDayOfMultiDayItem = useMemo(() => {
    if (!isMultiDayItem) return false;

    return (
      columnDate &&
      DateTime.fromFormat(columnDate, "dd.MM.yyyy").hasSame(
        DateTime.fromMillis(item.endTime),
        "day"
      )
    );
  }, [columnDate, isMultiDayItem, item.endTime]);

  const onKeyDown = (event, item) => {
    const enterOrSpace =
      event.key === "Enter" ||
      event.key === " " ||
      event.key === "Spacebar" ||
      event.which === 13 ||
      event.which === 32;
    if (enterOrSpace) {
      event.preventDefault();
      handleNewsItemClick(item.id);
    }
  };

  return (
    <div
      className={classNames(
        style["item"],
        {
          [style["item--read"]]: read,
          [style["item--active"]]: active,
          [style["item--" + itemStatus?.id]]: itemStatus?.id
        },
        priority <= previousPriority
          ? { [style["item--priority-high"]]: priority <= 3 }
          : {
              [style["item--priority-medium"]]: previousPriority !== null && previousPriority <= 3
            }
      )}
      ref={newsItemRef}
      role="button"
      tabIndex={0}
      onClick={() => handleNewsItemClick(item.id)}
      onKeyDown={event => onKeyDown(event, item)}
    >
      {isMultiDayItem &&
        (isLastDayOfMultiDayItem ? (
          <LastMultiDayIndicator className={style["item__multi-day-indicator"]} />
        ) : (
          <MultiDayIndicator className={style["item__multi-day-indicator"]} />
        ))}
      <div className={style["item__info"]}>
        {itemStatus && (
          <div
            className={classNames(
              style["item__info__status"],
              style["item__info__status--" + itemStatus.id]
            )}
          >
            <Pulser variant={itemStatus.color} />
            {itemStatus.label && (
              <div className={style["item__info__status__text"]}>{itemStatus.label}</div>
            )}
          </div>
        )}
        {!itemStatus && showLabel && itemLabel().text && (
          <div
            className={classNames(style["item__info__label"], {
              [style["item__info__label--" + itemLabel()?.cssClass]]: itemLabel()?.cssClass
            })}
          >
            {itemLabel().text}
          </div>
        )}
        <div className={style["item__info__icons"]}>
          {renderCoverageIcon(item, "text")}
          {renderCoverageIcon(item, "picture")}
          {renderCoverageIcon(item, "video")}
          {renderCoverageIcon(item, "live_video")}
          {renderCoverageIcon(item, "infographics")}
          {renderCoverageIcon(item, "undecided_under_consideration")}

          {/*<Tooltip content={t("newsItem:calendarCoverages:text")}>
            {item.coverages?.some(coverage => coverage.type === "text") && (
              <MaterialSymbol icon="article" size={18} />
            )}
          </Tooltip>
          {item.pictures ||
            (item.coverages?.some(coverage => coverage.type === "picture") && (
              <Tooltip content={t("newsItem:calendarCoverages:pictures")}>
                <MaterialSymbol icon="photo" size={18} />
              </Tooltip>
            ))}
          {item.coverages?.some(coverage => coverage.type === "video") && (
            <Tooltip content={t("newsItem:calendarCoverages:video")}>
              <MaterialSymbol icon="videocam" size={18} />
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "live_video") && (
            <Tooltip content={t("newsItem:calendarCoverages:liveVideo")}>
              <MaterialSymbol icon="play_circle" size={18} />
            </Tooltip>
          )}

          {item.coverages?.some(coverage => coverage.type === "infographics") && (
            <Tooltip content={t("newsItem:calendarCoverages:infographics")}>
              <MaterialSymbol icon="analytics" size={18} />
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "undecided_under_consideration") && (
            <Tooltip content={t("newsItem:calendarCoverages:underConsideration")}>
              <MaterialSymbol icon="help" size={18} />
            </Tooltip>
          )}*/}
        </div>
      </div>
      <div
        className={classNames(style["item__title"], {
          [style["item__title--multi-day-item"]]: isMultiDayItem
        })}
      >
        {decodeHtml(item.title)}
      </div>

      {itemContent ? (
        <>
          <div
            className={classNames(style["item__content"], {
              [style["item__content--multi-day-item"]]: isMultiDayItem
            })}
            dangerouslySetInnerHTML={{ __html: itemContent.contentMarkup }}
          />
          {!isEmpty(calendarInfo) && (
            <table className={style["item__calendar-info"]}>
              <tbody>
                {map(calendarInfo, (value, key) => (
                  <tr key={key}>
                    <td>{key}:</td>
                    <td dangerouslySetInnerHTML={{ __html: value }} />
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      ) : (
        <NewsItemDetailedSkeleton type={"content"} />
      )}
    </div>
  );
}

NewsItemDetailed.propTypes = {
  item: PropTypes.object.isRequired,
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  columnDate: PropTypes.string,
  active: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showDate: PropTypes.bool,
  showLabel: PropTypes.bool
};

export default React.memo(NewsItemDetailed, isEqual);
