import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

import style from "../../assets/scss/components/form/text-input.module.scss";

function TextInput({
  label,
  name,
  readOnly,
  wrapperClassName,
  type = "text",
  value = "",
  onChange,
  onClick
}) {
  const uniqueId = nanoid();

  const handleChange = value => {
    if (onChange) onChange(value);
  };

  return (
    <div className={classNames(style["text-input"], wrapperClassName)}>
      <input
        className={classNames(style["text-input__input"], {
          [style["text-input__input--empty"]]: !value
        })}
        id={`text-input-${uniqueId}`}
        name={name}
        readOnly={readOnly}
        type={type}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onClick={onClick}
      />
      <label className={style["text-input__label"]} htmlFor={`text-input-${uniqueId}`}>
        {label}
      </label>
    </div>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

export default TextInput;
