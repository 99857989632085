function env() {
  switch (window.location.hostname) {
    case "localhost": {
      return "development";
    }

    case "nyheter.test.ntb.dev": {
      return "test";
    }

    case "nyheter.stage.ntb.dev": {
      return "staging";
    }

    case "ntbnyheter.paulsenit.no": {
      return "docker-staging";
    }

    case "nyheter.ntb.no": {
      return "production";
    }

    case "nyheter.test.ntb.dev": {
      return "test";
    }

    default: {
      return "development";
    }
  }
}

export default env();
