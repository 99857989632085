import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import style from "../../assets/scss/components/newsitem/news-item-detailed-skeleton.module.scss";
import SkeletonBox from "../skeleton/SkeletonBox";

function NewsItemDetailedSkeleton({ type }) {
  return (
    <div
      className={classNames(style["news-item-detailed-skeleton"], {
        [style["news-item-detailed-skeleton--" + type]]: type
      })}
    >
      {type !== "content" && (
        <>
          <SkeletonBox style={{ width: "20%", marginBottom: "5px" }} />
          <SkeletonBox style={{ width: "70%", marginBottom: "20px" }} />{" "}
        </>
      )}

      <SkeletonBox style={{ width: "100%", marginBottom: "5px" }} />
      <SkeletonBox style={{ width: "80%", marginBottom: "5px" }} />
      <SkeletonBox style={{ width: "30%", marginBottom: "20px" }} />

      <SkeletonBox style={{ width: "90%", marginBottom: "5px" }} />
      <SkeletonBox style={{ width: "50%", marginBottom: "15px" }} />
    </div>
  );
}

NewsItemDetailedSkeleton.propTypes = {
  type: PropTypes.string
};

export default NewsItemDetailedSkeleton;
