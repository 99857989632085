import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { isEqual, omit } from "lodash";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";

import style from "../assets/scss/components/news-column.module.scss";
import { FILTER_NAME_MAX_LENGTH } from "../helpers/constants";
import { truncateString } from "../helpers/utils";
import { selectArticleFilterById } from "../store/articleFilter/selector";
import {
  selectDashboardActiveNewsItemId,
  selectDashboardColumnById,
  selectDashboardReadNewsItemIds,
  selectDashboardUpdatedNewsItemIds,
  selectDashboardActiveNewsItemLatestVersionId
} from "../store/dashboard/selector";
import {
  changeNewsColumnSort,
  articleSearch,
  loadMoreArticlesByFilterId,
  fetchNewArticlesByFilterId,
  markNewsItemAsActive
} from "../store/dashboard/slice";

import LoadingSpinner from "./LoadingSpinner";
import NewsItem from "./NewsItem";
import NewsItemSkeleton from "./NewsItemSkeleton";

function NewsColumn({ id, draggableControls }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);

  const column = useSelector(selectDashboardColumnById(id), isEqual);
  const filter = useSelector(selectArticleFilterById(column.filterId), (oldState, newState) => {
    // prevents re-rendering of the component just because of the filters order being changed
    return isEqual(
      omit(oldState, ["custom.properties.order"]),
      omit(newState, ["custom.properties.order"])
    );
  });
  const readNewsItems = useSelector(selectDashboardReadNewsItemIds, isEqual);
  const updatedNewsItems = useSelector(selectDashboardUpdatedNewsItemIds, isEqual);
  const activeNewsItemId = useSelector(selectDashboardActiveNewsItemId, isEqual);
  const activeNewsItemLatestVersionId = useSelector(
    selectDashboardActiveNewsItemLatestVersionId,
    isEqual
  );

  const columnScrollableRef = useRef(null);

  const articlesAreLoading = useMemo(() => {
    return !(column && !column.loading);
  }, [column]);

  useEffect(() => {
    dispatch(articleSearch(column.id));
  }, [dispatch, column.id, column.sort, filter]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchNewArticlesByFilterId(column.id));
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, column.id]);

  useEffect(() => {
    if (columnScrollableRef.current) {
      const hasVerticalScrollbar =
        columnScrollableRef.current.scrollHeight > columnScrollableRef.current.clientHeight;
      // Added the check because on iOS devices the scrollbar isn't visible
      const verticalScrollbarIsVisible =
        columnScrollableRef.current.offsetWidth > columnScrollableRef.current.clientWidth;

      columnScrollableRef.current.classList.toggle(
        style["column__scrollable--has-scrollbar"],
        hasVerticalScrollbar && verticalScrollbarIsVisible
      );
    }
  });

  const sortOptions = useMemo(
    () => [
      { value: "ID_DESC", label: t("articleFilter:published") },
      { value: "PRIORITY_ASC|ID_DESC", label: t("articleFilter:Priority") }
    ],
    [t]
  );

  const handleSortChange = sort => {
    if (column.sort !== sort) {
      dispatch(changeNewsColumnSort({ id, sort }));

      ReactGA.event({
        category: "List",
        action: "Change sorting order",
        label: sort
      });
    }
  };

  const getSelectedSortOption = useCallback(() => {
    return sortOptions.find(option => option.value === column.sort) || sortOptions[0];
  }, [column.sort]); // eslint-disable-line react-hooks/exhaustive-deps

  let selectedSortOption = getSelectedSortOption();

  const setActiveNewsItem = useCallback(
    (id, columnId) => {
      // Close newsItem if the open one was clicked
      if (activeNewsItemId === id && column.id === columnId) {
        return dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      }

      dispatch(markNewsItemAsActive({ id, columnId }));
    },
    [activeNewsItemId, column.id, dispatch]
  );

  const newsItemList = () => {
    return column.articles.map(item => (
      <NewsItem
        active={activeNewsItemId === item.id}
        columnId={column.id}
        item={item}
        key={`news-item-${column.id}-${id}-${item.id}`}
        latestVersionId={activeNewsItemLatestVersionId}
        read={readNewsItems.includes(item.id)}
        showLabel={!filter.default || filter.custom?.properties?.originalId === "all"}
        updated={updatedNewsItems.includes(item.id)}
        onClick={setActiveNewsItem}
      />
    ));
  };

  const loader = () => {
    return (
      <>
        <NewsItemSkeleton />
        <NewsItemSkeleton />
        <NewsItemSkeleton />
      </>
    );
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div
            className={classNames("column__headline__select__dropdown-indicator__arrow", {
              "column__headline__select__dropdown-indicator__arrow--rotated":
              props.selectProps.menuIsOpen // eslint-disable-line
            })}
          />
        </components.DropdownIndicator>
      )
    );
  };

  // The filter is already removed from the store,
  // the column will be removed when the dashboard is re-rendered
  if (!filter) return null;

  return (
    <div
      className={classNames(style["column"], {
        [style["column--" + filter.custom?.properties?.cssClass]]:
          filter.custom?.properties?.cssClass
      })}
    >
      <div
        className={classNames(style["column__headline"], style["column__headline--draggable"], {
          [style["column__headline--dragging"]]: dragging
        })}
        onPointerDown={e => {
          draggableControls.start(e);
          e.preventDefault(); // Prevents user-select of the text while dragging
          setDragging(true);
        }}
        onPointerUp={() => {
          setDragging(false);
        }}
      >
        <div className={style["column__headline__inner"]}>
          <div className={style["column__headline__title"]}>
            {filter.default
              ? t(filter.title)
              : truncateString(filter.title, FILTER_NAME_MAX_LENGTH)}
          </div>

          <Select
            className={style["column__headline__select"]}
            classNamePrefix={"column__headline__select"}
            components={{ DropdownIndicator }}
            isDisabled={articlesAreLoading}
            isSearchable={false}
            options={sortOptions}
            value={selectedSortOption}
            onChange={e => handleSortChange(e.value)}
          />
        </div>
      </div>
      {articlesAreLoading ? (
        <div className={style["loading"]}>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          className={style["column__scrollable"]}
          draggable={false}
          id={`column-${column.id}`}
          ref={columnScrollableRef}
        >
          {!articlesAreLoading && column.articles.length === 0 && (
            <div className={style["column__scrollable__no-results"]}>
              {t("feed:noResultsLabel")}
            </div>
          )}
          <InfiniteScroll
            dataLength={column.articles.length}
            hasMore={column.totalResults > column.articles.length}
            loader={loader()}
            next={() => dispatch(loadMoreArticlesByFilterId(column.id))}
            scrollableTarget={`column-${column.id}`}
          >
            {newsItemList()}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

NewsColumn.propTypes = {
  id: PropTypes.number.isRequired,
  draggableControls: PropTypes.any.isRequired
};

export default NewsColumn;
