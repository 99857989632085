import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import { useEffectOnce } from "usehooks-ts";

import style from "../../assets/scss/components/calendar.module.scss";
import { DEFAULT_DATE_FORMAT } from "../../helpers/constants";
import { calendarSearchRoute } from "../../helpers/routes";
import { selectCalendarEndDate, selectCalendarStartDate } from "../../store/calendar/selector";
import { changeDateRange } from "../../store/calendar/slice";
import DatePickerRange from "../form/DatePickerRange";
import CalendarContentFilters from "../ui-element/CalendarContentFilters";
import CalendarViewMode from "../ui-element/CalendarViewMode";

import RequestNewEvent from "./RequestNewEvent";

const CalendarExtraActionRow = ({ datePickerSelectable, showViewMode }) => {
  const [startDateQuery, setStartDateQuery] = useQueryState("startDate", "");
  const [endDateQuery, setEndDateQuery] = useQueryState("endDate", "");
  const dispatch = useDispatch();

  const startDate = useSelector(selectCalendarStartDate);
  const endDate = useSelector(selectCalendarEndDate);

  const isCalendarSearchRoute = useMatch(calendarSearchRoute);

  useEffectOnce(() => {
    if (isCalendarSearchRoute && !startDateQuery) {
      setStartDateQuery(DateTime.now().toFormat("dd.MM.yyyy"));
    }
  });

  return (
    <div className={style["calendar-container__action-row"]}>
      {datePickerSelectable ? (
        <DatePickerRange
          allowClear={isCalendarSearchRoute}
          endDate={isCalendarSearchRoute ? endDateQuery : endDate}
          maxDays={90}
          options={[
            "today",
            "tomorrow",
            "next7days",
            "next30days",
            "next90days",
            ...(isCalendarSearchRoute ? ["allUpcoming"] : [])
          ]}
          startDate={isCalendarSearchRoute ? startDateQuery : startDate}
          onChange={(startDate, endDate) => {
            if (isCalendarSearchRoute) {
              setStartDateQuery(startDate);
              setEndDateQuery(endDate);
              return;
            }
            dispatch(changeDateRange({ startDate, endDate }));
          }}
        />
      ) : (
        <div className={style["calendar-container__action-row__date"]}>
          <span>{DateTime.fromFormat(startDate, DEFAULT_DATE_FORMAT).toFormat("DDDD")}</span>
        </div>
      )}
      <div className={style["calendar-container__action-row__filters"]}>
        <CalendarContentFilters />
      </div>
      <div className={style["calendar-container__action-row__action-links"]}>
        <RequestNewEvent />
      </div>
      {showViewMode && (
        <div className={style["calendar-container__action-row__view-mode"]}>
          <CalendarViewMode />
        </div>
      )}
    </div>
  );
};

CalendarExtraActionRow.defaultProps = {
  datePickerSelectable: true,
  showViewMode: true
};

CalendarExtraActionRow.propTypes = {
  datePickerSelectable: PropTypes.bool,
  showViewMode: PropTypes.bool
};

export default CalendarExtraActionRow;
