import React, { Suspense } from "react";

import "react-material-symbols/dist/outlined.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-dom-last-location";
// import ScrollMemory from "react-router-scroll-memory";
import { toast, ToastContainer } from "react-toastify";

import GoogleAnalytics from "./components/system/GoogleAnalytics";
import Notifications from "./components/system/Notifications";
import RedirectFromTrailingSlash from "./components/system/RedirectFromTrailingSlash";
import Theme from "./components/system/Theme";
import env from "./helpers/env";
import "./helpers/luxonDefaults";
import {
  indexRoute,
  calendarRoute,
  counterRoute,
  settingsRoute,
  newsSearchRoute,
  calendarSearchRoute,
  settingsAccountRoute,
  notificationsAccountRoute
} from "./helpers/routes";
import "react-toastify/dist/ReactToastify.css";
import DefaultLayout from "./layouts/default";
import "./assets/scss/global/base.scss";
import "./assets/scss/global/scrollbar.scss";
import "./assets/scss/global/react-toastify.scss";
import "./assets/scss/global/print.scss";
import CalendarPage from "./pages/calendar";
import CalendarSearchPage from "./pages/calendar-search";
import CounterPage from "./pages/counter";
import IndexPage from "./pages/index";
import NewsSearchPage from "./pages/news-search";
import SettingsPage from "./pages/settings";
//import * as serviceWorker from "./serviceWorker";
import "./i18n";
import AccountSettingsPage from "./pages/settings/account";
import NotificationsSettingsPage from "./pages/settings/notifications";
import store from "./store";

// Enable Sentry
if (env !== "development")
  Sentry.init({
    dsn: "https://a65ad77107354ba496e23deb34ac4f47@o409747.ingest.sentry.io/5944665",
    autoSessionTracking: true,
    release: process.env.REACT_APP_OPENSHIFT_BUILD_COMMIT_REF || null,
    environment: env,
    attachStacktrace: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [null]
      })
    ],
    normalizeDepth: 3,
    tracesSampleRate: 0.1
  });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={null}>
        <Router>
          {/*<ScrollMemory />*/}
          <RedirectFromTrailingSlash />
          <GoogleAnalytics />
          <Notifications />
          <ToastContainer hideProgressBar position={toast.POSITION.BOTTOM_LEFT} />
          <LastLocationProvider>
            <Theme>
              <DefaultLayout>
                <Routes>
                  <Route element={<IndexPage />} path={indexRoute} />
                  <Route element={<CalendarPage />} path={calendarRoute} />
                  <Route element={<CounterPage />} path={counterRoute} />
                  <Route element={<NewsSearchPage />} path={newsSearchRoute} />
                  <Route element={<CalendarSearchPage />} path={calendarSearchRoute} />
                  <Route element={<SettingsPage />} path={settingsRoute}>
                    <Route element={<AccountSettingsPage />} path={settingsAccountRoute} />
                    <Route
                      element={<NotificationsSettingsPage />}
                      path={notificationsAccountRoute}
                    />
                  </Route>
                  <Route
                    element={(function NotFound() {
                      return <h1>404</h1>;
                    })()}
                  />
                </Routes>
              </DefaultLayout>
            </Theme>
          </LastLocationProvider>
        </Router>
      </Suspense>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
