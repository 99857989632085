import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectUserThemePreference } from "../../store/user/selector";

function Theme({ children }) {
  const preferredTheme = useSelector(selectUserThemePreference);

  const currentTheme = useRef(null);

  const setCurrentTheme = theme => {
    const newTheme = `${theme}-theme`;
    document.body.classList.remove(currentTheme.current);
    document.body.classList.add(newTheme);

    currentTheme.current = newTheme;
  };

  useEffect(() => {
    setCurrentTheme(preferredTheme);
  }, [preferredTheme]);

  return children || null;
}

Theme.propTypes = {
  children: PropTypes.node
};

export default Theme;
