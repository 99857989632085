import { useEffect } from "react";

import hotkeysJs from "hotkeys-js";
import { isFunction } from "lodash";

import i18n from "../i18n";

export const hotkeyScopesRegistry = {
  ALL: {
    title: i18n.t("hotkey:scope:all:title"),
    description: i18n.t("hotkey:scope:all:description")
  },
  MODAL: {
    title: i18n.t("hotkey:scope:modal:title"),
    description: i18n.t("hotkey:scope:modal:description")
  },
  ARTICLE: {
    title: i18n.t("hotkey:scope:article:title"),
    description: i18n.t("hotkey:scope:article:description")
  }
};

export const hotkeyScopes = Object.keys(hotkeyScopesRegistry).reduce((acc, curr) => {
  acc[curr] = curr.toLowerCase();
  return acc;
}, {});

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const hotkeyRegistry = {
  OPEN_HOTKEY_OVERVIEW: {
    // keys: isMac ? "fn + f1" : "f1",
    keys: "f1",
    description: i18n.t("hotkey:key:openHotKeyOverview:description"),
    scope: hotkeyScopes.ALL
  },
  PRINT: {
    keys: isMac ? "command+p" : "Ctrl+p",
    description: i18n.t("hotkey:key:print:description"),
    scope: hotkeyScopes.ALL
  },
  CLOSE_MODAL: {
    keys: "esc",
    description: i18n.t("hotkey:key:closeModal:description"),
    scope: hotkeyScopes.MODAL
  },
  NEXT_ARTICLE: {
    keys: "down",
    description: i18n.t("hotkey:key:nextArticle:description"),
    scope: hotkeyScopes.ARTICLE
  },
  PREVIOUS_ARTICLE: {
    keys: "up",
    description: i18n.t("hotkey:key:previousArticle:description"),
    scope: hotkeyScopes.ARTICLE
  },
  CLOSE_ARTICLE: {
    keys: "esc",
    description: i18n.t("hotkey:key:closeArticle:description"),
    scope: hotkeyScopes.ARTICLE
  },
  NEXT_COLUMN: {
    keys: "right",
    description: i18n.t("hotkey:key:nextColumn:description"),
    scope: hotkeyScopes.ARTICLE
  },
  PREVIOUS_COLUMN: {
    keys: "left",
    description: i18n.t("hotkey:key:previousColumn:description"),
    scope: hotkeyScopes.ARTICLE
  }
};

/**
 * Returns an object containing keys for all registered hotkeys
 * @type {{}}
 * @example
 * {NEXT_ARTICLE: "NEXT_ARTICLE"}
 */
export const hotkeys = Object.keys(hotkeyRegistry).reduce((acc, curr) => {
  acc[curr] = curr;
  return acc;
}, {});

export const hotkeyScopeList = (() => {
  const hotkeyScopes = [];
  for (const [key, value] of Object.entries(hotkeyScopesRegistry)) {
    hotkeyScopes.push({ ...value, scope: key.toLowerCase() });
  }
  return hotkeyScopes;
})();

export const hotkeyList = Object.values(hotkeyRegistry);

/**
 * Lets you bind a hotkey to a callback from the list of registered hotkeyRegistry
 * @param hotkey
 * @param callback A callback that is executed when the hotkey is pressed.
 * Override the browsers native hotkey action by returning false in the callback.
 * @returns {(function(): void)} A function to let you unbind the hotkey programmatically
 */
export function useHotkey(hotkey, callback) {
  useEffect(() => {
    if (!isFunction(callback)) return;

    const keys = hotkeyRegistry[hotkey]?.keys;
    const scope = hotkeyRegistry[hotkey]?.scope;

    if (keys) {
      hotkeysJs(keys, scope, callback);
    }

    return () => {
      hotkeysJs.unbind(keys, scope, callback);
    };
  }, [hotkey, callback]);

  return () => {
    hotkeysJs.unbind(hotkeyRegistry[hotkey].keys, callback);
  };
}

/**
 * Activates a hotkey scope
 * @param scope
 * @param active
 */
export function useHotkeyScope(scope, active = true) {
  useEffect(() => {
    if (!active) return;
    const currentScope = hotkeysJs.getScope();
    hotkeysJs.setScope(scope);

    return () => {
      if (scope === hotkeysJs.getScope()) {
        hotkeysJs.setScope(currentScope);
      }
    };
  }, [scope, active]);
}
