import React, { useEffect } from "react";

import { isEqual } from "lodash";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import style from "../assets/scss/components/calendar.module.scss";
import { hotkeys, hotkeyScopes, useHotkey, useHotkeyScope } from "../helpers/hotkeys";
import { selectActiveCalendarArticleFilters } from "../store/articleFilter/selector";
import {
  selectCalendarStartDate,
  selectCalendarEndDate,
  selectCalendarColumns,
  selectCalendarActiveNewsItemColumnId,
  selectCalendarActiveNewsItem,
  selectCalendarViewMode,
  selectCalendarModalNewsItem,
  selectCalendarIsMobile
} from "../store/calendar/selector";
import {
  changeDateRange,
  markNewsItemAsActive,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive,
  setModalNewsItem,
  setViewMode
} from "../store/calendar/slice";

import ArticleView from "./article/ArticleView";
import ArticleViewModal from "./article/ArticleViewModal";
import CalendarExtraActionRow from "./calendar/CalendarExtraActionRow";
import CalendarNewsColumn from "./CalendarNewsColumn";

function Calendar() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let queryObject = qs.parse(search, { ignoreQueryPrefix: true });

  const columns = useSelector(selectCalendarColumns);
  const columnsNumber = columns.length;
  const activeNewsItemColumnId = useSelector(selectCalendarActiveNewsItemColumnId);
  const modalNewsItem = useSelector(selectCalendarModalNewsItem);
  const activeNewsItem = useSelector(selectCalendarActiveNewsItem);
  const viewMode = useSelector(selectCalendarViewMode);
  const isMobile = useSelector(selectCalendarIsMobile);

  const startDate = useSelector(selectCalendarStartDate);
  const endDate = useSelector(selectCalendarEndDate);
  const activeCalendarFilters = useSelector(selectActiveCalendarArticleFilters, isEqual);

  const filterSearchStringCollection = activeCalendarFilters
    .filter(filter => !filter.default)
    .map(filter => filter?.properties?.searchString)
    .filter(searchString => !!searchString);

  useHotkeyScope(hotkeyScopes.ARTICLE);

  useHotkey(hotkeys.NEXT_ARTICLE, () => {
    dispatch(markNextNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_ARTICLE, () => {
    dispatch(markPreviousNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.NEXT_COLUMN, () => {
    dispatch(markNextNewsColumnAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_COLUMN, () => {
    dispatch(markPreviousNewsColumnAsActive());
    return false;
  });

  useEffect(() => {
    if (viewMode !== "kanban") {
      dispatch(changeDateRange({ startDate, endDate }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, isMobile]);

  useEffect(() => {
    dispatch(setViewMode(queryObject.viewMode));
  }, [dispatch, queryObject.viewMode]);

  console.log("Filter search string calendar ", filterSearchStringCollection);
  return (
    <div className={style["calendar-container"]}>
      <CalendarExtraActionRow datePickerSelectable={viewMode !== "kanban"} />
      <div className={style["calendar-container__calendar"]}>
        {modalNewsItem.id && (
          <ArticleViewModal
            article={modalNewsItem}
            onClose={() => dispatch(setModalNewsItem({}))}
          />
        )}
        {columns.map(column => {
          return (
            <React.Fragment key={`news-column-${column.id}`}>
              <CalendarNewsColumn columnsNumber={columnsNumber} id={column.id} />
              {activeNewsItemColumnId === column.id && (
                <ArticleView
                  article={activeNewsItem}
                  searchString={filterSearchStringCollection.join(" OR ")}
                  onClose={() => dispatch(markNewsItemAsActive({ id: null, columnId: null }))}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Calendar;
