export const selectArticleFilters = state => state.articleFilter.filters;

export const selectArticleFiltersLoading = state => state.articleFilter.loading;

export const selectAccessibleArticleFilters = state => {
  return selectArticleFilters(state).filter(filter => filter.accessible);
};

export const selectAccessibleDefaultArticleFilters = state => {
  return selectArticleFilters(state).filter(filter => filter.accessible && filter.default);
};

export const selectAccessibleNewsfeedArticleFilters = state => {
  return selectArticleFilters(state).filter(
    filter => filter.accessible && filter.visibility.includes("newsfeed")
  );
};

export const selectAccessibleCalendarArticleFilters = state => {
  return selectArticleFilters(state).filter(
    filter => filter.accessible && filter.visibility.includes("calendar")
  );
};

export const selectActiveCalendarArticleFilters = state => {
  return selectArticleFilters(state).filter(
    filter => filter.calendarActive && filter.visibility.includes("calendar")
  );
};

export const selectDefaultArticleFilters = state => {
  return selectArticleFilters(state).filter(filter => filter.default);
};

export const selectCustomArticleFilters = state => {
  return selectArticleFilters(state).filter(filter => !filter.default && !filter.isMyNews);
};

export const selectActiveArticleFilters = state => {
  return selectArticleFilters(state).filter(filter => filter.active);
};

export const selectActiveNewsfeedArticleFilters = state => {
  return selectArticleFilters(state).filter(
    filter =>
      filter.active &&
      filter.custom?.properties?.hiddenByUser !== "true" &&
      filter.visibility.includes("newsfeed")
  );
};

export const selectArticleFilterById = id => state => {
  return selectArticleFilters(state).find(filter => filter.id === id);
};

export const selectArticleFilterIsMobile = state => state.articleFilter.isMobile;
export const selectMediaTopics = state => state.articleFilter.mediaTopics;
export const selectRegions = state => state.articleFilter.regions;
