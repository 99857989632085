import React, { forwardRef } from "react";

import ReactSelect from "react-select";

import style from "../../assets/scss/components/form/select.module.scss";

const Select = forwardRef(function Select({ ...restProps }, ref) {
  return (
    <div className={style["select"]}>
      <ReactSelect classNamePrefix="select" ref={ref} {...restProps} />
    </div>
  );
});

export default Select;
