import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import {
  DEFAULT_SHOW_NUM_RESULTS,
  DEFAULT_SHOW_NUM_RESULTS_SINGLE_COLUMN
} from "../../helpers/constants";
import ArticleService from "../../services/ArticleService";

import { markNewsItemAsActive } from "./slice";

async function search(
  filters,
  startDate,
  endDate,
  sortOrder,
  latest,
  coverageTypes,
  highestCoverageStatus,
  offset = 0,
  numResults = DEFAULT_SHOW_NUM_RESULTS,
  abortController = null
) {
  const articleService = new ArticleService();

  return await articleService
    .where(builder => {
      builder.service("newscalendar");
      builder.searchQuery("", filters, { ignoreService: true, calendarFilter: true });
      builder.calendarStart(DateTime.fromSeconds(startDate).toFormat("yyyy-LL-dd"));
      builder.calendarStop(DateTime.fromSeconds(endDate).toFormat("yyyy-LL-dd"));
      builder.sortOrder(sortOrder);
      builder.latest(latest);
      builder.coverageTypes(coverageTypes);
      builder.highestCoverageStatus(highestCoverageStatus);
    })
    .offset(offset)
    .showNumResults(numResults)
    .search(abortController);
}

export const calendarSearch = createAsyncThunk(
  "calendar/calendarSearch",
  async (data, { getState, fulfillWithValue, rejectWithValue }) => {
    const { columnId, abortController } = data;
    const filters = getState().articleFilter.filters;
    const column = getState().calendar.columns.find(column => column.id === columnId);
    const coverageFilters = getState().calendar.coverageFilters;
    const calendarFilters = filters.filter(filter => filter.visibility.includes("calendar"));
    const numResults =
      getState().calendar.columns.length === 1
        ? DEFAULT_SHOW_NUM_RESULTS_SINGLE_COLUMN
        : DEFAULT_SHOW_NUM_RESULTS;
    const latest = getState().user.preferences.articleVersion === "latest";

    try {
      const articles = await search(
        calendarFilters,
        column.startDate,
        column.endDate,
        column.sort,
        latest,
        coverageFilters,
        column.highestCoverageStatus,
        0,
        numResults,
        abortController
      );

      return fulfillWithValue(articles);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadMoreArticlesByColumnId = createAsyncThunk(
  "calendar/loadMoreArticlesByColumnId",
  (columnId = "", { getState }) => {
    const filters = getState().articleFilter.filters;
    const column = getState().calendar.columns.find(column => column.id === columnId);
    const coverageFilters = getState().calendar.coverageFilters;
    const calendarFilters = filters.filter(filter => filter.visibility.includes("calendar"));
    const latest = getState().user.preferences.articleVersion === "latest";

    return search(
      calendarFilters,
      column.startDate,
      column.endDate,
      column.sort,
      latest,
      coverageFilters,
      column.highestCoverageStatus,
      column.articles.length
    );
  }
);

export const fetchNewArticlesByColumnId = createAsyncThunk(
  "calendar/fetchNewArticlesByColumnId",
  async (columnId = "", { getState }) => {
    const filters = getState().articleFilter.filters;
    const column = getState().calendar.columns.find(column => column.id === columnId);
    const coverageFilters = getState().calendar.coverageFilters;
    const calendarFilters = filters.filter(filter => filter.visibility.includes("calendar"));
    const latest = getState().user.preferences.articleVersion === "latest";
    const defaultResultsNumber =
      getState().calendar.columns.length === 1
        ? DEFAULT_SHOW_NUM_RESULTS_SINGLE_COLUMN
        : DEFAULT_SHOW_NUM_RESULTS;

    let response;

    let numResults =
      column.articles.length >= defaultResultsNumber
        ? column.articles.length
        : defaultResultsNumber;

    // Are there any extra articles added to the column that backend is not aware of?
    // If so we must reduce the number of results to fetch.
    if (column.extraArticles && column.extraArticles.length > 0) {
      numResults -= column.extraArticles.length;
    }

    response = await search(
      calendarFilters,
      column.startDate,
      column.endDate,
      column.sort,
      latest,
      coverageFilters,
      column.highestCoverageStatus,
      0,
      numResults
    );

    response.latest = latest;
    return response;
  }
);

export const markNextNewsItemAsActive = () => (dispatch, getState) => {
  const state = getState();
  const calendar = state.calendar;

  // if no article is selected, select the first article in the first column
  if (!calendar.activeNewsItemId) {
    if (!calendar.columns[0].articles.length) return;

    return dispatch(
      markNewsItemAsActive({
        id: calendar.columns[0].articles[0].id,
        columnId: calendar.columns[0].id
      })
    );
  }

  const currentColumn = calendar.columns.find(
    column => column.id === calendar.activeNewsItemColumnId
  );

  const nextActiveNewsItemIndex =
    currentColumn.articles.findIndex(article => article.id === calendar.activeNewsItemId) + 1;

  if (nextActiveNewsItemIndex > currentColumn.articles.length - 1) return;

  const nextActiveNewsItemId = currentColumn.articles[nextActiveNewsItemIndex].id;

  dispatch(
    markNewsItemAsActive({
      id: nextActiveNewsItemId,
      columnId: calendar.activeNewsItemColumnId
    })
  );
};

export const markPreviousNewsItemAsActive = () => (dispatch, getState) => {
  const state = getState();
  const calendar = state.calendar;

  if (!calendar.activeNewsItemId) {
    if (!calendar.columns[0].articles.length) return;

    return dispatch(
      markNewsItemAsActive({
        id: calendar.columns[0].articles[0].id,
        columnId: calendar.columns[0].id
      })
    );
  }

  const currentColumn = calendar.columns.find(
    column => column.id === calendar.activeNewsItemColumnId
  );

  const previousActiveNewsItemIndex =
    currentColumn.articles.findIndex(article => article.id === calendar.activeNewsItemId) - 1;

  if (previousActiveNewsItemIndex < 0) return;

  const previousActiveNewsItemId = currentColumn.articles[previousActiveNewsItemIndex].id;

  dispatch(
    markNewsItemAsActive({
      id: previousActiveNewsItemId,
      columnId: calendar.activeNewsItemColumnId
    })
  );
};

export const markNextNewsColumnAsActive = () => (dispatch, getState) => {
  const state = getState();
  const calendar = state.calendar;

  if (!calendar.activeNewsItemColumnId) {
    return dispatch(
      markNewsItemAsActive({
        id: calendar.columns[0].articles[0].id,
        columnId: calendar.columns[0].id
      })
    );
  }

  const currentColumnIndex = calendar.columns.findIndex(
    column => column.id === calendar.activeNewsItemColumnId
  );

  if (currentColumnIndex === calendar.columns.length - 1) return;

  return dispatch(
    markNewsItemAsActive({
      id: calendar.columns[currentColumnIndex + 1].articles[0].id,
      columnId: calendar.columns[currentColumnIndex + 1].id
    })
  );
};

export const markPreviousNewsColumnAsActive = () => (dispatch, getState) => {
  const state = getState();
  const calendar = state.calendar;

  if (!calendar.activeNewsItemColumnId) {
    return dispatch(
      markNewsItemAsActive({
        id: calendar.columns[0].articles[0].id,
        columnId: calendar.columns[0].id
      })
    );
  }

  const currentColumnIndex = calendar.columns.findIndex(
    column => column.id === calendar.activeNewsItemColumnId
  );

  if (currentColumnIndex === 0) return;

  return dispatch(
    markNewsItemAsActive({
      id: calendar.columns[currentColumnIndex - 1].articles[0].id,
      columnId: calendar.columns[currentColumnIndex - 1].id
    })
  );
};
