import { useEffect } from "react";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-0VFKDB0WK9", {
      gaOptions: {
        anonymizeIp: true
      }
    });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
}

export default GoogleAnalytics;
