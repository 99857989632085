import React, { useCallback, useMemo } from "react";

import { ReactComponent as PlayIcon } from "@ntbjs/react-components/icons/play.svg";
import cn from "classnames";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.scss";

import "slick-carousel/slick/slick-theme.scss";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-long.svg";
import { ReactComponent as PieChartIcon } from "../../assets/icons/pie-chart.svg";
import style from "../../assets/scss/components/article/slider.module.scss";

import Caption from "./Caption";

function NextArrow({ className, onClick }) {
  return <ArrowIcon className={className} onClick={onClick} />;
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

function PrevArrow({ className, onClick }) {
  return <ArrowIcon className={className} onClick={onClick} />;
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

function Slider({ mediaList, onItemClicked }) {
  const sliderSettings = {
    infinite: true,
    slidesToShow: mediaList.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    touchMove: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onClick = useCallback(
    media => {
      if (isFunction(onItemClicked)) {
        return onItemClicked(media);
      }
    },
    [onItemClicked]
  );
  const hasOtherMediaThenImage = useMemo(
    () => mediaList.some(m => m.mediaType !== "image"),
    [mediaList]
  );

  return (
    <div
      className={cn(style["slider"], {
        [style["slider--with-gutter-offset"]]: !hasOtherMediaThenImage
      })}
    >
      <SlickSlider {...sliderSettings}>
        {mediaList.map(media => (
          <div
            className={cn(style["slider__item"], {
              [style["slider__item--other-media"]]: hasOtherMediaThenImage
            })}
            key={`media-item-${media.id}`}
          >
            <div className={style["slider__image-container"]} onClick={onClick(media)}>
              {hasOtherMediaThenImage && (
                <div className={style["slider__image-container__overlay"]}>
                  <div
                    className={style["slider__image-container__overlay__caption"]}
                    title={media.caption}
                  >
                    {media.mediaType === "video" && <PlayIcon />}
                    {media.mediaType === "grafikk" && <PieChartIcon />}
                  </div>
                </div>
              )}
              <img className={style["slider__image-container__image"]} src={media.url} />
            </div>
            <Caption charLimit={!hasOtherMediaThenImage ? 140 : 100} mediaId={media.id}>
              {media.caption}
            </Caption>
          </div>
        ))}
      </SlickSlider>
    </div>
  );
}

Slider.propTypes = {
  mediaList: PropTypes.array,
  onItemClicked: PropTypes.func
};

export default Slider;
