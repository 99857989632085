import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { LS_KEY_INACTIVE_MY_NEWS_FILTERS } from "../../helpers/constants";
import UserService from "../../services/UserService";

import { getFilters, addFilter, toggleNotificationType, getMediaTopics, getRegions } from "./thunk";

export const articleFilterSlice = createSlice({
  name: "articleFilter",
  initialState: {
    loading: true,
    filters: [],
    mediaTopics: [],
    regions: [],
    isMobile: false
  },
  reducers: {
    reorderFilter(state, action) {
      const filterIds = action.payload;

      filterIds.forEach((filterId, filterIndex) => {
        const index = state.filters.findIndex(filter => filter.id === filterId);
        if (index === -1) return;

        if (!state.filters[index].custom) {
          state.filters[index].custom = {
            properties: {
              order: filterIndex + 1
            }
          };
        } else {
          state.filters[index].custom.properties.order = filterIndex + 1;
        }

        const userService = new UserService();
        userService.updateFilter(cloneDeep(state.filters[index])).then();
      });
    },
    hideFilter(state, action) {
      const id = action.payload.id;
      const hiddenByUser = action.payload.hiddenByUser;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      if (!state.filters[index].custom) {
        state.filters[index].custom = {
          properties: {
            hiddenByUser: hiddenByUser
          }
        };
      } else {
        state.filters[index].custom.properties.hiddenByUser = hiddenByUser;
      }

      const userService = new UserService();
      userService.updateFilter(cloneDeep(state.filters[index])).then();
    },
    toggleFilter(state, action) {
      const id = action.payload.id;
      const isCalendarFilter = action.payload.calendarFilter;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      const activeFilters = state.filters.filter(filter =>
        isCalendarFilter ? filter.calendarActive : filter.active
      );
      const triggeredFilterOldOrder = state.filters[index].custom?.properties?.order;
      const triggeredFilterNewOrder = (
        isCalendarFilter ? !state.filters[index].calendarActive : !state.filters[index].active
      )
        ? activeFilters.length + 1
        : null;

      if (!state.filters[index].custom) {
        state.filters[index].custom = {
          properties: {
            order: triggeredFilterNewOrder
          }
        };
      } else {
        state.filters[index].custom.properties.order = triggeredFilterNewOrder;
      }

      // Rearrange order for other filters
      // When the triggered filter was deactivated
      if (triggeredFilterOldOrder && !triggeredFilterNewOrder) {
        activeFilters.map(filter => {
          const filterOrder = filter.custom?.properties?.order;

          if (filterOrder && filterOrder > triggeredFilterOldOrder) {
            filter.custom.properties.order = filterOrder - 1;

            const userService = new UserService();
            return userService.updateFilter(cloneDeep(filter)).then();
          }
        });
      }

      if (isCalendarFilter) {
        state.filters[index].calendarActive = !state.filters[index].calendarActive;
      } else {
        state.filters[index].active = !state.filters[index].active;
      }

      if (!state.filters[index].isMyNews) {
        const userService = new UserService();
        userService.updateFilter(cloneDeep(state.filters[index])).then();
      } else {
        let inactiveMyNewsFilters =
          JSON.parse(window.localStorage.getItem(LS_KEY_INACTIVE_MY_NEWS_FILTERS)) || [];

        const indexInLocalStorage = inactiveMyNewsFilters.indexOf(state.filters[index].id);

        if (indexInLocalStorage === -1) {
          inactiveMyNewsFilters.push(state.filters[index].id);
        } else {
          inactiveMyNewsFilters.splice(indexInLocalStorage, 1);
        }

        window.localStorage.setItem(
          LS_KEY_INACTIVE_MY_NEWS_FILTERS,
          JSON.stringify(inactiveMyNewsFilters)
        );
      }

      // Special handling for the "All news" filter
      const allNewsFilterIndex = state.filters.findIndex(
        filter => filter.custom?.properties?.originalId === "all"
      );

      if (allNewsFilterIndex === -1) return;
      let allNewsFilterUpdateRequired = false;

      // Deactivate the "All news" filter when other filter was activated

      if (isCalendarFilter) {
        if (
          state.filters[allNewsFilterIndex].calendarActive &&
          state.filters[index].calendarActive &&
          allNewsFilterIndex !== index
        ) {
          state.filters[allNewsFilterIndex].calendarActive = false;
          allNewsFilterUpdateRequired = true;
        }
      } else {
        if (
          state.filters[allNewsFilterIndex].active &&
          state.filters[index].active &&
          allNewsFilterIndex !== index
        ) {
          // state.filters[allNewsFilterIndex].active = false;
          allNewsFilterUpdateRequired = true;
        }
      }

      // No active filters left? Activate "All news" filter then!

      if (isCalendarFilter) {
        if (activeFilters.length === 1 && !state.filters[index].calendarActive) {
          state.filters[allNewsFilterIndex].calendarActive = true;
          allNewsFilterUpdateRequired = true;
        }
      } else {
        if (activeFilters.length === 1 && !state.filters[index].active) {
          state.filters[allNewsFilterIndex].active = true;
          allNewsFilterUpdateRequired = true;
        }
      }

      if (allNewsFilterUpdateRequired) {
        const userService = new UserService();
        userService.updateFilter(cloneDeep(state.filters[allNewsFilterIndex])).then();
      }
    },
    updateFilter(state, action) {
      const { id, title, searchString, mediaTopics, regions, defaultFilters } = action.payload;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      state.filters[index].title = title;
      state.filters[index].properties.searchString = searchString;
      state.filters[index].properties.defaultFilters = defaultFilters;
      state.filters[index].properties.mediaTopics = mediaTopics;
      state.filters[index].properties.regions = regions;

      const userService = new UserService();
      userService.updateFilter(cloneDeep(state.filters[index])).then();
    },
    removeFilter(state, action) {
      const id = action.payload.id;
      const isCalendarFilter = action.payload.calendarFilter;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      const userService = new UserService();
      userService.removeFilter(cloneDeep(state.filters[index])).then();

      state.filters.splice(index, 1);

      // If no active filters left activate the "All news" filter
      const activeFilters = state.filters.filter(filter =>
        isCalendarFilter ? filter.calendarActive : filter.active
      );

      if (!activeFilters.length) {
        const allNewsFilterIndex = state.filters.findIndex(
          filter => filter.custom?.properties?.originalId === "all"
        );

        if (allNewsFilterIndex !== -1) {
          if (isCalendarFilter) {
            state.filters[allNewsFilterIndex].calendarActive = true;
          } else {
            state.filters[allNewsFilterIndex].active = true;
          }

          if (!state.isMobile) {
            const userService = new UserService();
            userService.updateFilter(cloneDeep(state.filters[allNewsFilterIndex])).then();
          }
        }
      }
    },
    activateAllNewsFilter(state, action) {
      const isCalendarFilter = action.payload.calendarFilter;
      const index = state.filters.findIndex(
        filter => filter.custom?.properties?.originalId === "all"
      );

      if (index === -1) return;

      const activeFilters = state.filters.filter(filter =>
        isCalendarFilter ? filter.calendarActive : filter.active
      );

      // Deactivate all active filters.
      activeFilters.map(filter => {
        if (isCalendarFilter) {
          filter.calendarActive = false;
        } else {
          filter.active = false;
        }

        if (!filter.isMyNews) {
          const userService = new UserService();
          return userService.updateFilter(cloneDeep(filter)).then();
        } else {
          let inactiveMyNewsFilters =
            JSON.parse(window.localStorage.getItem(LS_KEY_INACTIVE_MY_NEWS_FILTERS)) || [];

          inactiveMyNewsFilters.push(filter.id);

          return window.localStorage.setItem(
            LS_KEY_INACTIVE_MY_NEWS_FILTERS,
            JSON.stringify(inactiveMyNewsFilters)
          );
        }
      });

      if (isCalendarFilter) {
        state.filters[index].calendarActive = true;
      } else {
        state.filters[index].active = true;
      }

      const userService = new UserService();
      userService.updateFilter(cloneDeep(state.filters[index])).then();
    },
    toggleFilterMobile(state, action) {
      const id = action.payload.id;
      const isCalendarFilter = action.payload.calendarFilter;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      if (isCalendarFilter) {
        state.filters[index].calendarActive = !state.filters[index].calendarActive;
      } else {
        state.filters[index].active = !state.filters[index].active;
      }

      // Special handling for the "All news" filter
      const allNewsFilterIndex = state.filters.findIndex(
        filter => filter.custom?.properties?.originalId === "all"
      );

      if (allNewsFilterIndex === -1) return;
      const activeFilters = state.filters.filter(filter =>
        isCalendarFilter ? filter.calendarActive : filter.active
      );

      // Deactivate the "All news" filter when other filter was activated
      if (isCalendarFilter) {
        if (
          state.filters[allNewsFilterIndex].calendarActive &&
          state.filters[index].calendarActive &&
          allNewsFilterIndex !== index
        ) {
          state.filters[allNewsFilterIndex].calendarActive = false;
        }
      } else {
        if (
          state.filters[allNewsFilterIndex].active &&
          state.filters[index].active &&
          allNewsFilterIndex !== index
        ) {
          state.filters[allNewsFilterIndex].active = false;
        }
      }

      // No active filters left? Activate "All news" filter then!
      if (!activeFilters.length) {
        if (isCalendarFilter) {
          state.filters[allNewsFilterIndex].calendarActive = true;
        } else {
          state.filters[allNewsFilterIndex].active = true;
        }
      }
    },
    enforceOneActiveFilterMobile(state, action) {
      const id = action.payload.id;
      const isCalendarFilter = action.payload.calendarFilter;
      const index = state.filters.findIndex(filter => filter.id === id);

      if (index === -1) return;

      const activeFilters = state.filters.filter(filter =>
        isCalendarFilter ? filter.calendarActive : filter.active
      );

      // Deactivate all active filters.
      activeFilters.map(filter => {
        if (isCalendarFilter) {
          filter.calendarActive = false;
        } else {
          filter.active = false;
        }
      });

      if (isCalendarFilter) {
        state.filters[index].calendarActive = true;
      } else {
        state.filters[index].active = true;
      }
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getFilters.pending, state => {
      state.loading = true;
    });

    builder.addCase(getFilters.fulfilled, (state, { payload }) => {
      state.filters = payload;
      state.loading = false;
    });

    builder.addCase(addFilter.fulfilled, (state, { payload }) => {
      const isCalendarFilter = payload.calendarFilter;
      state.filters.push(payload.newFilter);

      // Since the newly added filter is active by default make sure:
      // 1. "All news" filter is deactivated on both mobile and desktop
      const allNewsFilterIndex = state.filters.findIndex(
        filter => filter.custom?.properties?.originalId === "all"
      );

      if (isCalendarFilter) {
        if (allNewsFilterIndex !== -1 && state.filters[allNewsFilterIndex].calendarActive) {
          state.filters[allNewsFilterIndex].calendarActive = false;
          const userService = new UserService();
          userService.updateFilter(cloneDeep(state.filters[allNewsFilterIndex])).then();
        }
      } else {
        if (allNewsFilterIndex !== -1 && state.filters[allNewsFilterIndex].active) {
          state.filters[allNewsFilterIndex].active = false;
          const userService = new UserService();
          userService.updateFilter(cloneDeep(state.filters[allNewsFilterIndex])).then();
        }
      }

      // 2. All other active filters are deactivated on mobile only
      if (state.isMobile) {
        const activeFilters = state.filters.filter(
          filter => filter.active && filter.id !== payload.id
        );

        activeFilters.map(filter => {
          filter.active = false;
        });
      }
    });

    builder.addCase(toggleNotificationType.pending, (state, { meta }) => {
      // We are updating the active notification types to reflect the change in
      // the UI immediately - The actual update is done in the thunk

      const { filterId, notificationType, enabled } = meta.arg;

      const index = state.filters.findIndex(filter => filter.id === filterId);
      if (index === -1) return;

      // state.filters[index].notification.types = type;

      if (!state.filters[index].notification) {
        state.filters[index].notification = { types: [notificationType] };
        return;
      }

      let types = state.filters[index].notification.types;

      if (enabled) {
        types.push(notificationType);
      } else {
        state.filters[index].notification.types = types.filter(t => t !== notificationType);
      }
    });

    builder.addCase(toggleNotificationType.fulfilled, (state, { meta, payload }) => {
      const { filterId } = meta.arg;

      const index = state.filters.findIndex(filter => filter.id === filterId);
      if (index === -1) return;
      delete payload.types;
      state.filters[index].notification = {
        ...payload,
        ...{ types: state.filters[index].notification.types }
      };
    });

    builder.addCase(getMediaTopics.fulfilled, (state, { payload }) => {
      state.mediaTopics = payload;
    });

    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
    });
  }
});

export const {
  reorderFilter,
  toggleFilter,
  removeFilter,
  updateFilter,
  hideFilter,
  activateAllNewsFilter,
  enforceOneActiveFilterMobile,
  toggleFilterMobile,
  setIsMobile
} = articleFilterSlice.actions;

export { getFilters, addFilter, toggleNotificationType, getMediaTopics, getRegions };

export default articleFilterSlice.reducer;
