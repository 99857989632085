import React, { useEffect, useState, useCallback } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import classNames from "classnames";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import style from "../../../assets/scss/components/article/actions/article-copy-to-clipboard.module.scss";

function CopyToClipboard({ article }) {
  const { t } = useTranslation();

  const [contentCopied, setContentCopied] = useState(false);
  const [copyContentHtml, setCopyContentHtml] = useState("");
  const [copyContentPlain, setCopyContentPlain] = useState("");

  useEffect(() => {
    if (article) {
      prepareCopyContent(article);
    }

    return () => {
      setContentCopied(false);
      setCopyContentHtml("");
      setCopyContentPlain("");
    };
  }, [article]);

  const prepareCopyContent = article => {
    const copyContentPlain =
      DateTime.fromMillis(article.pubdate).toFormat("dd. LLL. yyyy, 'kl.' HH.mm") +
      "\n\n" +
      article.title +
      "\n\n" +
      article.contentMarkup
        .replace(
          /<p .*?class="lead".*?>(.*?)<\/p>/gi,
          "<p>$1</p>\n" + "<p>Av: " + article.byline + "</p>"
        )
        // remove extra whitespaces
        .replace(/\s{2,}/g, " ")
        // replace p tags with line breaks
        .replaceAll(/<p.*?>((.|[\r\n])*?)<\/p>/gim, "$1\n")
        // remove all remaining tags
        .replace(/<\/?[^>]+(>|$)/g, "")
        .trim();

    // Increase font-size for lead text
    let copyContentHtml = article.contentMarkup.replace(
      /<p .*?class="lead".*?>(.*?)<\/p>/gi,
      "<p style='font-size: 20px;'>$1</p><p style='font-style: italic'>Av: " +
        article.byline +
        "</p>"
    );

    // Replace all divs with hl2 class on h2
    copyContentHtml = copyContentHtml.replaceAll(
      /<div .*?class="hl2".*?>(.*?)<\/div>/gi,
      "<h2>$1</h2>"
    );

    // Add title and published date
    copyContentHtml =
      "<em>" +
      DateTime.fromMillis(article.pubdate).toFormat("dd. LLL. yyyy, 'kl.' HH.mm") +
      "</em>\n" +
      "<h1>" +
      article.title +
      "</h1>\n" +
      copyContentHtml;

    setCopyContentPlain(copyContentPlain);
    setCopyContentHtml(copyContentHtml);
  };

  const onCopy = useCallback(() => {
    setContentCopied(true);
  }, [setContentCopied]);

  return copyContentHtml && copyContentPlain ? (
    <ReactCopyToClipboard
      options={{
        debug: true,
        format: "text/html",
        onCopy: clipboardData => {
          clipboardData.setData("text/plain", copyContentPlain);

          ReactGA.event({
            category: "Article",
            action: "Copy to clipboard",
            label: article.title
          });
        }
      }}
      text={copyContentHtml}
      onCopy={onCopy}
    >
      <div className={style["article-copy-to-clipboard-wrapper"]}>
        <Tooltip
          content={!contentCopied ? t("articleView:actions:copy") : t("articleView:actions:copied")}
          hideOnClick={false}
          onHidden={() => setContentCopied(false)}
        >
          <CopyIcon
            className={classNames(
              style["article-copy-to-clipboard"],
              style["article-copy-to-clipboard--active"]
            )}
          />
        </Tooltip>
      </div>
    </ReactCopyToClipboard>
  ) : (
    <CopyIcon className={style["article-copy-to-clipboard"]} />
  );
}

CopyToClipboard.propTypes = {
  article: PropTypes.object
};

export default CopyToClipboard;
