import React, {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useEffect
} from "react";

import { Popover } from "@ntbjs/react-components/data";
import { MultiLevelCheckboxSelect } from "@ntbjs/react-components/inputs";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import { useEffectOnce } from "usehooks-ts";

import { ReactComponent as ClearIcon } from "../../assets/icons/clear-alt.svg";
import { ReactComponent as OptionsIcon } from "../../assets/icons/options.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import style from "../../assets/scss/components/search/search-field.module.scss";
import DatePickerRange from "../../components/form/DatePickerRange";
import {
  indexRoute,
  calendarRoute,
  newsSearchRoute,
  calendarSearchRoute
} from "../../helpers/routes";
import {
  selectAccessibleDefaultArticleFilters,
  selectMediaTopics,
  selectRegions,
  selectArticleFilterIsMobile
} from "../../store/articleFilter/selector";
import { getMediaTopics, getRegions } from "../../store/articleFilter/slice";
import { markNewsItemAsActive as markCalendarItemAsActive } from "../../store/calendar/slice";
import { markNewsItemAsActive } from "../../store/dashboard/slice";
import Select from "../form/Select";

import FilterForm from "./FilterForm";

const SearchField = forwardRef(function SearchField({ className }, ref) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const inputContainer = useRef(null);

  const isCalendarPage = pathname == calendarSearchRoute || pathname == calendarRoute;

  const defaultFilters = useSelector(selectAccessibleDefaultArticleFilters, isEqual);
  const regionOptions = useSelector(selectRegions, isEqual);
  const mediaTopicOptions = useSelector(selectMediaTopics, isEqual);

  let queryObject = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const startDateQueryParam = queryObject.startDate ? queryObject.startDate : "";
  const endDateQueryParam = queryObject.endDate ? queryObject.endDate : "";

  const defaultFiltersQuery = useMemo(() => {
    return queryObject.defaultFilters ? queryObject.defaultFilters : [];
  }, [queryObject]);

  const [searchStringQuery, setSearchStringQuery] = useQueryState("searchString", "");
  const [startDateQuery] = useQueryState("startDate", "");
  const [endDateQuery] = useQueryState("endDate", "");
  const [defaultFiltersQueryState] = useQueryState("defaultFilters", []);

  const [mediaTopicsQuery] = useQueryState("mediaTopics", []);
  const [regionsQuery] = useQueryState("regions", []);

  const [searchString, setSearchString] = useState(searchStringQuery);
  const [startDate, setStartDate] = useState(startDateQuery);
  const [endDate, setEndDate] = useState(endDateQuery);
  // const [categories, setCategories] = useState(categoriesQuery);
  const [defaultFilterValues, setDefaultFilterValues] = useState(defaultFiltersQueryState);
  const [mediaTopics, setMediaTopics] = useState(mediaTopicsQuery);
  const [regions, setRegions] = useState(regionsQuery);

  // Field controllers - Only used to control input fields
  const [selectedMediaTopicOptions, setSelectedMediaTopicOptions] = useState(mediaTopicsQuery);
  const [selectedRegionOptions, setSelectedRegionOptions] = useState(regionsQuery);

  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  const defaultFiltersOptions = useMemo(() => {
    return defaultFilters
      .filter(filter => (isCalendarPage ? filter.visibility.includes("calendar") : true))
      .map(filter => ({
        value: filter.title,
        label: t(filter.title)
      }));
  }, [t, defaultFilters, isCalendarPage]);

  const defaultFilterValuesToOptionObject = useCallback(
    defaultFilters => {
      if (isEmpty(defaultFilters) || isEmpty(defaultFiltersOptions)) {
        return [];
      }

      return defaultFiltersOptions.filter(defaultFilterOption =>
        defaultFilters.includes(defaultFilterOption.value)
      );
    },
    [defaultFiltersOptions]
  );
  const [optionsIsOpen, setOptionsIsOpen] = useState(false);

  const showOptions = () => setOptionsIsOpen(true);
  const hideOptions = () => setOptionsIsOpen(false);

  const maybeHideOptions = (instance, event) => {
    if (!event.target.classList.value.includes("react-datepicker")) {
      hideOptions();
    }
  };

  const mediaTopicsQuerySerialized = JSON.stringify(mediaTopicsQuery.sort());
  const regionsQuerySerialized = JSON.stringify(regionsQuery.sort());

  useEffect(() => {
    setDefaultFilterValues(defaultFiltersQuery);
  }, [defaultFiltersQuery]);

  useEffectOnce(() => {
    dispatch(getMediaTopics());
    dispatch(getRegions());
  });

  useEffect(() => {
    setStartDate(startDateQueryParam);
  }, [startDateQueryParam]);

  useEffect(() => {
    setEndDate(endDateQueryParam);
  }, [endDateQueryParam]);

  useEffect(() => {
    setSelectedMediaTopicOptions(JSON.parse(mediaTopicsQuerySerialized));
  }, [mediaTopicsQuerySerialized]);

  useEffect(() => {
    setRegions(JSON.parse(regionsQuerySerialized));
  }, [regionsQuerySerialized]);

  function onKeyDown(event) {
    const { key } = event;
    if (key === "Enter") {
      doSearch();
    }
  }

  function getRoute() {
    let route;
    switch (pathname) {
      case indexRoute:
      case indexRoute.replace(/\/$/, ""):
      case newsSearchRoute:
        route = newsSearchRoute;
        break;
      case calendarRoute:
      case calendarSearchRoute:
        route = calendarSearchRoute;
        break;
      default:
        route = newsSearchRoute;
    }

    return route;
  }

  function onInputChange(event) {
    setSearchString(event.target.value);
  }

  function onDateRangeChange(startDate, endDate) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function doSearch() {
    let searchParamsChanged = false;
    const searchRoute = getRoute();

    if (searchStringQuery !== searchString) {
      if (searchString) {
        queryObject.searchString = searchString;
      } else {
        delete queryObject.searchString;
      }

      searchParamsChanged = true;
    }

    if (startDateQueryParam !== startDate) {
      if (startDate) {
        queryObject.startDate = startDate;
      } else {
        delete queryObject.startDate;
      }

      searchParamsChanged = true;
    }

    if (endDateQueryParam !== endDate) {
      if (endDate) {
        queryObject.endDate = endDate;
      } else {
        delete queryObject.endDate;
      }

      searchParamsChanged = true;
    }

    if (defaultFiltersQuery !== defaultFilterValues) {
      if (defaultFilterValues.length > 0) {
        queryObject.defaultFilters = defaultFilterValues;
      } else {
        delete queryObject.defaultFilters;
      }

      searchParamsChanged = true;
    }
    if (mediaTopicsQuery !== mediaTopics) {
      if (mediaTopics.length > 0) {
        queryObject.mediaTopics = mediaTopics;
      } else {
        delete queryObject.mediaTopics;
      }

      searchParamsChanged = true;
    }

    if (regionsQuery !== regions) {
      if (regions.length > 0) {
        queryObject.regions = regions;
      } else {
        delete queryObject.regions;
      }

      searchParamsChanged = true;
    }

    if (searchParamsChanged || searchRoute !== pathname) {
      dispatch(markCalendarItemAsActive({ id: null, columnId: null }));
      dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      hideOptions();
      const isAdvancedSearch = !!(queryObject.startDate || queryObject.endDate);

      if (queryObject.hasOwnProperty("service") || queryObject.hasOwnProperty("article")) {
        delete queryObject.service;
        delete queryObject.article;
      }

      // Removing viewMode parameter since it has no effect on the search
      if (queryObject.hasOwnProperty("viewMode")) delete queryObject.viewMode;

      navigate({
        pathname: searchRoute,
        search: qs.stringify(queryObject, { arrayFormat: "repeat" })
      });

      ReactGA.event({
        category: searchRoute === calendarSearchRoute ? "Search calendar" : "Search newsfeed",
        action: isAdvancedSearch ? "Advanced search" : "Basic search",
        label: qs.stringify(queryObject, { arrayFormat: "repeat" })
      });
    }
  }

  function resetSearch() {
    setSearchString("");
    setStartDate("");
    setEndDate("");
    setDefaultFilterValues([]);
    setSelectedMediaTopicOptions([]);
    setSelectedRegionOptions([]);
    hideOptions();

    delete queryObject.searchString;
    delete queryObject.startDate;
    delete queryObject.endDate;
    delete queryObject.categories;
    delete queryObject.mediaTopics;
    delete queryObject.regions;
    delete queryObject.defaultFilters;

    navigate({
      pathname: getRoute(),
      search: qs.stringify(queryObject)
    });
  }

  const clearSearch = useCallback(() => {
    setSearchString("");
    setSearchStringQuery("");
    setEndDate("");
    setDefaultFilterValues([]);
    setSelectedMediaTopicOptions([]);
    setSelectedRegionOptions([]);

    let newRoute;
    if (pathname === newsSearchRoute) {
      newRoute = indexRoute;
    }

    if (pathname === calendarSearchRoute) {
      newRoute = calendarRoute;
    }

    if (newRoute) {
      navigate({
        pathname: newRoute
      });
    }
  }, [navigate, pathname, setSearchStringQuery]);

  const optionsContent = () => {
    const searchRoute = getRoute();

    return (
      <div className={style["search__options__content"]}>
        <Select
          isMulti
          options={defaultFiltersOptions}
          placeholder={t("search:filter:form:defaultFilters")}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              marginBottom: 16
            })
          }}
          value={defaultFilterValuesToOptionObject(defaultFilterValues)}
          onChange={async selectedOptions => {
            setDefaultFilterValues(selectedOptions.map(selectedOption => selectedOption.value));
          }}
        />

        {mediaTopicOptions.length > 0 && (
          <MultiLevelCheckboxSelect
            checked={selectedMediaTopicOptions}
            label={"Tema"}
            options={mediaTopicOptions}
            style={{ marginBottom: 16 }}
            onChange={setSelectedMediaTopicOptions}
            onParentChange={setMediaTopics}
          />
        )}
        {regionOptions.length > 0 && (
          <MultiLevelCheckboxSelect
            checked={selectedRegionOptions}
            label={"Region"}
            options={regionOptions}
            onChange={setSelectedRegionOptions}
            onParentChange={setRegions}
          />
        )}
        <DatePickerRange
          endDate={endDate}
          options={
            searchRoute === calendarSearchRoute
              ? ["today", "tomorrow", "next7days", "next30days", "next90days"]
              : ["today", "yesterday", "last7days", "last30days", "last180days", "last365days"]
          }
          startDate={startDate}
          onChange={(startDate, endDate) => onDateRangeChange(startDate, endDate)}
        />
        <div className={style["search__options__content__actions"]}>
          {searchStringQuery ||
          startDateQueryParam ||
          endDateQueryParam ||
          mediaTopicsQuery.length ||
          regionsQuery.length ||
          defaultFiltersQuery.length ? (
            <div onClick={() => resetSearch()}>{t("search:actions:reset")}</div>
          ) : null}
          <FilterForm
            calendarFilter={pathname === calendarSearchRoute || pathname === calendarRoute}
            filter={{
              properties: {
                searchString,
                regions,
                mediaTopics,
                defaultFilters: defaultFilterValues
              }
            }}
          />
          <div onClick={doSearch}>{t("search:actions:search")}</div>
        </div>
      </div>
    );
  };

  useImperativeHandle(
    ref,
    () => ({
      clearSearch() {
        clearSearch();
      }
    }),
    [clearSearch]
  );

  return (
    <div className={classNames(style["search"], className)}>
      <div className={style["search__input-container"]} ref={inputContainer}>
        <input
          className={classNames(style["search__input"])}
          placeholder="Søk her"
          type="text"
          value={searchString}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
        />

        {searchString.length || pathname === newsSearchRoute || pathname === calendarSearchRoute ? (
          <ClearIcon
            className={style["search__clear-input"]}
            onClick={() => {
              clearSearch();
            }}
          />
        ) : null}

        <div className={classNames(style["search__options"])}>
          <Popover
            arrow={false}
            content={optionsContent()}
            duration={0}
            modifiers={[
              {
                name: "sameWidth",
                enabled: true,
                phase: "beforeWrite",
                requires: ["computeStyles"],
                fn: ({ state }) => {
                  state.styles.popper.width = `${inputContainer.current.width}px`;
                },
                effect: ({ state }) => {
                  state.elements.popper.style.width = `${inputContainer.current.offsetWidth}px`;
                }
              }
            ]}
            offset={[12, 8]}
            placement={isMobile ? "bottom-start" : "bottom-end"}
            visible={optionsIsOpen}
            onClickOutside={(instance, event) => maybeHideOptions(instance, event)}
          >
            <OptionsIcon
              className={style["search__options__icon"]}
              onClick={optionsIsOpen ? hideOptions : showOptions}
            />
          </Popover>
        </div>
        <SearchIcon className={style["search__icon"]} onClick={doSearch} />
      </div>
    </div>
  );
});

SearchField.propTypes = {
  className: PropTypes.string
};

export default SearchField;
