import { pick } from "lodash";

export const selectDashboardFilterMenuOpen = state => state.dashboard.filterMenuOpen;

export const selectDashboardColumns = state => state.dashboard.columns;

export const selectDashboardColumnsShallow = state => {
  return selectDashboardColumns(state).map(column => {
    return pick(column, ["id", "filterId"]);
  });
};

export const selectDashboardReadNewsItemIds = state => state.dashboard.readNewsItems;

export const selectDashboardUpdatedNewsItemIds = state => state.dashboard.updatedNewsItems;

export const selectDashboardActiveNewsItemId = state => state.dashboard.activeNewsItemId;

export const selectDashboardActiveNewsItemLatestVersionId = state =>
  state.dashboard.activeNewsItemLatestVersionId;

export const selectDashboardActiveNewsItemColumnId = state => {
  return state.dashboard.activeNewsItemColumnId;
};

export const selectDashboardModalNewsItem = state => state.dashboard.modalNewsItem;

export const selectDashboardModalNewsItemLatestVersionId = state =>
  state.dashboard.modalNewsItemLatestVersionId;

export const selectDashboardColumnById = id => state => {
  return selectDashboardColumns(state).find(column => column.id === id);
};

export const selectDashboardArticleById = id => state => {
  const articleFilter = state.dashboard.columns.find(articleFilter => {
    return articleFilter.articles.some(article => article.id === id);
  });

  return articleFilter ? articleFilter.articles.find(article => article.id === id) : null;
};

export const selectDashboardActiveNewsItemColumn = state => {
  return selectDashboardColumnById(selectDashboardActiveNewsItemColumnId(state))(state);
};

export const selectDashboardActiveNewsItem = state => {
  return selectDashboardArticleById(selectDashboardActiveNewsItemId(state))(state);
};

export const selectDashboardIsMobile = state => state.dashboard.isMobile;
