export const selectUserAuthenticationAttempted = state => state.user.authenticationAttempted;
export const selectUserAuthenticated = state => state.user.authenticated;
export const selectUserProfile = state => state.user.profile;
export const selectUserPreferences = state => state.user.preferences;
export const selectUserThemePreference = state => selectUserPreferences(state).theme;
export const selectUserArticleImagePlacementPreference = state => {
  return selectUserPreferences(state).articleImagePlacement;
};
export const selectUserArticleVersionPreference = state => {
  return selectUserPreferences(state).articleVersion;
};
