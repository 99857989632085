import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import classNames from "classnames";
import { isEmpty, isEqual, isFunction } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as LastMultiDayIndicator } from "../assets/graphics/last-multi-day-indicator.svg";
import { ReactComponent as MultiDayIndicator } from "../assets/graphics/multi-day-indicator.svg";
import style from "../assets/scss/components/news-item.module.scss";
import { NTB_TEMA_NEWS_SERVICES, NTB_TEMA_SMALL_MATTER_CATEGORIES } from "../helpers/constants";
import { selectArticleFilters } from "../store/articleFilter/selector";
import { selectCalendarActiveNewsItemColumnId } from "../store/calendar/selector";
import { markNewsItemAsActive as markCalendarNewsItemAsActive } from "../store/calendar/slice";
import { selectDashboardActiveNewsItemColumnId } from "../store/dashboard/selector";
import { markNewsItemAsActive as markDashboardNewsItemAsActive } from "../store/dashboard/slice";

import ArticleView, { decodeHtml, removeUnwantedTitleStartWords } from "./article/ArticleView";
import Pulser from "./Pulser";

function NewsItem({
  item,
  columnId,
  columnDate,
  active,
  read,
  updated,
  onClick,
  withInlineArticleView,
  latestVersionId = null,
  showDate = false,
  showLabel = true,
  showLabelTop = false
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeDashboardNewsItemColumnId = useSelector(
    selectDashboardActiveNewsItemColumnId,
    isEqual
  );
  const activeCalendarNewsItemColumnId = useSelector(selectCalendarActiveNewsItemColumnId, isEqual);

  const newsItemRef = useRef(null);
  const articleViewRef = useRef(null);

  const articleFilters = useSelector(selectArticleFilters, isEqual);
  const activeArticleFilters = useMemo(
    () => articleFilters.filter(filter => filter.active),
    [articleFilters]
  );

  // Disable the label of only one filter is selected and is not "All news"
  if (
    activeArticleFilters.length === 1 &&
    activeArticleFilters[0].custom?.properties?.originalId !== "all"
  ) {
    showLabel = false;
  }

  const [articleViewReady, setArticleViewReady] = useState(false);

  const statuses = useMemo(
    () => [
      {
        id: "updated",
        label: t("newsItem:statuses:updated"),
        color: "green"
      },
      {
        id: "published",
        color: "green"
      },
      {
        id: "beingUpdated",
        color: "green"
      },
      {
        id: "inProgress",
        color: "yellow"
      }
    ],
    [t]
  );

  const priority = useMemo(() => {
    return item.priority;
  }, [item.priority]);

  const previousPriority = useMemo(() => {
    if (Number.isInteger(item.priority) && item.priority <= 3) {
      return item.priority;
    }

    let value = 5;

    if (!isEmpty(item?.previousPriorities)) {
      value = Math.min(...item.previousPriorities.filter(n => Number.isInteger(n)));
    }

    return value;
  }, [item.previousPriorities, item.priority]);

  const itemTimeLuxon = useMemo(() => DateTime.fromMillis(item.time), [item.time]);

  const itemStatus = useMemo(() => {
    if (updated) {
      const index = statuses.findIndex(status => status.id === "updated");
      return statuses[index];
    }

    if (item.coverages) {
      const inProgressCoverage = item.coverages.find(coverage => coverage.status === "inProgress");
      const beingUpdatedCoverage = item.coverages.find(
        coverage => coverage.status === "beingUpdated"
      );
      const publishedCoverage = item.coverages.find(coverage => coverage.status === "published");

      if (publishedCoverage) {
        return statuses.find(status => status.id === "published");
      }

      if (beingUpdatedCoverage) {
        return statuses.find(status => status.id === "beingUpdated");
      }

      if (inProgressCoverage) {
        return statuses.find(status => status.id === "inProgress");
      }
    }

    return null;
  }, [item.coverages, statuses, updated]);

  const itemLabel = () => {
    let label = item.category;
    let cssClass = "";

    if (NTB_TEMA_NEWS_SERVICES.includes(item.service)) {
      label = "Tema";

      if (NTB_TEMA_SMALL_MATTER_CATEGORIES.includes(item.category)) label = "Småstoff";
    }

    if (label === "PRM-NTB") {
      label = t("articleCategories:PRM");
    }

    if (item.service === "mediation") cssClass = "tranquil";

    return { text: label, cssClass };
  };

  const isCalendarItem = useMemo(() => {
    return item.service === "newscalendar";
  }, [item.service]);

  const markNewsItemAsActive = useCallback(
    (id, columnId) => {
      if (isCalendarItem) {
        dispatch(markCalendarNewsItemAsActive({ id, columnId }));
      } else {
        dispatch(markDashboardNewsItemAsActive({ id, columnId }));
      }
    },
    [isCalendarItem, dispatch]
  );

  useEffect(() => {
    if (withInlineArticleView && articleViewRef.current && newsItemRef.current) {
      function handleResize() {
        if (!articleViewRef.current) return;

        articleViewRef.current.style.top =
          newsItemRef.current.parentElement.offsetTop - newsItemRef.current.offsetTop + "px";
        articleViewRef.current.style.height = newsItemRef.current.parentElement.clientHeight + "px";

        setArticleViewReady(true);
      }

      handleResize();
      window.addEventListener("resize", handleResize);

      return function cleanup() {
        setArticleViewReady(false);
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  useEffect(() => {
    if (
      active &&
      (columnId === activeDashboardNewsItemColumnId || columnId === activeCalendarNewsItemColumnId)
    ) {
      newsItemRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [active, columnId, activeDashboardNewsItemColumnId, activeCalendarNewsItemColumnId]);

  const handleNewsItemClick = id => {
    if (isFunction(onClick)) {
      onClick(id, columnId);
    }
  };

  // Click events in the inline ArticleView must not propagate to the NewsItem,
  // othewise the newsItem will be opened again
  const stopPropagation = e => {
    e.stopPropagation();
  };

  const renderCoverageIcon = (item, type) => {
    if (
      !item.coverages?.some(coverage => coverage.type === type) &&
      !(type === "picture" && item.pictures)
    ) {
      return null;
    }

    const isCancelled = item.coverages?.some(c => c.type === type && c.status === "cancelled");

    return (
      <Tooltip
        content={
          isCalendarItem ? (
            isCancelled ? (
              <>
                {type === "text" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:textCancelled")
                    }}
                  />
                )}
                {type === "picture" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:picturesCancelled")
                    }}
                  />
                )}
                {type === "video" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:videoCancelled")
                    }}
                  />
                )}
                {type === "live_video" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:liveVideoCancelled")
                    }}
                  />
                )}
                {type === "infographics" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:infographicsCancelled")
                    }}
                  />
                )}
                {type === "undecided_under_consideration" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("newsItem:calendarCoverages:underConsiderationCancelled")
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {type === "text" && t("newsItem:calendarCoverages:text")}
                {type === "picture" && t("newsItem:calendarCoverages:pictures")}
                {type === "video" && t("newsItem:calendarCoverages:video")}
                {type === "live_video" && t("newsItem:calendarCoverages:liveVideo")}
                {type === "infographics" && t("newsItem:calendarCoverages:infographics")}
                {type === "undecided_under_consideration" &&
                  t("newsItem:calendarCoverages:underConsideration")}
              </>
            )
          ) : (
            <>{type === "picture" && t("newsItem:coverages:pictures")}</>
          )
        }
      >
        <div
          className={classNames(style["item__info__icons__container"], {
            [style["item__info__icons__container--cancelled"]]: isCancelled
          })}
        >
          {type === "text" && <MaterialSymbol icon="article" size={18} />}
          {type === "picture" && <MaterialSymbol icon="photo" size={18} />}
          {type === "video" && <MaterialSymbol icon="play_circle" size={18} />}
          {type === "live_video" && <MaterialSymbol icon="videocam" size={18} />}
          {type === "infographics" && <MaterialSymbol icon="analytics" size={18} />}
          {type === "undecided_under_consideration" && <MaterialSymbol icon="help" size={18} />}

          {isCancelled && <div className={style["item__info__icons__container__cancelled-line"]} />}
        </div>
      </Tooltip>
    );
  };

  const isMultiDayItem = useMemo(() => {
    return item.endTime && !itemTimeLuxon.hasSame(DateTime.fromMillis(item.endTime), "day");
  }, [item.endTime, itemTimeLuxon]);

  const isToBeConfirmedItem = useMemo(() => item.timeToBeConfirmed, [item.timeToBeConfirmed]);

  const isLastDayOfMultiDayItem = useMemo(() => {
    if (!isMultiDayItem) return false;

    return (
      columnDate &&
      DateTime.fromFormat(columnDate, "dd.MM.yyyy").hasSame(
        DateTime.fromMillis(item.endTime),
        "day"
      )
    );
  }, [columnDate, isMultiDayItem, item.endTime]);

  const displayTime = useMemo(() => {
    const within24Hours =
      item.endTime && DateTime.fromMillis(item.endTime).diff(itemTimeLuxon, "hours").hours > 23;

    if (!showDate) {
      if (isCalendarItem || itemTimeLuxon.hasSame(DateTime.local(), "day")) {
        if (isToBeConfirmedItem) {
          return null;
        }

        return within24Hours ? "Hele dagen" : itemTimeLuxon.toFormat("HH.mm");
      } else {
        if (isToBeConfirmedItem) {
          itemTimeLuxon.toFormat("dd.LL.yyyy");
        }

        return itemTimeLuxon.toFormat("HH.mm - dd.LL.yyyy");
      }
    } else {
      if (isToBeConfirmedItem) {
        return itemTimeLuxon.toFormat("dd.LL.yyyy");
      }

      return within24Hours
        ? itemTimeLuxon.toFormat("dd.LL.yyyy', hele dagen'")
        : itemTimeLuxon.toFormat("dd.LL.yyyy - HH.mm");
    }
  }, [isCalendarItem, isToBeConfirmedItem, item.endTime, itemTimeLuxon, showDate]);

  const onKeyDown = (event, item) => {
    const enterOrSpace =
      event.key === "Enter" ||
      event.key === " " ||
      event.key === "Spacebar" ||
      event.which === 13 ||
      event.which === 32;
    if (enterOrSpace) {
      event.preventDefault();
      handleNewsItemClick(item.id);
    }
  };

  return (
    <div
      className={classNames(
        style["item"],
        {
          [style["item--read"]]: read,
          [style["item--active"]]: active,
          [style["item--" + itemStatus?.id]]: itemStatus?.id,
          [style["item--with-inline-article-view"]]: withInlineArticleView
        },
        priority <= previousPriority
          ? { [style["item--priority-high"]]: priority <= 3 }
          : {
              [style["item--priority-medium"]]: previousPriority !== null && previousPriority <= 3
            }
      )}
      ref={newsItemRef}
      role="button"
      tabIndex={0}
      onClick={() => handleNewsItemClick(item.id)}
      onKeyDown={event => onKeyDown(event, item)}
    >
      {isMultiDayItem &&
        (isLastDayOfMultiDayItem ? (
          <LastMultiDayIndicator className={style["item__multi-day-indicator"]} />
        ) : (
          <MultiDayIndicator className={style["item__multi-day-indicator"]} />
        ))}
      <div className={style["item__info"]}>
        <div className={style["item__info__time"]}>{displayTime}</div>
        {showLabel && showLabelTop && (
          <div
            className={classNames(
              style["item__label"],
              {
                [style["item__label--" + itemLabel()?.cssClass]]: itemLabel()?.cssClass
              },
              style["item__info__label"]
            )}
          >
            {itemLabel().text}
          </div>
        )}
        {itemStatus && (
          <div
            className={classNames(
              style["item__info__status"],
              style["item__info__status--" + itemStatus.id]
            )}
          >
            <Pulser variant={itemStatus.color} />
            {itemStatus.label && (
              <div className={style["item__info__status__text"]}>{itemStatus.label}</div>
            )}
          </div>
        )}
        <div className={style["item__info__icons"]}>
          {renderCoverageIcon(item, "text")}
          {renderCoverageIcon(item, "picture")}
          {renderCoverageIcon(item, "video")}
          {renderCoverageIcon(item, "live_video")}
          {renderCoverageIcon(item, "infographics")}
          {renderCoverageIcon(item, "undecided_under_consideration")}

          {/*<Tooltip content={t("newsItem:calendarCoverages:text")}>
            {item.coverages?.some(coverage => coverage.type === "text") && (
              <MaterialSymbol icon="article" size={18} />
            )}
          </Tooltip>
          {(item.pictures || item.coverages?.some(coverage => coverage.type === "picture")) && (
            <Tooltip
              content={
                isCalendarItem ? (
                  item.coverages?.some(c => c.type === "picture" && c.status === "cancelled") ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("newsItem:calendarCoverages:picturesCancelled")
                      }}
                    />
                  ) : (
                    t("newsItem:calendarCoverages:pictures")
                  )
                ) : (
                  t("newsItem:coverages:pictures")
                )
              }
            >
              <div
                className={classNames(style["item__info__icons__container"], {
                  [style["item__info__icons__container--cancelled"]]: true
                })}
              >
                <MaterialSymbol icon="photo" size={18} />
                <div className={style["item__info__icons__container__cancelled-line"]} />
              </div>
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "video") && (
            <Tooltip
              content={
                isCalendarItem
                  ? t("newsItem:calendarCoverages:video")
                  : t("newsItem:coverages:video")
              }
            >
              <MaterialSymbol icon="play_circle" size={18} />
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "live_video") && (
            <Tooltip content={t("newsItem:calendarCoverages:liveVideo")}>
              <MaterialSymbol icon="videocam" size={18} />
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "infographics") && (
            <Tooltip content={t("newsItem:calendarCoverages:infographics")}>
              <MaterialSymbol icon="analytics" size={18} />
            </Tooltip>
          )}
          {item.coverages?.some(coverage => coverage.type === "undecided_under_consideration") && (
            <Tooltip content={t("newsItem:calendarCoverages:underConsideration")}>
              <MaterialSymbol icon="help" size={18} />
            </Tooltip>
          )}*/}
        </div>
      </div>
      <div
        className={classNames(style["item__title"], {
          [style["item__title--multi-day-item"]]: isMultiDayItem
        })}
      >
        {item.service === "mediation"
          ? removeUnwantedTitleStartWords(decodeHtml(item.title))
          : decodeHtml(item.title)}
      </div>
      {showLabel && !showLabelTop && (
        <div
          className={classNames(style["item__label"], {
            [style["item__label--" + itemLabel()?.cssClass]]: itemLabel()?.cssClass
          })}
        >
          {itemLabel().text}
        </div>
      )}
      {withInlineArticleView && (
        <div
          className={style["item__article-view-wrapper"]}
          ref={articleViewRef}
          onClick={stopPropagation}
        >
          {articleViewReady && (
            <ArticleView
              article={item}
              latestVersionId={latestVersionId}
              onClose={() => markNewsItemAsActive(null, null)}
            />
          )}
        </div>
      )}
    </div>
  );
}

NewsItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  columnDate: PropTypes.string,
  active: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  withInlineArticleView: PropTypes.bool,
  latestVersionId: PropTypes.number,
  showDate: PropTypes.bool,
  showLabel: PropTypes.bool,
  showLabelTop: PropTypes.bool
};

NewsItem.defaultProps = {
  withInlineArticleView: false
};

export default React.memo(NewsItem, (props, oldProps) => {
  if (props.withInlineArticleView) return false;
  return isEqual(props, oldProps);
});
