import React, { useState, useMemo, useCallback } from "react";

import { ReactComponent as AddIcon } from "@ntbjs/react-components/icons/add-circle.svg";
import { TextInput, TextArea, Button } from "@ntbjs/react-components/inputs";
import classNames from "classnames";
import ReactGA from "react-ga4";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import style from "../../assets/scss/components/calendar/request-new-event.module.scss";
import CalendarService from "../../services/CalendarService";
import DatePickerRange from "../form/DatePickerRange";
import Select from "../form/Select";
import Modal from "../ui-element/Modal";

const calendarService = new CalendarService();

function RequestNewEvent() {
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);

    ReactGA.event({
      category: "Calendar event",
      action: "Click on register new event link"
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  // generate time option
  const timeOptions = useMemo(() => {
    const options = [];

    for (let i = 0; i <= 86400; i++) {
      if (i % 900 === 0) {
        const hours = i / 3600;
        const minutes = hours - Math.floor(hours);

        const hourPart = String(Math.floor(hours)).padStart(2, "0");
        const minutePart = String(60 * minutes).padStart(2, "0");

        const time = `${hourPart}:${minutePart}`;

        options.push({ value: time, label: time });
      }
    }

    options.pop();

    return options;
  }, []);

  const onSubmit = async data => {
    if (submitting) return;
    setSubmitting(true);

    try {
      await calendarService.requestAddEvent(data);
      reset();
      toast("Innsending suksess", { autoClose: 5000 });
    } catch (e) {
      // TODO: Add a toast notification
      alert("Innsending feilet");
    }

    setSubmitting(false);
    setModalOpen(false);

    ReactGA.event({
      category: "Calendar event",
      action: "Submit event registration form",
      label: data.title
    });
  };

  return (
    <>
      <button className={style["request-new-event__trigger"]} onClick={openModal}>
        <AddIcon />
        {t("calendar:Add new event")}
      </button>

      <Modal className={style["request-new-event__modal"]} open={modalOpen} onClose={closeModal}>
        <div className={style["request-new-event__modal__header"]}>
          {t("calendar:Add new event")}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classNames(
              style["request-new-event__modal__form__grid-50-50"],
              style["request-new-event__modal__form__grid-gap-30"]
            )}
          >
            <div>
              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  error={Boolean(errors["title"])}
                  label={t("calendar:requestNewEvent:form:title")}
                  {...register("title", { required: true })}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Select
                      value={{ value: value, label: value }}
                      {...field}
                      options={[
                        { value: "Innenriks", label: "Innenriks" },
                        { value: "Utenriks", label: "Utenriks" },
                        { value: "Sport", label: "Sport" }
                      ]}
                      placeholder={t("calendar:requestNewEvent:form:category:placeholder")}
                      onChange={option => onChange(option.value)}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:address")}
                  {...register("address", { required: true })}
                  error={Boolean(errors["address"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:location")}
                  {...register("location", { required: true })}
                  error={Boolean(errors["location"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:url")}
                  {...register("url", { required: true })}
                  error={Boolean(errors["url"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextArea
                  required
                  label={t("calendar:requestNewEvent:form:description")}
                  {...register("description", { required: true })}
                  error={Boolean(errors["description"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextArea
                  required
                  label={t("calendar:requestNewEvent:form:additionalNotes")}
                  {...register("notes", { required: true })}
                  error={Boolean(errors["notes"])}
                />
              </div>
            </div>

            <div>
              <div className={style["request-new-event__modal__form__input"]}>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, value, ...field } }) => (
                    <DatePickerRange
                      {...field}
                      block
                      allowClear={false}
                      endDate={value?.endDate}
                      options={["today", "tomorrow"]}
                      startDate={value?.startDate}
                      onChange={(startDate, endDate) =>
                        onChange({
                          startDate,
                          endDate
                        })
                      }
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>

              <div
                className={classNames(
                  style["request-new-event__modal__form__grid-50-50"],
                  style["request-new-event__modal__form__grid-gap-10"]
                )}
              >
                <div className={style["request-new-event__modal__form__input"]}>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        value={{ value: value, label: value }}
                        {...field}
                        options={timeOptions}
                        placeholder={t("calendar:requestNewEvent:form:startTime")}
                        onChange={option => onChange(option.value)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className={style["request-new-event__modal__form__input"]}>
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        value={{ value: value, label: value }}
                        {...field}
                        options={timeOptions}
                        placeholder={t("calendar:requestNewEvent:form:endTime")}
                        onChange={option => onChange(option.value)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>

              <p className={style["request-new-event__modal__form__header"]}>
                {t("calendar:requestNewEvent:form:formTitleContact")}
              </p>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:contactName")}
                  {...register("contactName", { required: true })}
                  error={Boolean(errors["contactName"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:contactPhone")}
                  {...register("contactPhone", { required: true })}
                  error={Boolean(errors["contactPhone"])}
                />
              </div>

              <div className={style["request-new-event__modal__form__input"]}>
                <TextInput
                  required
                  label={t("calendar:requestNewEvent:form:contactEmail")}
                  {...register("contactEmail", { required: true })}
                  error={Boolean(errors["contactEmail"])}
                />
              </div>
            </div>
          </div>

          <div className={style["request-new-event__modal__actions"]}>
            <Button outlined secondary onClick={() => setModalOpen(false)}>
              {t("calendar:requestNewEvent:form:actions:cancel")}
            </Button>
            <Button disabled={!isValid} loading={submitting}>
              {t("calendar:requestNewEvent:form:actions:submit")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default RequestNewEvent;
