import { createSlice } from "@reduxjs/toolkit";

import { togglePreferredTheme, getUserProfile } from "./thunk";

const LS_KEY_PREFERRED_THEME = "NEWS_PREFERRED_THEME";
const LS_KEY_ARTICLE_IMAGE_CAROUSEL_PLACEMENT = "ARTICLE_IMAGE_CAROUSEL_PLACEMENT";
const LS_KEY_ARTICLE_VERSION = "ARTICLE_VERSION";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    authenticationAttempted: false,
    authenticated: false,
    profile: null,
    preferences: {
      theme: localStorage.getItem(LS_KEY_PREFERRED_THEME) || "default",
      articleImagePlacement: localStorage.getItem(LS_KEY_ARTICLE_IMAGE_CAROUSEL_PLACEMENT) || "top",
      articleVersion: localStorage.getItem(LS_KEY_ARTICLE_VERSION) || "latest"
    }
  },
  reducers: {
    setThemePreferenceDefault(state) {
      state.preferences.theme = "default";
      localStorage.setItem(LS_KEY_PREFERRED_THEME, "default");
    },
    setThemePreferenceDark(state) {
      state.preferences.theme = "dark";
      localStorage.setItem(LS_KEY_PREFERRED_THEME, "dark");
    },
    setThemePreferenceLight(state) {
      state.preferences.theme = "light";
      localStorage.setItem(LS_KEY_PREFERRED_THEME, "light");
    },
    setArticleImagePlacementPreferenceTop(state) {
      state.preferences.articleImagePlacement = "top";
      localStorage.setItem(LS_KEY_ARTICLE_IMAGE_CAROUSEL_PLACEMENT, "top");
    },
    setArticleImagePlacementPreferenceBottom(state) {
      state.preferences.articleImagePlacement = "bottom";
      localStorage.setItem(LS_KEY_ARTICLE_IMAGE_CAROUSEL_PLACEMENT, "bottom");
    },
    setArticleVersionPreference(state, action) {
      state.preferences.articleVersion = action.payload;
      localStorage.setItem(LS_KEY_ARTICLE_VERSION, action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.authenticationAttempted = true;
      state.authenticated = true;
      state.profile = action.payload;
    });

    builder.addCase(getUserProfile.rejected, state => {
      state.authenticationAttempted = true;
      state.authenticated = false;
      state.profile = null;
    });
  }
});

export const {
  setThemePreferenceDark,
  setThemePreferenceLight,
  setThemePreferenceDefault,
  setArticleImagePlacementPreferenceTop,
  setArticleImagePlacementPreferenceBottom,
  setArticleVersionPreference
} = userSlice.actions;
export { togglePreferredTheme, getUserProfile };
export default userSlice.reducer;
