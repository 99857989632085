import { isArray } from "lodash";

import BaseService from "./BaseService";
import { queryStringBuilder } from "./utils";

export default class NotificationService extends BaseService {
  constructor() {
    super();
  }

  async getAll() {
    super.setAction("api/notification/all");
    return super.fetch();
  }

  async getById(id) {
    super.setAction("api/notification/get");
    super.setQuery({ id });
    return super.fetch();
  }

  async create(filter, types) {
    super.setAction("api/notification/add");
    const notificationConfig = this._generateNotificationConfig(filter, types);
    super.setBody(notificationConfig);
    return super.post();
  }

  async updateByFilter(filter, types = null, returnUpdated = false) {
    super.setAction("api/notification/update");
    const notificationConfig = this._generateNotificationConfig(filter, types);
    super.setBody(notificationConfig);

    return await super.post({ method: "PUT" }).then(async () => {
      if (returnUpdated) {
        return await this.getById(filter.notification.id);
      }
    });
  }

  async delete(id) {
    super.setAction("api/notification/delete");
    super.setQuery({ id });
    return super.post({ method: "DELETE" });
  }

  async updateBrowserNotificationToken(token) {
    const format = "x1";
    const device = "web";

    super.setAction(`api/${format}/notification/${device}/register/${token}`);
    return super.fetch();
  }

  /**
   * @param {("web"|"sms"|"email")} device
   * @param {("sport"|"domestic"|"foreign"|"urgent")} type
   * @param {Boolean} enabled
   */
  async enableBreakingNews(device, type, enabled = true) {
    const format = "x1";

    super.setAction(
      `api/${format}/notification/breaking/${device}/update/${type}/${String(enabled)}`
    );
    return super.fetch();
  }

  /**
   * @param {("web"|"sms"|"email")} device
   * @param {("editorial"|"spvideo")} type
   * @param {Boolean} enabled
   */
  async enableServiceMessages(device, type, enabled = true) {
    const format = "x1";

    super.setAction(
      `api/${format}/notification/breaking/${device}/update/${type}/${String(enabled)}`
    );
    return super.fetch();
  }

  _generateNotificationConfig(filter, types = null) {
    const notificationConfig = {
      notification: {
        name: `filter:${filter.id}`,
        site: "ntb",
        types: types || filter.notification.types,
        userId: filter.userId
      },
      search: {
        searchString: queryStringBuilder("", [filter]),
        service: isArray(filter.properties.service)
          ? filter.properties.service.join(",")
          : filter.properties.service
      }
    };

    if (filter.notification) {
      notificationConfig.notification.id = filter.notification.id;
      notificationConfig.notification.searchId = filter.notification.searchId;
    }

    return notificationConfig;
  }
}
