import React, { useState, useCallback, useMemo } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import { TextInput, Button } from "@ntbjs/react-components/inputs";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope.svg";
import style from "../../../assets/scss/components/article/actions/send-to-email.module.scss";
import UserService from "../../../services/UserService";
import { selectUserProfile } from "../../../store/user/selector";
import Modal from "../../ui-element/Modal";

const userService = new UserService();

function SendToEmail({ article }) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  const userprofile = useSelector(selectUserProfile);

  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openModal = useCallback(() => setModalOpen(true), []);

  const closeModal = useCallback(() => setModalOpen(false), []);

  const isCalendarEvent = useMemo(() => {
    return article?.service === "newscalendar";
  }, [article.service]);

  const onSubmit = async data => {
    if (submitting) return;
    setSubmitting(true);
    await userService.shareByEmail(article.id, article.service, data.emailAddress);

    setSubmitting(false);
    setModalOpen(false);

    ReactGA.event({
      category: "Article",
      action: "Send to email",
      label: article.title
    });
  };

  return (
    <>
      <Tooltip
        content={
          isCalendarEvent
            ? t("articleView:actions:shareCalendarEventByEmail")
            : t("articleView:actions:shareArticleByEmail")
        }
      >
        <EnvelopeIcon onClick={openModal} />
      </Tooltip>

      <Modal open={modalOpen} size={"small"} onClose={closeModal}>
        <div className={style["send-to-email__modal__header"]}>
          {isCalendarEvent
            ? t("articleView:actions:shareCalendarEventByEmail")
            : t("articleView:actions:shareArticleByEmail")}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            defaultValue={userprofile.email}
            error={Boolean(errors["emailAddress"])}
            label={t("articleView:actions:shareByEmailModal:emailAddress")}
            required={true}
            {...register(`emailAddress`, { required: true })}
          />

          <div className={style["send-to-email__modal__actions"]}>
            <Button disabled={!isValid} loading={submitting}>
              {t("articleView:actions:shareByEmailModal:share")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

SendToEmail.propTypes = {
  article: PropTypes.object
};

export default SendToEmail;
