export const selectNotifications = state => state.notification.list;

export const selectUnreadNotificationsCount = state =>
  selectNotifications(state).filter(notification => !notification.read).length;

export const selectLoadingInitialNotifications = state => state.notification.loadingInitial;

export const selectBreakingNewsNotifications = state => state.notification.breakingNews;

export const selectServiceMessageNotifications = state => state.notification.serviceMessages;
