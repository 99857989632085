import BaseService from "./BaseService";

export default class SearchFilterService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Returns available mediaTopics
   */
  async getMediaTopics() {
    super.setAction("api/mediatopics-regions/mediatopics");
    return super.fetch();
  }

  async getRegions() {
    super.setAction("api/mediatopics-regions/regions");
    return super.fetch();
  }
}
