export const baseURL = "/ntbWeb/r";

export const indexRoute = baseURL;
export const calendarRoute = `${baseURL}/calendar`;
// export const previewRoute = `${baseURL}/preview/:mainGroup/:id`;
// export const albumIndexRoute = `${baseURL}/album`;
// export const albumNameRoute = `${baseURL}/album/:name`;
export const counterRoute = `${baseURL}/counter`;
export const settingsRoute = `${baseURL}/settings`;
export const settingsAccountRoute = `${baseURL}/settings/account`;
export const notificationsAccountRoute = `${baseURL}/settings/notifications`;
// export const needActivationRoute = `${baseURL}/need-activation`;
export const newsSearchRoute = `${baseURL}/search/newsfeed`;
export const calendarSearchRoute = `${baseURL}/search/calendar`;
