import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import style from "../../assets/scss/components/layout/hotkey-overview.module.scss";
import { hotkeyScopeList, hotkeyList } from "../../helpers/hotkeys";

function HotkeyOverview({}) {
  const { t } = useTranslation();

  function fancyKeys(keys) {
    keys = keys.trim();

    let fancyKey;
    switch (keys) {
      case "option":
        fancyKey = "⌥";
        break;
      case "up":
        fancyKey = "↑";
        break;
      case "right":
        fancyKey = "→";
        break;
      case "down":
        fancyKey = "↓";
        break;
      case "left":
        fancyKey = "←";
        break;
      case "command":
        fancyKey = "⌘";
        break;
      default:
        fancyKey = keys;
    }

    return fancyKey;
  }

  function hotkeysByScope(scope) {
    return hotkeyList.filter(hotkey => hotkey.scope === scope);
  }

  return (
    <div className={style["overview"]}>
      <div className={style["overview__header"]}>{t("hotkey:overview:title")}</div>

      <div className={style["overview__grid"]}>
        {hotkeyScopeList.map(hotkeyScope => (
          <div
            className={classNames(style["overview__scope"], {
              [style["overview__grid__span-2"]]: hotkeysByScope(hotkeyScope.scope).length > 1
            })}
            key={`hotkeyScope-${hotkeyScope.title}`}
          >
            <div className={style["overview__scope__title"]}>{hotkeyScope.title}</div>
            <div className={style["overview__scope__description"]}>{hotkeyScope.description}</div>
            <div
              className={classNames(style["overview__scope__key-list"], {
                [style["overview__scope__key-list--2-columns"]]:
                  hotkeysByScope(hotkeyScope.scope).length <= 2
              })}
            >
              {hotkeysByScope(hotkeyScope.scope).map(hotkey => (
                <div
                  className={style["overview__scope__key-list__container"]}
                  key={`hotkey-${hotkey.keys}-${hotkey.scope}`}
                >
                  <div className={style["overview__scope__key-list__container__description"]}>
                    {hotkey.description}
                  </div>
                  <div className={style["overview__scope__key-list__container__key-combinations"]}>
                    {hotkey.keys.split(",").map(keyCombination => (
                      <div
                        className={
                          style[
                            "overview__scope__key-list__container__key-combinations__combination"
                          ]
                        }
                        key={`hotkey-${hotkey.keys}-${hotkey.scope}-${keyCombination}`}
                      >
                        {keyCombination.split("+").map(key => (
                          <div
                            className={
                              style[
                                "overview__scope__key-list__container__key-combinations__combination__key"
                              ]
                            }
                            key={`hotkey-${hotkey.keys}-${hotkey.scope}-${keyCombination}-${key}`}
                          >
                            {fancyKeys(key)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HotkeyOverview;
