import React, { Fragment, useMemo } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import style from "../../assets/scss/components/action-row.module.scss";
import { FILTER_NAME_MAX_LENGTH } from "../../helpers/constants";
import { calendarSearchRoute } from "../../helpers/routes";
import { truncateString } from "../../helpers/utils";
import { selectArticleFilterIsMobile } from "../../store/articleFilter/selector";
import { selectDashboardFilterMenuOpen } from "../../store/dashboard/selector";
import { toggleFilterMenu } from "../../store/dashboard/slice";
import PrimaryMenu from "../navigation/PrimaryMenu";
import SkeletonBox from "../skeleton/SkeletonBox";

function SearchActionRow({ filters, loadingFilters, onFilterClick, onClearFilters }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filterMenuOpen = useSelector(selectDashboardFilterMenuOpen, isEqual);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);

  const isCalendarSearchRoute = useMatch(calendarSearchRoute);

  const hasActiveFilters = useMemo(
    () => filters.some(filter => (isCalendarSearchRoute ? filter.calendarActive : filter.active)),
    [filters, isCalendarSearchRoute]
  );

  return (
    <div className={classNames(style["action-row"])}>
      <button
        className={classNames(style["action-row__menu-trigger"], {
          [style["action-row__menu-trigger--open"]]: filterMenuOpen
        })}
        onClick={() => dispatch(toggleFilterMenu())}
      >
        <FilterIcon className={style["action-row__menu-trigger__icon"]} />
        <span className={style["action-row__menu-trigger__label"]}>Filter</span>
        <div className={style["action-row__menu-trigger__open-indicator"]}>
          <ChevronLeftIcon />
        </div>
      </button>

      {isMobile ? (
        <PrimaryMenu />
      ) : !loadingFilters ? (
        <>
          <button
            className={classNames(style["action-row__button"], {
              [style["action-row__button--active"]]: !hasActiveFilters
            })}
            onClick={onClearFilters}
          >
            {isCalendarSearchRoute
              ? t("articleCategories:AllEvents")
              : t("articleCategories:AllNews")}
          </button>
          <div className={style["action-row__divider"]} />
          {filters.map(filter => (
            <Fragment key={filter.id}>
              <button
                className={classNames(style["action-row__button"], {
                  [style["action-row__button--active"]]: isCalendarSearchRoute
                    ? filter.calendarActive
                    : filter.active
                })}
                onClick={() => onFilterClick(filter.id)}
              >
                {filter.default
                  ? t(filter.title)
                  : truncateString(filter.title, FILTER_NAME_MAX_LENGTH)}
              </button>
            </Fragment>
          ))}
        </>
      ) : (
        [...Array(3)].map((value, index) => (
          <React.Fragment key={index}>
            <SkeletonBox
              style={{
                width: "66px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
            <SkeletonBox
              style={{
                width: "90px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
            <SkeletonBox
              style={{
                width: "45px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
          </React.Fragment>
        ))
      )}
    </div>
  );
}

SearchActionRow.propTypes = {
  filters: PropTypes.array,
  loadingFilters: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired
};

export default SearchActionRow;
