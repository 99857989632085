import React, { useEffect } from "react";

import { Tooltip } from "@ntbjs/react-components/data";
import classNames from "classnames";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as EventNoteIcon } from "../../assets/icons/event-note.svg";
import { ReactComponent as TableIcon } from "../../assets/icons/table.svg";
import style from "../../assets/scss/components/ui-element/calendar-view-mode.module.scss";
import { DEFAULT_CALENDAR_VIEW_MODE } from "../../helpers/constants";
import { selectCalendarViewMode } from "../../store/calendar/selector";
import { setViewMode } from "../../store/calendar/slice";

function CalendarViewMode() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const calendarViewMode = useSelector(selectCalendarViewMode);

  let queryObject = qs.parse(search, { ignoreQueryPrefix: true });
  const viewModeQueryParam = queryObject.viewMode ? queryObject.viewMode : "";

  useEffect(() => {
    let updateRequired = false;

    // When viewMode is not the default one add "viewMode" to the query string
    if (
      calendarViewMode !== viewModeQueryParam &&
      calendarViewMode !== DEFAULT_CALENDAR_VIEW_MODE
    ) {
      queryObject.viewMode = calendarViewMode;
      updateRequired = true;
    }

    // If viewMode is the default one remove the parameter from query string
    if (calendarViewMode === DEFAULT_CALENDAR_VIEW_MODE && viewModeQueryParam) {
      delete queryObject.viewMode;
      updateRequired = true;
    }

    if (updateRequired) {
      navigate(
        {
          pathname: pathname,
          search: qs.stringify(queryObject)
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewMode]);

  return (
    <div className={style["calendar-view-mode"]}>
      <div
        className={classNames(style["calendar-view-mode__item"], {
          [style["calendar-view-mode__item--active"]]: calendarViewMode === "calendar"
        })}
        onClick={() => dispatch(setViewMode("calendar"))}
      >
        <Tooltip content={t("viewMode:Calendar view")}>
          <CalendarIcon />
        </Tooltip>
      </div>
      <div
        className={classNames(style["calendar-view-mode__item"], {
          [style["calendar-view-mode__item--active"]]: calendarViewMode === "detailed"
        })}
        onClick={() => dispatch(setViewMode("detailed"))}
      >
        <Tooltip content={t("viewMode:Detailed view")}>
          <EventNoteIcon />
        </Tooltip>
      </div>
      {/*<div*/}
      {/*  className={classNames(style["calendar-view-mode__item"], {*/}
      {/*    [style["calendar-view-mode__item--active"]]: calendarViewMode === "kanban"*/}
      {/*  })}*/}
      {/*  onClick={() => dispatch(setViewMode("kanban"))}*/}
      {/*>*/}
      {/*  <Tooltip content={t("viewMode:Kanban view")}>*/}
      {/*    <TableIcon />*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}
    </div>
  );
}

export default CalendarViewMode;
