import React, { useEffect, useState, useMemo, useCallback } from "react";

import PropTypes from "prop-types";
import qs from "qs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLastLocation } from "react-router-dom-last-location";

import { newsSearchRoute, calendarSearchRoute } from "../../helpers/routes";
import ArticleService from "../../services/ArticleService";
import {
  selectCalendarActiveNewsItemId,
  selectCalendarReadNewsItemIds,
  selectCalendarModalNewsItem
} from "../../store/calendar/selector";
import {
  markNewsItemAsActive as markCalendarNewsItemAsActive,
  setModalNewsItem as setCalendarModalNewsItem
} from "../../store/calendar/slice";
import {
  selectDashboardActiveNewsItemId,
  selectDashboardReadNewsItemIds,
  selectDashboardModalNewsItem
} from "../../store/dashboard/selector";
import {
  markNewsItemAsActive as markDashboardNewsItemAsActive,
  setModalNewsItem as setDashboardModalNewsItem
} from "../../store/dashboard/slice";

const articleService = new ArticleService();

function ArticleUrlModifier({ service, onHardLoad }) {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const lastLocation = useLastLocation();

  const dashboardActiveNewsItemId = useSelector(selectDashboardActiveNewsItemId);
  const calendarActiveNewsItemId = useSelector(selectCalendarActiveNewsItemId);
  const dashboardModalNewsItem = useSelector(selectDashboardModalNewsItem);
  const calendarModalNewsItem = useSelector(selectCalendarModalNewsItem);
  const readDashboardNewsItems = useSelector(selectDashboardReadNewsItemIds);
  const readCalendarNewsItems = useSelector(selectCalendarReadNewsItemIds);

  const [activeNewsItemReset, setActiveNewsItemReset] = useState(false);
  const [historyActionActiveNewsItem, setHistoryActionActiveNewsItem] = useState("push");
  const [historyActionModalNewsItem, setHistoryActionModalNewsItem] = useState("push");

  const isCalendarEvent = useMemo(() => {
    return service === "newscalendar";
  }, [service]);

  const articleId = isCalendarEvent ? calendarActiveNewsItemId : dashboardActiveNewsItemId;
  const modalArticleId = isCalendarEvent ? calendarModalNewsItem.id : dashboardModalNewsItem.id;
  const queryObject = qs.parse(search, { ignoreQueryPrefix: true });

  const markNewsItemAsActive = useCallback(
    (id, columnId) => {
      if (isCalendarEvent) {
        dispatch(markCalendarNewsItemAsActive({ id, columnId }));
      } else {
        dispatch(markDashboardNewsItemAsActive({ id, columnId }));
      }
    },
    [isCalendarEvent, dispatch]
  );

  const setModalNewsItem = useCallback(
    article => {
      if (isCalendarEvent) {
        dispatch(setCalendarModalNewsItem(article));
      } else {
        dispatch(setDashboardModalNewsItem(article));
      }
    },
    [isCalendarEvent, dispatch]
  );

  useEffect(() => {
    if (
      lastLocation &&
      (lastLocation.pathname === newsSearchRoute ||
        lastLocation.pathname === calendarSearchRoute) &&
      !(pathname === newsSearchRoute || pathname === calendarSearchRoute) &&
      !activeNewsItemReset
    ) {
      // User is coming from the search page, reset active news item to prevent URL blinking
      dispatch(markDashboardNewsItemAsActive({ id: null, columnId: null }));
      dispatch(markCalendarNewsItemAsActive({ id: null, columnId: null }));
      setActiveNewsItemReset(true);
      return;
    }

    if (queryObject.hasOwnProperty("article")) {
      queryObject.article = Number(queryObject.article);
    }

    if (
      !articleId &&
      !modalArticleId &&
      queryObject.hasOwnProperty("article") &&
      queryObject.article &&
      !readDashboardNewsItems.includes(queryObject.article) &&
      !readCalendarNewsItems.includes(queryObject.article)
    ) {
      // User is hard loading the page with an article id and service in the URL parameters the article should be opened in a modal-style popover.
      articleService.getOne(service, queryObject.article).then(article => {
        article.service = service;
        article.time = article.pubdate;
        onHardLoad(article);
      });
    } else {
      if (modalArticleId) {
        document.body.classList.add("modal-article-view-open");
        if (modalArticleId !== queryObject.article) {
          queryObject.service = service;
          queryObject.article = modalArticleId;

          navigate(
            {
              pathname: pathname,
              search: `?${qs.stringify(queryObject)}`
            },
            { replace: historyActionModalNewsItem === "replace" }
          );

          if (historyActionModalNewsItem === "push") setHistoryActionModalNewsItem("replace");
        }
      } else if (articleId) {
        document.body.classList.add("article-view-open");
        if (articleId !== queryObject.article) {
          queryObject.service = service;
          queryObject.article = articleId;

          // When the modal article is closed go back in the history,
          // otherwise add/replace the history entry
          if (
            lastLocation &&
            lastLocation.search === `?${qs.stringify(queryObject)}` &&
            historyActionModalNewsItem === "replace"
          ) {
            navigate(-1);
          } else {
            navigate(
              {
                pathname: pathname,
                search: `?${qs.stringify(queryObject)}`
              },
              { replace: historyActionActiveNewsItem === "replace" }
            );
          }

          if (historyActionActiveNewsItem === "push") setHistoryActionActiveNewsItem("replace");
          if (historyActionModalNewsItem === "replace") setHistoryActionModalNewsItem("push");
        }
      } else if (queryObject.hasOwnProperty("service") || queryObject.hasOwnProperty("article")) {
        delete queryObject.service;
        delete queryObject.article;
        document.body.classList.remove("article-view-open");
        document.body.classList.remove("modal-article-view-open");

        navigate(
          {
            pathname: pathname,
            search: `?${qs.stringify(queryObject)}`
          },
          { replace: true }
        );

        if (historyActionModalNewsItem === "replace") setHistoryActionModalNewsItem("push");
      }
    }

    return () => {
      document.body.classList.remove("article-view-open");
      document.body.classList.remove("modal-article-view-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    service,
    dashboardActiveNewsItemId,
    calendarActiveNewsItemId,
    dashboardModalNewsItem,
    calendarModalNewsItem,
    readDashboardNewsItems,
    readCalendarNewsItems,
    pathname
  ]);

  useEffect(() => {
    if (queryObject.hasOwnProperty("article")) {
      queryObject.article = Number(queryObject.article);
    }

    // Handle clicking on the back and forth browser buttons

    // 1. Close MODAL article but keep INLINE article opened.
    // The case when user clicked back button and the modal article must be closed.
    if (
      queryObject.hasOwnProperty("article") &&
      articleId &&
      modalArticleId &&
      queryObject.article === articleId &&
      (queryObject.article !== modalArticleId ||
        (lastLocation.search && lastLocation.search === search))
    ) {
      setModalNewsItem({});
      if (historyActionModalNewsItem === "replace") setHistoryActionModalNewsItem("push");
      return;
    }

    // 2. Close MODAL article when inline article already closed.
    // The case when user clicked back button and the modal article must be closed.
    if (!queryObject.hasOwnProperty("article") && !articleId && modalArticleId) {
      setModalNewsItem({});
      if (historyActionModalNewsItem === "replace") setHistoryActionModalNewsItem("push");
      return;
    }

    // 3. Close INLINE article.
    // The case when user clicked back button and the inline article must be closed.
    if (!queryObject.hasOwnProperty("article") && articleId && !modalArticleId) {
      markNewsItemAsActive(null, null);
      if (historyActionActiveNewsItem === "replace") setHistoryActionActiveNewsItem("push");
      return;
    }

    // 4. Close both MODAL and INLINE articles.
    // It's possible when the same article opened inline and in the modal
    if (!queryObject.hasOwnProperty("article") && articleId && modalArticleId) {
      setModalNewsItem({});
      markNewsItemAsActive(null, null);
      return;
    }

    // 5. Open MODAL article.
    // The case when user clicked forward button and modal article must be opened.
    if (
      queryObject.hasOwnProperty("article") &&
      (!articleId || articleId !== queryObject.article) &&
      (!modalArticleId || modalArticleId !== queryObject.article) &&
      lastLocation
    ) {
      articleService.getOne(service, queryObject.article).then(article => {
        article.service = service;
        article.time = article.pubdate;
        setModalNewsItem(article);
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, lastLocation]);

  return null;
}

ArticleUrlModifier.propTypes = {
  service: PropTypes.string.isRequired,
  onHardLoad: PropTypes.func.isRequired
};

export default ArticleUrlModifier;
