import { createAsyncThunk } from "@reduxjs/toolkit";

import UserService from "../../services/UserService";

import { selectUserThemePreference } from "./selector";
import { setThemePreferenceDark, setThemePreferenceLight } from "./slice";

export const getUserProfile = createAsyncThunk("user/getUserProfile", async () => {
  return await new UserService().getProfile();
});

export const togglePreferredTheme = () => (dispatch, getState) => {
  let currentTheme = selectUserThemePreference(getState());

  if (currentTheme === "default") {
    currentTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  if (currentTheme === "light") {
    dispatch(setThemePreferenceDark());
  }

  if (currentTheme === "dark") {
    dispatch(setThemePreferenceLight());
  }
};
