class IframeEventBus {
  constructor() {
    this.iframeDomNodes = new Set();
    this.eventStore = [];

    window.addEventListener("message", this._onMessage.bind(this));
  }

  registerIframe(domNode) {
    if (domNode instanceof Element || domNode instanceof HTMLDocument) {
      // only register domNodes
      this.iframeDomNodes.add(domNode);
    }
  }

  unregisterIframe(domNode) {
    this.iframeDomNodes.delete(domNode);
  }

  on(event, callback) {
    this.eventStore.push({
      event,
      callback
    });
  }

  emit(event, payload) {
    // push event to the native parent instance
    window.top.postMessage({ event: event, payload }, "*");

    // push event to all registered iframe instances
    for (let node of this.iframeDomNodes) {
      console.log("POSTING MESSAGE:", { event: event, payload });
      node.contentWindow.postMessage({ event: event, payload }, "*");
    }
  }

  _onMessage(eventObject) {
    const { event, payload } = eventObject.data;

    // get register events from the eventStore with the same event key
    const eventsToRun = this.eventStore.filter(eventFromStore => event === eventFromStore.event);

    // run callback
    eventsToRun.forEach(eventToRun => eventToRun.callback(payload));
  }
}

export default new IframeEventBus();
