import React, { useEffect, useState, useCallback } from "react";

import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "usehooks-ts";

import ThemeSwitch from "../components/helpers/ThemeSwitch";
import Header from "../components/layout/Header";
import HotkeyOverview from "../components/layout/HotkeyOverview";
import SalesforceChatScript from "../components/scripts/SalesforceChatScript";
import Modal from "../components/ui-element/Modal";
import { hotkeys, useHotkey } from "../helpers/hotkeys";
import { usePrint } from "../helpers/usePrint";
import AuthService from "../services/AuthService";
import { setIsMobile } from "../store/articleFilter/slice";
import {
  selectUserAuthenticationAttempted,
  selectUserAuthenticated,
  selectUserProfile
} from "../store/user/selector";
import { getUserProfile } from "../store/user/slice";

const authService = new AuthService();

function DefaultLayout({ children }) {
  const dispatch = useDispatch();

  const authenticationAttempted = useSelector(selectUserAuthenticationAttempted, isEqual);
  const authenticated = useSelector(selectUserAuthenticated, isEqual);
  const userProfile = useSelector(selectUserProfile, isEqual);

  const [hotkeyOverviewOpen, setHotkeyOverviewOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 639px)");
  const isPrinting = usePrint();

  useHotkey(hotkeys.OPEN_HOTKEY_OVERVIEW, () => {
    setHotkeyOverviewOpen(!hotkeyOverviewOpen);
    return false;
  });

  const onCloseModal = useCallback(() => {
    setHotkeyOverviewOpen(false);
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    // No need to change app state when printing
    if (!isPrinting) dispatch(setIsMobile(isMobile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isMobile]);

  useEffect(() => {
    // Calculate 1vh value in pixels based on window inner height
    // and use it on mobile to fix 100vh issue
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
  }, [isMobile]);

  // Redirect unauthenticated users to the authentication page
  useEffect(() => {
    if (authenticationAttempted && !authenticated) {
      authService.login();
    }
  }, [authenticationAttempted, authenticated]);

  // Set user in Sentry
  useEffect(() => {
    if (userProfile?.id) {
      Sentry.setUser({
        id: userProfile.id,
        email: userProfile.email || null,
        username: userProfile.username || null
      });
    }
  }, [userProfile]);

  // Show an empty page if the user is not authenticated
  if (!authenticationAttempted || (authenticationAttempted && !authenticated)) {
    return null;
  }

  return (
    <>
      <ThemeSwitch />
      <Header />

      {children}

      <Modal open={hotkeyOverviewOpen} onClose={onCloseModal}>
        <HotkeyOverview />
      </Modal>

      <SalesforceChatScript />
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default DefaultLayout;
