import React, { useState } from "react";

import classNames from "classnames";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

import style from "../../assets/scss/components/form/radio.module.scss";

function Radio({ onChange, value, checked, name, children }) {
  const [uniqueId] = useState(nanoid());

  const clicked = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <label
      className={classNames(style["radio"], { [style["radio--checked"]]: checked })}
      htmlFor={uniqueId}
    >
      <input checked={checked} id={uniqueId} name={name} type="radio" onChange={clicked} />
      <div className={style["radio__label"]}>{children}</div>
    </label>
  );
}

Radio.propTypes = {
  children: PropTypes.any,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default Radio;
