import React, { useEffect, useCallback } from "react";

import { useDispatch } from "react-redux";

import { togglePreferredTheme } from "../../store/user/slice";

function ThemeSwitch() {
  const dispatch = useDispatch();

  const onKeyDown = useCallback(
    event => {
      if (event.key === "Pause") {
        dispatch(togglePreferredTheme());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return null;
}

export default ThemeSwitch;
