import React, { useRef, useState, useCallback } from "react";

import PropTypes from "prop-types";

import ArticleView from "../article/ArticleView";
import Modal from "../ui-element/Modal";

function ArticleViewModal({ article, latestVersionId, onClose }) {
  const [articleModalOpen, setArticleModalOpen] = useState(true);

  // Store document title before the modal is opened
  // and set it back when the modal is closed
  const prevDocumentTitle = useRef(document.title);

  const onCloseModal = useCallback(() => {
    onClose();
    setArticleModalOpen(false);
    document.title = prevDocumentTitle.current;
  }, [onClose]);

  return article.id ? (
    <Modal
      className={"article-view"}
      open={articleModalOpen}
      showCloseButton={false}
      onClose={onCloseModal}
    >
      <ArticleView
        article={article}
        latestVersionId={latestVersionId}
        modal={true}
        onClose={() => onCloseModal()}
      />
    </Modal>
  ) : null;
}

ArticleViewModal.propTypes = {
  article: PropTypes.object.isRequired,
  latestVersionId: PropTypes.number,
  onClose: PropTypes.func
};

export default ArticleViewModal;
