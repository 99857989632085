import React, { useState } from "react";

import PropTypes from "prop-types";

import { ReactComponent as LaunchIcon } from "../../assets/icons/launch.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/swap.svg";
import Logo from "../../assets/images/ntb-logo.svg";
import style from "../../assets/scss/components/user-menu.module.scss";

function UserExternalLink({ url, label, visible, displayLogo }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {visible && (
        <a
          className={style["user-menu__dropdown__item"]}
          href={url}
          rel="noreferrer"
          target="_blank"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>
            {displayLogo && <img alt={"logo"} src={Logo} />}
            <div>{label}</div>
          </div>
          {!isHovered ? <LaunchIcon /> : <SwapIcon />}
        </a>
      )}
    </>
  );
}

UserExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  displayLogo: PropTypes.bool
};

UserExternalLink.defaultProps = {
  visible: true,
  displayLogo: false
};

export default UserExternalLink;
