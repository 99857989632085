import React from "react";

import { random } from "lodash";

import SkeletonBox from "../skeleton/SkeletonBox";

function ArticleViewSkeleton() {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "calc(100% - 110px)" }}>
        <div style={{ fontSize: "1.125rem", marginTop: "25px", marginBottom: "45px" }}>
          <SkeletonBox style={{ width: "100%", marginBottom: "9px" }} />
          <SkeletonBox style={{ width: "80%", marginBottom: "9px" }} />
          <SkeletonBox style={{ width: "30%", marginBottom: "9px" }} />
        </div>

        <div style={{ width: "100%" }}>
          {Array(5)
            .fill(0)
            .map((value, index) => (
              <SkeletonBox
                key={index}
                style={{ width: `${random(50, 100)}%`, marginBottom: "7px" }}
              />
            ))}
          <br />
          {Array(7)
            .fill(0)
            .map((value, index) => (
              <SkeletonBox
                key={index}
                style={{ width: `${random(50, 100)}%`, marginBottom: "7px" }}
              />
            ))}
          <br />
          {Array(3)
            .fill(0)
            .map((value, index) => (
              <SkeletonBox
                key={index}
                style={{ width: `${random(50, 100)}%`, marginBottom: "7px" }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ArticleViewSkeleton;
