import { createSlice } from "@reduxjs/toolkit";
import { eachDayOfInterval } from "date-fns";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";

import { DEFAULT_CALENDAR_VIEW_MODE, DEFAULT_DATE_FORMAT } from "../../helpers/constants";
import i18n from "../../i18n";

import {
  calendarSearch,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive,
  loadMoreArticlesByColumnId,
  fetchNewArticlesByColumnId
} from "./thunk";

const todayLuxon = DateTime.now();

const today = todayLuxon.toFormat(DEFAULT_DATE_FORMAT);
const nextWeek = todayLuxon.plus({ days: 6 }).toFormat(DEFAULT_DATE_FORMAT);

const kanbanColumns = [
  { id: "planned", title: i18n.t("calendar:column:title:Scheduled") },
  { id: "inProgress", title: i18n.t("calendar:column:title:Work in progress") },
  { id: "beingUpdated", title: i18n.t("calendar:column:title:Updated") },
  { id: "published", title: i18n.t("calendar:column:title:Published") }
];

const viewModes = ["calendar", "kanban", "detailed"];

const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const initialViewMode = params.get("viewMode");

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    startDate: today,
    endDate: nextWeek,
    columns: [],
    coverageFilters: [],
    viewMode: viewModes.includes(initialViewMode) ? initialViewMode : DEFAULT_CALENDAR_VIEW_MODE,
    readNewsItems: [],
    updatedNewsItems: [],
    activeNewsItemId: null,
    activeNewsItemColumnId: null,
    modalNewsItem: {},
    isMobile: false
  },
  reducers: {
    changeDateRange(state, action) {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;

      const startDateLuxon = DateTime.fromFormat(startDate, DEFAULT_DATE_FORMAT);
      const endDateLuxon = DateTime.fromFormat(endDate, DEFAULT_DATE_FORMAT);

      if (state.isMobile) {
        // On mobile only one column should be displayed therefore we make it multi day column.
        // E.g. events from different days displayed in the same column (like it is on the search page).
        const startDateTime = startDateLuxon;
        const endDateTime = endDateLuxon;
        const columnId = startDate + " - " + endDate;

        const existingColumn = state.columns.find(column => column.id === columnId);

        state.columns = existingColumn
          ? [existingColumn]
          : [
              {
                id: columnId,
                title:
                  startDate !== endDate
                    ? "Fra " + startDate + " til " + endDate
                    : startDateTime.hasSame(DateTime.now(), "day")
                    ? i18n.t("calendar:column:title:today")
                    : startDateTime.hasSame(DateTime.now().plus({ days: 1 }), "day")
                    ? i18n.t("calendar:column:title:tomorrow")
                    : startDate,
                startDate: startDateTime.toSeconds(),
                endDate: endDateTime.toSeconds(),
                sort: "PRIORITY_ASC|UNIQUE_COVERAGE_TYPES_COUNT_DESC",
                loading: false,
                totalResults: 0,
                articles: [],
                extraArticles: []
              }
            ];

        return;
      }

      const days = [];
      if (!!startDate && !!endDate) {
        days.push(startDateLuxon);
        let nextDay;
        let initialValue = 1;
        do {
          nextDay = startDateLuxon.plus({ days: initialValue });
          if (nextDay > endDateLuxon) {
            break;
          } else {
            days.push(nextDay);
            initialValue++;
          }
        } while (true);
      }

      state.columns = days.map(dateTime => {
        const date = dateTime.toFormat(DEFAULT_DATE_FORMAT);

        const existingColumn = state.columns.find(column => column.id === date);
        if (existingColumn) {
          return existingColumn;
        }

        return {
          id: date,
          title: dateTime.hasSame(DateTime.now(), "day")
            ? i18n.t("calendar:column:title:today")
            : dateTime.hasSame(DateTime.now().plus({ days: 1 }), "day")
            ? i18n.t("calendar:column:title:tomorrow")
            : date,
          startDate: dateTime.toSeconds(),
          endDate: dateTime.toSeconds(),
          sort: "UNIQUE_COVERAGE_TYPES_COUNT_DESC|STARTDATE_ASC",
          loading: false,
          totalResults: 0,
          articles: [],
          extraArticles: []
        };
      });
    },
    changeNewsColumnSort(state, action) {
      const { id, sort } = action.payload;
      const index = state.columns.findIndex(column => column.id === id);
      state.columns[index].sort = sort;
      state.columns[index].extraArticles = [];

      if (state.activeNewsItemColumnId === id) {
        state.activeNewsItemId = null;
        state.activeNewsItemColumnId = null;
      }
    },
    setViewMode(state, action) {
      if (isEmpty(action.payload)) {
        return;
      }
      // if (state.viewMode !== action.payload) {
      state.viewMode = action.payload;
      state.activeNewsItemId = null;
      state.activeNewsItemColumnId = null;

      if (action.payload === "kanban") {
        // As of now Kanban view mode designed only for today's date
        state.startDate = today;
        state.endDate = today;

        state.columns = kanbanColumns.map(kanbanColumn => {
          const existingColumn = state.columns.find(column => column.id === kanbanColumn.id);

          if (existingColumn) {
            return existingColumn;
          }

          return {
            id: kanbanColumn.id,
            title: kanbanColumn.title,
            startDate: DateTime.fromFormat(today, DEFAULT_DATE_FORMAT).toSeconds(),
            endDate: DateTime.fromFormat(today, DEFAULT_DATE_FORMAT).toSeconds(),
            sort: "UNIQUE_COVERAGE_TYPES_COUNT_DESC|STARTDATE_ASC",
            loading: false,
            totalResults: 0,
            articles: [],
            highestCoverageStatus: kanbanColumn.id
          };
        });
      }
      // }
    },
    toggleContentFilter(state, action) {
      const id = action.payload;

      let index = state.coverageFilters.findIndex(filter => filter === id);
      if (index === -1) {
        state.coverageFilters.push(id);
      } else {
        state.coverageFilters.splice(index, 1);
      }
    },
    markNewsItemAsActive(state, action) {
      state.activeNewsItemId = action.payload.id;
      if (action.payload.id) {
        if (!state.readNewsItems.includes(action.payload.id))
          state.readNewsItems.push(action.payload.id);

        if (state.updatedNewsItems.includes(action.payload.id))
          state.updatedNewsItems = state.updatedNewsItems.filter(
            item => item !== action.payload.id
          );
      }

      state.activeNewsItemColumnId = action.payload.columnId;
    },
    setModalNewsItem(state, action) {
      state.modalNewsItem = { ...action.payload };

      if (action.payload.id) {
        if (!state.readNewsItems.includes(action.payload.id))
          state.readNewsItems.push(action.payload.id);

        if (state.updatedNewsItems.includes(action.payload.id))
          state.updatedNewsItems = state.updatedNewsItems.filter(
            item => item !== action.payload.id
          );
      }
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(calendarSearch.pending, (state, { meta }) => {
      const { columnId } = meta.arg;

      const index = state.columns.findIndex(column => column.id === columnId);
      const columns = state.columns[index];

      columns.loading = true;
    });

    builder.addCase(calendarSearch.fulfilled, (state, { payload, meta }) => {
      if (!payload) return;

      const { columnId } = meta.arg;

      const index = state.columns.findIndex(column => column.id === columnId);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const column = state.columns[index];

      column.totalResults = payload.numResults;
      column.articles = [...payload.data];
      column.loading = false;
    });

    builder.addCase(calendarSearch.rejected, (state, { meta }) => {
      const { columnId } = meta.arg;

      const index = state.columns.findIndex(column => column.id === columnId);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const columns = state.columns[index];

      columns.loading = false;
    });

    builder.addCase(loadMoreArticlesByColumnId.fulfilled, (state, { payload, meta }) => {
      const index = state.columns.findIndex(column => column.id === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      const column = state.columns[index];

      column.totalResults = payload.numResults;
      column.articles = [...column.articles, ...payload.data];
    });

    builder.addCase(fetchNewArticlesByColumnId.fulfilled, (state, { payload, meta }) => {
      const index = state.columns.findIndex(column => column.id === meta.arg);

      // The column is already removed from the store - skip
      if (index === -1) return;

      // No new articles fetched
      if (!payload.data || payload.data.length === 0) return;

      const column = state.columns[index];

      // Compare fetched articles with existing ones and replace if there are new ones.
      const oldArticleIds = column.articles.map(article => article.id);
      const oldNtbIds = column.articles.map(article => article.ntbId);
      const newArticleIds = payload.data.map(article => article.id);
      const newNtbIds = payload.data.map(article => article.ntbId);

      const addedArticleIds = newArticleIds.filter(id => !oldArticleIds.includes(id));

      // No new articles fetched
      if (addedArticleIds.length === 0) return;

      // Reset extra articles that might have been added under certain circumstances.
      column.extraArticles = [];

      // Add updated mark to articles when appropriate
      if (payload.latest) {
        for (let i = 0; i <= addedArticleIds.length - 1; i++) {
          let index = payload.data.findIndex(article => article.id === addedArticleIds[i]);
          if (
            oldNtbIds.includes(payload.data[index].ntbId) &&
            !state.updatedNewsItems.includes(payload.data[index].id)
          ) {
            state.updatedNewsItems.push(payload.data[index].id);
          }
        }
      }

      // Make sure the active article is in the column and add it back if it was removed
      if (
        state.activeNewsItemColumnId === column.id &&
        !newArticleIds.includes(state.activeNewsItemId)
      ) {
        const activeArticle = Object.assign(
          {},
          column.articles.find(article => article.id === state.activeNewsItemId)
        );

        // Was it removed because a newer version of it fetched?
        // If so we add it back right after the newer version of the article.
        if (payload.latest && newNtbIds.includes(activeArticle.ntbId)) {
          const newerVersionOfActiveArticleIndex = payload.data.findIndex(
            article => article.ntbId === activeArticle.ntbId
          );
          payload.data.splice(newerVersionOfActiveArticleIndex + 1, 0, activeArticle);
        }
        // Was it removed because it's placed too low in the list?
        // In this case just add it to the very bottom of the list.
        else {
          payload.data.push(activeArticle);
        }

        // We forced addition of the active article, we must take it into account when
        // polling for the new articles again to prevent fetching more items than needed.
        column.extraArticles = [activeArticle];
      }

      column.articles = [...payload.data];
    });
  }
});

export const {
  changeDateRange,
  changeNewsColumnSort,
  setViewMode,
  setIsMobile,
  toggleContentFilter,
  markNewsItemAsActive,
  setModalNewsItem
} = calendarSlice.actions;

export {
  calendarSearch,
  loadMoreArticlesByColumnId,
  fetchNewArticlesByColumnId,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive
};

export default calendarSlice.reducer;
