import { useEffect } from "react";

import { useSelector } from "react-redux";

import { selectUserProfile } from "../../store/user/selector";
import "../../assets/scss/components/script/salesforce-chat-script.module.scss";

const SalesforceChatScript = () => {
  const user = useSelector(selectUserProfile);

  useEffect(() => {
    if (!user) return;

    const script = document.createElement("script");

    script.innerHTML = `
      try {
          embedded_svc.settings.prepopulatedPrechatFields = {
          FirstName: "${user.firstName || ""}",
          LastName: "${user.lastName || ""}",
          Email: "${user.email || ""}",
          Phone: "${user.mobile || ""}"
        }; //Sets the auto-population of pre-chat form fields
      } catch (error) {
        console.error(error);
      }
    `;

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, [user]);

  return null;
};

export default SalesforceChatScript;
