import React, { useEffect, useRef, useState, useCallback } from "react";

import PropTypes from "prop-types";

function HorizontalInfiniteScroll({
  hasMore,
  mainWrapper = null,
  children,
  loader,
  dispatchScroll
}) {
  let mainWrapperRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const triggerScroll = useCallback(
    mainWrapperRef => {
      if (mainWrapperRef.current && Object.keys(mainWrapperRef.current).length > 0) {
        const element = mainWrapperRef.current;

        element.onscroll = e => {
          if (e.target.scrollWidth - e.target.scrollLeft - e.target.offsetWidth < 300) {
            element.onscroll = () => {};
            setIsLoading(true);
            dispatchScroll({ scrollWidth: e.target.scrollWidth });
          }
        };

        const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
        if (!hasHorizontalScrollbar) {
          setIsLoading(true);
          dispatchScroll({ scrollWidth: element.scrollWidth });
        }
      }
    },
    [mainWrapperRef, dispatchScroll] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setIsLoading(false);
    if (hasMore) {
      triggerScroll(mainWrapperRef);
    }
  }, [triggerScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return React.cloneElement(mainWrapper, { ref: mainWrapperRef }, [
    ...children,
    isLoading ? loader : []
  ]);
}

HorizontalInfiniteScroll.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  mainWrapper: PropTypes.element.isRequired,
  children: PropTypes.array,
  loader: PropTypes.element,
  dispatchScroll: PropTypes.func.isRequired
};

export default HorizontalInfiniteScroll;
