import React, { useCallback, useEffect, useMemo, useRef } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";

import style from "../assets/scss/components/news-column.module.scss";
import { selectAccessibleCalendarArticleFilters } from "../store/articleFilter/selector";
import {
  selectCalendarColumnById,
  selectCalendarReadNewsItemIds,
  selectCalendarUpdatedNewsItemIds,
  selectCalendarActiveNewsItemId,
  selectCalendarViewMode,
  selectCalendarContentFilters
} from "../store/calendar/selector";
import {
  calendarSearch,
  loadMoreArticlesByColumnId,
  fetchNewArticlesByColumnId,
  changeNewsColumnSort,
  changeDateRange,
  markNewsItemAsActive
} from "../store/calendar/slice";

import LoadingSpinner from "./LoadingSpinner";
import NewsItem from "./NewsItem";
import NewsItemDetailed from "./newsitem/NewsItemDetailed";
import NewsItemDetailedSkeleton from "./newsitem/NewsItemDetailedSkeleton";
import NewsItemSkeleton from "./NewsItemSkeleton";

function CalendarNewsColumn({ id, columnsNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(selectAccessibleCalendarArticleFilters, isEqual);
  const column = useSelector(selectCalendarColumnById(id));
  const selectedContentFilters = useSelector(selectCalendarContentFilters);
  const readNewsItems = useSelector(selectCalendarReadNewsItemIds);
  const updatedNewsItems = useSelector(selectCalendarUpdatedNewsItemIds, isEqual);
  const activeNewsItemId = useSelector(selectCalendarActiveNewsItemId);
  const viewMode = useSelector(selectCalendarViewMode);

  const columnScrollableRef = useRef(null);

  useEffect(() => {
    if (!filters.length) return;
    const abortController = new AbortController();
    dispatch(calendarSearch({ columnId: column.id, abortController }));
    return () => abortController.abort();
  }, [dispatch, filters, column.id, column.sort, selectedContentFilters]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchNewArticlesByColumnId(column.id));
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, column.id]);

  useEffect(() => {
    if (columnScrollableRef.current) {
      const hasVerticalScrollbar =
        columnScrollableRef.current.scrollHeight > columnScrollableRef.current.clientHeight;
      // Added the check because on iOS devices the scrollbar isn't visible
      const verticalScrollbarIsVisible =
        columnScrollableRef.current.offsetWidth > columnScrollableRef.current.clientWidth;

      columnScrollableRef.current.classList.toggle(
        style["column__scrollable--has-scrollbar"],
        hasVerticalScrollbar && verticalScrollbarIsVisible
      );
    }
  });

  const sortOptions = useMemo(
    () => [
      {
        value: "UNIQUE_COVERAGE_TYPES_COUNT_DESC|STARTDATE_ASC",
        label: t("articleFilter:Priority")
      },
      { value: "STARTDATE_ASC", label: t("articleFilter:Chronology") }
    ],
    [t]
  );

  const articlesAreLoading = useMemo(() => {
    return !(column && !column.loading && filters.length);
  }, [column, filters.length]);

  const handleSortChange = sort => {
    if (column.sort !== sort) {
      dispatch(changeNewsColumnSort({ id, sort }));

      ReactGA.event({
        category: "List",
        action: "Change sorting order",
        label: sort
      });
    }
  };

  const setActiveNewsItem = useCallback(
    (id, columnId) => {
      // Close newsItem if the open one was clicked
      if (activeNewsItemId === id && column.id === columnId) {
        return dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      }

      dispatch(markNewsItemAsActive({ id, columnId }));
    },
    [activeNewsItemId, column.id, dispatch]
  );

  const getSelectedSortOption = useCallback(() => {
    return sortOptions.find(option => option.value === column.sort) || sortOptions[0];
  }, [column.sort]); // eslint-disable-line react-hooks/exhaustive-deps

  let selectedSortOption = getSelectedSortOption();

  const newsItemList = () => {
    const NewsItemComponent = viewMode !== "detailed" ? NewsItem : NewsItemDetailed;

    const columnDate = DateTime.fromFormat(column.id, "dd.MM.yyyy");

    return column.articles.map(item => (
      <NewsItemComponent
        active={activeNewsItemId === item.id}
        columnDate={columnDate.isValid ? columnDate.toFormat("dd.MM.yyyy") : null}
        columnId={column.id}
        item={item}
        key={`news-item-${column.id}-${id}-${item.id}`}
        read={readNewsItems.includes(item.id)}
        showDate={column.startDate !== column.endDate}
        updated={updatedNewsItems.includes(item.id)}
        onClick={setActiveNewsItem}
      />
    ));
  };

  const loader = () => {
    const NewsItemSkeletonComponent =
      viewMode !== "detailed" ? NewsItemSkeleton : NewsItemDetailedSkeleton;

    return (
      <>
        <NewsItemSkeletonComponent />
        <NewsItemSkeletonComponent />
        <NewsItemSkeletonComponent />
      </>
    );
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div
            className={classNames("column__headline__select__dropdown-indicator__arrow", {
              "column__headline__select__dropdown-indicator__arrow--rotated":
                props.selectProps.menuIsOpen // eslint-disable-line
            })}
          />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div
      className={classNames(style["column"], style["column--" + viewMode], {
        [style["column--" + id]]: viewMode === "kanban"
      })}
    >
      <div className={style["column__headline"]}>
        <div className={style["column__headline__inner"]}>
          <div
            className={classNames(style["column__headline__title"], {
              [style["column__headline__title--clickable"]]:
                columnsNumber !== 1 && viewMode === "calendar"
            })}
            onClick={() =>
              columnsNumber !== 1 && viewMode === "calendar"
                ? dispatch(changeDateRange({ startDate: column.id, endDate: column.id }))
                : () => false
            }
          >
            {column.title}
          </div>

          <Select
            className={style["column__headline__select"]}
            classNamePrefix={"column__headline__select"}
            components={{ DropdownIndicator }}
            isDisabled={articlesAreLoading}
            isSearchable={false}
            options={sortOptions}
            value={selectedSortOption}
            onChange={e => handleSortChange(e.value)}
          />
        </div>
      </div>
      {articlesAreLoading ? (
        <div className={style["loading"]}>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          className={style["column__scrollable"]}
          id={`column-${column.id}`}
          ref={columnScrollableRef}
        >
          {!articlesAreLoading && column.articles.length === 0 && (
            <div className={style["column__scrollable__no-results"]}>
              {t("feed:noResultsLabel")}
            </div>
          )}
          <InfiniteScroll
            dataLength={column.articles.length}
            hasMore={column.totalResults > column.articles.length}
            loader={loader()}
            next={() => dispatch(loadMoreArticlesByColumnId(column.id))}
            scrollableTarget={`column-${column.id}`}
          >
            {newsItemList()}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

CalendarNewsColumn.propTypes = {
  id: PropTypes.string,
  columnsNumber: PropTypes.number
};

export default CalendarNewsColumn;
