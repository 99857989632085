import React, { useRef, useState } from "react";

import DOMPurify from "dompurify";
import truncate from "html-truncate";
import PropTypes from "prop-types";

const ReadMoreLess = ({ children, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatableRef = useRef(false);

  const getContentTextLength = () => {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = children;
    const plainText = tempDiv.textContent || tempDiv.innerText || "";
    return plainText.length;
  };

  const getTruncatedContent = () => {
    const contentTextLength = getContentTextLength();
    if (contentTextLength > maxLength) {
      truncatableRef.current = true;
      return truncate(children, maxLength);
    } else {
      return children;
    }
  };

  const isTruncatable = getContentTextLength() > maxLength;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(isExpanded ? children : getTruncatedContent())
        }}
      />
      {isTruncatable && (
        <>
          {" "}
          <span
            className="react-read-more-read-less react-read-more-read-less-more"
            role="presentation"
            style={{
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            onClick={toggleReadMore}
          >
            {isExpanded ? "Les mindre" : "Les mer"}
          </span>
        </>
      )}
    </>
  );
};

ReadMoreLess.propTypes = {
  maxLength: PropTypes.number,
  children: PropTypes.any
};

export default ReadMoreLess;
